import { deleteData, getData, postData, putData, useGetData } from "lib/request"

export type UsuarioCriarReq = { nome: string, login: string, senha: string, telefone?: {'numero_com_ddd': 'string', 'eh_principal': 'boolean'} }
export type UsuarioCriarRes = { id: number, pessoa_id: number, login: string, senha: string, ultimo_acesso?: string, ultima_mundaca_senha: string, criado_as: string, deletado_as?: string }

const usuarioCriar = async (body: UsuarioCriarReq) => postData<UsuarioCriarRes>(`/usuario`, body)

export type UsuarioPegarRes = { id: number, nome: string, login: string }

const usuarioPegar = async (id: number) => getData<UsuarioPegarRes>(`/usuario/${id}`)
const useUsuarioPegar = (id: number) => useGetData<UsuarioPegarRes>(`/usuario/${id}`)

export type UsuarioAtualizarReq = { id: number, nome: string, login: string, senha: string, telefone?: {'numero_com_ddd': 'string', 'eh_principal': 'boolean'} }
export type UsuarioAtualizarRes = { id: number, pessoa_id: number, login: string, senha: string, ultimo_acesso?: string, ultima_mundaca_senha: string, criado_as: string, deletado_as?: string }

const usuarioAtualizar = async (body: UsuarioAtualizarReq) => putData<UsuarioAtualizarRes>(`/usuario`, body)

export type UsuarioInativarRes = { id: number, pessoa_id: number, login: string, senha: string, ultimo_acesso?: string, ultima_mundaca_senha: string, criado_as: string, deletado_as?: string }

const usuarioInativar = async (id: number) => deleteData<UsuarioInativarRes>(`/usuario/${id}`)

export type UsuarioListarRes = { id: number, nome: string, login: string }[]

const usuarioListar = async () => getData<UsuarioListarRes>(`/usuarios`)
const useUsuarioListar = () => useGetData<UsuarioListarRes>(`/usuarios`)

export type UsuarioSalvarConfiguracaoReq = { chave: string, valor: string }
export type UsuarioSalvarConfiguracaoRes = { usuario_id: number, chave: string, valor: string }

const usuarioSalvarConfiguracao = async (body: UsuarioSalvarConfiguracaoReq) => postData<UsuarioSalvarConfiguracaoRes>(`/usuario/configuracao`, body)

export type UsuarioListarConfiguracoesRes = { [index in string]: string }

const usuarioListarConfiguracoes = async () => getData<UsuarioListarConfiguracoesRes>(`/usuario/configuracoes`)
const useUsuarioListarConfiguracoes = () => useGetData<UsuarioListarConfiguracoesRes>(`/usuario/configuracoes`)

export { usuarioCriar, usuarioPegar, useUsuarioPegar, usuarioAtualizar, usuarioInativar, usuarioListar, useUsuarioListar, usuarioSalvarConfiguracao, usuarioListarConfiguracoes, useUsuarioListarConfiguracoes }