import { useState } from "react";

import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import Select from "components/Form/Select";
import Input from "components/Form/Input";
import BrFloatInput from "components/Form/Input/BrFloatInput";
import DateInput from "components/Form/Input/DateInput";
import SearchSelect from "components/Form/Select/SearchSelect";
import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";

import { useCentroCustoListar } from "lib/request/centro_custo";
import { useContaListar } from "lib/request/conta";
import { montaOptionsIdNome } from "lib/montaOptions";
import {
  produtoListar,
  produtoPegar,
  useProdutoListar,
} from "lib/request/produto";
import { useEstoqueListar } from "lib/request/estoque";
import ApiSearchSelect from "components/Form/Select/ApiSearchSelect";
import { clienteFornecedorListar } from "lib/request/cliente_fornecedor";
import { siglaColetaDados } from "lib/produto_quantidade";

function FormProduto({
  index,
  estoques_options,
}: {
  index: number;
  estoques_options: any;
}) {
  const [produtoId, setProdutoId] = useState<number | null>(null);
  const [unidadeMedida, setUnidadeMedida] = useState<string | null>(null);

  if (produtoId && unidadeMedida === null) {
    const localizarUnidadeMedida = async () => {
      const produto = await produtoPegar(produtoId);

      if (produto.data?.unidade_medida_chave) {
        const sigla = siglaColetaDados(produto.data?.unidade_medida_chave);
        setUnidadeMedida(sigla);
      }
    };

    localizarUnidadeMedida();
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <ApiSearchSelect
          label="Produto"
          name={`produto_id[${index}]`}
          fnApiGetOptions={produtoListar}
          onChange={(e) => {
            setUnidadeMedida(null);
            setProdutoId(e.target.value as unknown as number);
          }}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          label="Estoque"
          name={`estoque_id[${index}]`}
          options={estoques_options}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BrFloatInput
          label={`Quantidade (${unidadeMedida || "..."})`}
          name={`quantidade[${index}]`}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BrFloatInput
          label="Valor cobrado (Total R$)"
          name={`valor_cobrado[${index}]`}
          required
        />
      </Grid>
    </Grid>
  );
}

function ContasPagarComMovimentacaoForm() {
  const [indexs, setIndexs] = useState([0]);

  const centro_custos_options = montaOptionsIdNome(useCentroCustoListar());
  const contas_options = montaOptionsIdNome(useContaListar());

  const estoques_options = montaOptionsIdNome(useEstoqueListar());

  const addIndex = () => {
    setIndexs([...indexs, indexs.length]);
  };

  const removeIndex = () => {
    let novo_indexs = [...indexs];
    novo_indexs.pop();
    setIndexs(novo_indexs);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Select
          label="Centro de Custo"
          name="centro_custo_id"
          options={centro_custos_options}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          label="Conta"
          name="conta_id"
          options={contas_options}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ApiSearchSelect
          label="Cliente / Fornecedor"
          name="cliente_fornecedor_id"
          fnApiGetOptions={clienteFornecedorListar}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Quantidade de parcelas"
          name="quantidade_parcelas"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput label="Data de lançamento" name="data_lancamento" required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          label="Data de vencimento"
          name="data_vencimento_primeira_parcela"
          required
        />
      </Grid>

      <Grid item xs={12} sm={12}>
        <Box>
          <Box mb={1}>
            <Typography variant="h4" fontWeight="bold">
              Produtos comprados
            </Typography>
          </Box>
          {indexs.map((index) => (
            <Grid item xs={12} sm={12} key={index}>
              Produto {index + 1}
              <FormProduto index={index} estoques_options={estoques_options} />
            </Grid>
          ))}
          <Button size="small" onClick={addIndex}>
            <AddIcon />
            produto
          </Button>
          {indexs.length > 1 ? (
            <Button size="small" onClick={removeIndex}>
              <RemoveIcon />
              produto
            </Button>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
}

export default ContasPagarComMovimentacaoForm;
