import { useNavigate, useParams } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { handleResponse } from "lib/response/defaultHandler";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import ProdutoValorForm from "../form";
import { useProdutoPegar } from "lib/request/produto";
import { parseBrToFloat } from "lib/parse";
import { maskBrFloat } from "lib/mask";
import {
  produtoValorAtualizar,
  useProdutoValorPegar,
} from "lib/request/produto_valor";

function ProdutoValorEditarPage() {
  const { produto_id, valor_id } = useParams();
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const produto = useProdutoPegar(produto_id as unknown as number);
  const { data } = useProdutoValorPegar(valor_id as unknown as number);

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await produtoValorAtualizar({
        id: parseInt(valor_id as unknown as string),
        produto_id: parseInt(produto_id as unknown as string),
        cliente_fornecedor_id: parseInt(
          event.target.cliente_fornecedor_id.value
        ),
        valor: parseBrToFloat(event.target.valor.value),
        data: event.target.data.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Valor atualizado com sucesso");
        navigate(`/produto/${produto_id}/valores`);
      },
    });
  }

  if (data) {
    data.valor = maskBrFloat(data.valor) as unknown as number;
  }

  return (
    <ContainerTitleBackLayout
      title={`Editando valor - ${produto.data?.nome || "..."}`}
      backTo={`/produto/${produto_id}/valores`}
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        {data && (
          <ProdutoValorForm
            unidadeMedidaChave={produto?.data?.unidade_medida_chave}
            data={data}
          />
        )}
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default ProdutoValorEditarPage;
