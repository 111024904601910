import { Link } from "react-router-dom";

import { ColumnDef } from "@tanstack/react-table";

import DoneIcon from "@mui/icons-material/Done";

import Button from "components/Button";
import IconLink from "components/IconLink";
import SearchIconLink from "components/IconLink/Search";
import EditIconLink from "components/IconLink/Edit";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import {
  SafraPaginateFiltros,
  SafraPaginateRes,
  useSafraPaginate,
} from "lib/request/safra";
import ActionButtons from "elements/Tables/ActionButtons";
import { parseIsoDate } from "lib/parse";
import { maskBrFloat } from "lib/mask";
import useApiTableState from "hooks/useApiTableState";
import DataTableApiPaginate from "elements/Tables/DataTable/DataTableApiPaginate";

function SafraListarPage() {
  const { data, tableState } = useApiTableState<
    SafraPaginateRes,
    SafraPaginateFiltros
  >(useSafraPaginate);

  return (
    <ContainerTitleBackLayout title="Listagem de safras" mb={0}>
      <Link to="/safras/criar">
        <Button>Nova</Button>
      </Link>
      <DataTableApiPaginate<SafraPaginateRes, SafraPaginateFiltros>
        data={data}
        tableState={tableState}
        columns={(): ColumnDef<SafraPaginateRes>[] => [
          {
            accessorKey: "talhao",
            cell: (info) => info.getValue(),
            header: "Talhão",
          },
          {
            accessorKey: "data_plantio",
            cell: (info) => parseIsoDate(info.getValue() as string),
            header: "Data do Plantio",
            meta: {
              filterInputType: "Date",
            },
          },
          {
            accessorKey: "data_colheita",
            cell: (info) => parseIsoDate(info.getValue() as string),
            header: "Data da Colheita",
            meta: {
              filterInputType: "Date",
            },
          },
          {
            accessorKey: "peso_colhido",
            cell: (info) => {
              if (info.getValue()) {
                return maskBrFloat(
                  (
                    (info.getValue() as any) /
                    (info.row.original as any)["area_ha"]
                  ).toFixed(2)
                );
              }
            },
            header: "Colhido (KG / HA)",
            /* meta: {
              filterInputType: "BrFloat",
            }, */
            enableColumnFilter: false,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink to={`/safras/editar/${info.getValue()}`} />
                <SearchIconLink
                  to={`/safras/${info.getValue()}/praticas-agricolas`}
                />
                <IconLink to={`/safras/definir-resultado/${info.getValue()}`}>
                  <DoneIcon />
                </IconLink>
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default SafraListarPage;
