import { forwardRef } from "react";

import InputRoot, { ExtendedTextFieldProps } from "components/Form/Input";
import { maskCnpj } from "lib/mask";

const CnpjInput = forwardRef<HTMLDivElement, ExtendedTextFieldProps>(
  ({ ...rest }: ExtendedTextFieldProps, ref) => {
    const handleMask = (event: any) => {
      if (event.target.value) {
        event.target.value = maskCnpj(event.target.value);
      }
    };

    return (
      <InputRoot
        {...rest}
        ref={ref}
        onInput={handleMask}
        inputProps={{ maxLength: 18 }}
      />
    );
  }
);

export default CnpjInput;
