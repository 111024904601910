import { useNavigate } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { handleResponse } from "lib/response/defaultHandler";
import { centroCustoCriar } from "lib/request/centro_custo";

import CentroCustoForm from "../form";

function CentroCustoCriarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await centroCustoCriar({
        nome: event.target.nome.value,
        descricao: event.target.descricao.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Centro custo criado com sucesso");
        navigate("/centro-de-custo");
      },
    });
  }

  return (
    <ContainerTitleBackLayout
      title="Salvando novo Centro de Custo"
      backTo="/centro-de-custo"
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        <CentroCustoForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default CentroCustoCriarPage;
