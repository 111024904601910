import Box from "components/Box";
import Typography from "components/Typography";
import { ReactNode } from "react";

type TableCellProps = {
  width?: string,
  align?: string,
  padding?: Array<number>,
  noBorder?: boolean,
  children: ReactNode,
}

function TableCell({
  width = "auto",
  align = "left",
  padding = [],
  noBorder = false,
  children
}: TableCellProps) {
  return (
    <Box
      component="th"
      width={width}
      pt={padding[0]}
      pr={padding[1]}
      pb={padding[2]}
      pl={padding[3]}
      // textAlign={align}
      sx={{
        /* border: ({ borders: { borderWidth }, palette: { light } }) =>
          noBorder ? 0 : `${borderWidth[1]} solid ${light.main}`, */
      }}
    >
      <Typography /* component="div" */ variant="body2" color="text">
        {children}
      </Typography>
    </Box>
  );
}

export default TableCell;
