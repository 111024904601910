import { forwardRef, useId, useMemo, useState } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
} from "@mui/material";
import Input from "components/Form/Input";
import SearchIcon from "components/Icon/Search";

type CustomSelectProps = {
  options: { id: number; label: string }[];
};

type MergeProps<T, U> = Omit<T, keyof U> & U;

type ExtendedSelectProps = MergeProps<SelectProps, CustomSelectProps>;

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const SearchSelect = forwardRef<HTMLDivElement, ExtendedSelectProps>(
  ({ label, value, options, required, ...rest }: ExtendedSelectProps, ref) => {
    const id = useId();

    const [selectValue, setSelectValue] = useState(value);
    const [searchText, setSearchText] = useState("");

    const displayedOptions = useMemo(
      () =>
        searchText === ""
          ? options
          : options.filter((option) => containsText(option.label, searchText)),
      [searchText]
    );

    return (
      <FormControl required={required} sx={{ width: "100%", height: "100%" }}>
        <InputLabel id={id}>{label}</InputLabel>
        <MuiSelect
          labelId={id}
          ref={ref}
          label={label}
          sx={{ height: "inherit" }}
          value={selectValue}
          onChange={(e) => setSelectValue(e.target.value)}
          MenuProps={{ autoFocus: false }}
          onClose={() => setSearchText("")}
          {...rest}
        >
          <ListSubheader>
            <Input
              size="small"
              autoFocus
              placeholder="Digite para pesquisar..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions.map((option) => (
            <MenuItem
              key={id + "-" + option.id}
              value={option.id}
              autoFocus={false}
            >
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    );
  }
);

export default SearchSelect;
