import { useState } from "react";

import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";

import { EstadoPegarRes } from "lib/request/estado";

function EstadoForm({ data }: { data?: EstadoPegarRes }) {
  const [nome, setNome] = useState(data?.nome);
  const [sigla, setSigla] = useState(data?.sigla);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Input
          label="Nome"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Sigla"
          name="sigla"
          value={sigla}
          onChange={(e) => setSigla(e.target.value)}
          required
        />
      </Grid>
    </Grid>
  );
}

export default EstadoForm;
