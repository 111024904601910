import { forwardRef } from "react";

import { ExtendedTextFieldProps } from "components/Form/Input";
import DateInput from "..";

{
  /* <SingleInputDateRangeField
  label="Controlled field"
  value={value}
  onChange={(newValue) => setValue(newValue)}
/> */
}

const DateRangeInput = forwardRef<HTMLDivElement, ExtendedTextFieldProps>(
  ({ ...rest }: ExtendedTextFieldProps, ref) => {
    return <DateInput ref={ref} {...rest} />;
  }
);

export default DateRangeInput;
