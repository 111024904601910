import Icon from "@mui/material/Icon";
import { Column } from "@tanstack/react-table";
import Box from "components/Box";
import Button from "components/Button";
import Input from "components/Form/Input";
import BrFloatInput from "components/Form/Input/BrFloatInput";
import DateInput from "components/Form/Input/DateInput";
import DateRangeInput from "components/Form/Input/DateInput/DateRangeInput";
import { parseBrToFloat } from "lib/parse";
import { useState } from "react";

function Filter<T>({
  column,
  setFilter,
}: {
  column: Column<T>;
  setFilter: any;
}) {
  const [open, setOpen] = useState(false);

  const columnId = column.id;

  function filterChangeHandler(event: any) {
    const valor = event.target.value;

    setFilter(columnId, valor);
    setOpen(false);
  }

  function submitHandler(event: any) {
    event.preventDefault();

    let valor = event.target.filtro.value;
    if (column.columnDef.meta?.filterInputType === "BrFloat") {
      valor = parseBrToFloat(valor);
    }

    setFilter(columnId, valor);
    setOpen(false);
  }

  const handleToggleOpen = () => setOpen(!open);

  const renderInput = () => {
    const FilterInput = () => {
      if (column.columnDef.meta?.filterInputType === "Date") {
        return (
          <DateInput
            name="filtro"
            placeholder="Pesquisar..."
            onChange={filterChangeHandler}
          />
        );
      }

      if (column.columnDef.meta?.filterInputType === "BrFloat") {
        return <BrFloatInput name="filtro" placeholder="Pesquisar..." />;
      }

      if (column.columnDef.meta?.filterInputType === "DateRange") {
        return <DateRangeInput name="filtro" placeholder="Pesquisar..." />;
      }

      return <Input name="filtro" placeholder="Pesquisar..." />;
    };

    return (
      <Box component="form" px={3} onSubmit={submitHandler}>
        {FilterInput()}
      </Box>
    );
  };

  return (
    <>
      <Button onClick={handleToggleOpen}>
        <Icon sx={{ fontWeight: "bold", verticalAlign: "sub" }}>
          filter_alt_icon
        </Icon>
      </Button>
      {open ? renderInput() : null}
    </>
  );
}

export default Filter;
