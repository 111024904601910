import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";

import Box from "components/Box";
import Typography from "components/Typography";

import ComplexProjectCard from "elements/Cards/ProjectCards/ComplexProjectCard";

import Header from "pages/safra/pratica-agricola/components/Header";

import logoSlack from "assets/images/small-logos/logo-slack.svg";

import { usePraticaAgricolaListar } from "lib/request/pratica_agricola";
import { useSafraListarContagemUsoProdutoPorPeriodoAgricola } from "lib/request/safra";

function SafraPraticaAgricolaPage() {
  const { safra_id } = useParams();
  const { data } = usePraticaAgricolaListar();

  const usos = useSafraListarContagemUsoProdutoPorPeriodoAgricola(
    safra_id as unknown as number
  );

  let map_usos: { [index: number]: number } = {};
  if (usos.data) {
    for (let i = 0; i < usos.data.length; i++) {
      map_usos[usos.data[i].pratica_agricola_id] = usos.data[i].contagem;
    }
  }

  return (
    <>
      <Header safra_id={safra_id as unknown as number} />
      <Box pb={3}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7}>
            <Box mb={1}>
              <Typography variant="h5">
                As práticas agricolas disponíveis
              </Typography>
            </Box>
            <Box mb={2}>
              <Typography variant="body2" color="text">
                Selecione uma prática agricola e vamos lhe passar a relação de
                como ela já foi utilizada nesta safra.
                <br />
                As sujestões de baseada na eficiência está ativa.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Grid container spacing={3}>
            {!data?.length ? (
              <div>Carregando...</div>
            ) : (
              data.map((pratica_agricola) => (
                <Grid item xs={12} md={6} lg={4} key={pratica_agricola.id}>
                  <Box mb={1.5} mt={1.5}>
                    <ComplexProjectCard
                      // image={logoSlack}
                      image={
                        process.env.REACT_APP_API_URL +
                        pratica_agricola.caminho_arquivo
                      }
                      safra_id={safra_id as unknown as number}
                      pratica_agricola_id={pratica_agricola.id}
                      title={pratica_agricola.nome}
                      description={pratica_agricola?.descricao}
                      useCount={map_usos[pratica_agricola.id] || 0}
                    />
                  </Box>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default SafraPraticaAgricolaPage;
