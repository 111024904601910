import { useState } from "react";
import { useParams } from "react-router-dom";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { usePraticaAgricolaPegar } from "lib/request/pratica_agricola";
import {
  produtoListarParaUtilizarSafra,
  produtoPegarMelhorEficiencia,
  useProdutoListarParaUtilizarSafra,
} from "lib/request/produto";
import Grid from "@mui/material/Grid";
import SearchSelect from "components/Form/Select/SearchSelect";
import Box from "components/Box";
import Button from "components/Button";
import { useSafraPegar } from "lib/request/safra";
import useNotificationBasic from "hooks/notification/useNotificationBasic";
import Typography from "components/Typography";
import DefaultLineChart from "elements/Charts/LineCharts/DefaultLineChart";
import { maskBrFloat } from "lib/mask";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "components/IconButton";
import Icon from "@mui/material/Icon";
import ApiSearchSelect from "components/Form/Select/ApiSearchSelect";

function SafraPraticaAgricolaRecomendacaoUsoPage() {
  const { safra_id, pratica_agricola_id } = useParams();
  const notification = useNotificationBasic();

  const [infoRecomendacao, setInfoRecomendacao] = useState<any>(null);

  const safra = useSafraPegar(safra_id as unknown as number);
  const pratica_agricola = usePraticaAgricolaPegar(
    pratica_agricola_id as unknown as number
  );

  async function submitHandler(event: any) {
    event.preventDefault();

    if (!safra.data) {
      notification.warningNotify(
        "Não foi possível localizar o produto que está sendo plantado, portanto não será possível realizar a recomendação, tente novamente"
      );
      return;
    }

    const resp = await produtoPegarMelhorEficiencia({
      pratica_agricola_id: parseInt(pratica_agricola_id as unknown as string),
      produto_plantado_id: safra.data.produto_plantado_id,
      produto_usar_id: parseInt(event.target.produto_id.value),
    });

    if (!resp.ok) {
      notification.warningNotify(resp.message);
      return;
    }

    if (resp.data) {
      let x = [];
      let y = [];

      for (const info of resp.data.info_eficiencia) {
        x.push(info.quantidade_por_ha);
        y.push(info.peso_colhido);
      }

      setInfoRecomendacao({
        tecnica: {
          kg_por_ha: maskBrFloat(resp.data.quantidade_rec_tecnica),
          reais_por_ha: maskBrFloat(resp.data.custo_rec_tecnica),
          reais_por_ha_defasado: resp.data.custo_defasadao,
        },
        financeira: {
          kg_por_ha: maskBrFloat(resp.data.quantidade_rec_financeira),
          reais_por_ha: maskBrFloat(resp.data.custo_rec_financeira),
          reais_por_ha_defasado: resp.data.custo_defasadao,
        },
        chart: {
          labels: x,
          datasets: [
            {
              label: "Rendimento em KG",
              data: y,
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        },
      });
    }
  }

  const exibirCustoPorHectare = (custo: string, defasado: boolean) => {
    if (custo === "0,00") {
      return;
    }

    return (
      <>
        <Typography variant="overline">
          . Custo/HA: R$ {custo}{" "}
          {defasado ? (
            <Tooltip
              title="Os valor aqui informado pode estar defasado"
              placement="bottom"
            >
              <b style={{ cursor: "pointer" }}>*</b>
            </Tooltip>
          ) : null}
        </Typography>
      </>
    );
  };

  return (
    <ContainerTitleBackLayout
      title={`Recomendação de uso - ${pratica_agricola.data?.nome || "..."}`}
      backTo={`/safras/${safra_id}/praticas-agricolas/${pratica_agricola_id}/utilizacao`}
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <ApiSearchSelect
              label="Insumo"
              name="produto_id"
              fnApiGetOptions={produtoListarParaUtilizarSafra}
              required
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Gerar recomendação
          </Button>
        </Box>
        <Box mt={3}>
          {infoRecomendacao ? (
            <>
              <Typography variant="h5">
                Rendimento em KG / Quantidade aplicada por hectare:
              </Typography>
              <Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} lg={12}>
                    <DefaultLineChart
                      title={
                        <>
                          <Typography variant="overline">
                            Melhor recomendação técnica:{" "}
                            <b>{infoRecomendacao.tecnica.kg_por_ha} KG/HA</b>
                            {exibirCustoPorHectare(
                              infoRecomendacao.tecnica.reais_por_ha,
                              infoRecomendacao.tecnica.reais_por_ha_defasado
                            )}
                          </Typography>
                          <br />
                          <Typography variant="overline">
                            Melhor recomendação financeira:{" "}
                            <b>{infoRecomendacao.financeira.kg_por_ha} KG/HA</b>
                            {exibirCustoPorHectare(
                              infoRecomendacao.financeira.reais_por_ha,
                              infoRecomendacao.financeira.reais_por_ha_defasado
                            )}
                          </Typography>
                        </>
                      }
                      chart={infoRecomendacao.chart}
                    />
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <Typography variant="body2">
              Selecione o produto para gerarmos a recomendação...
            </Typography>
          )}
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default SafraPraticaAgricolaRecomendacaoUsoPage;
