const maskBrFloat = (value: string | number) => {
  const replaceLast = (val: string) => {
    const find = ".";
    const replace = ",";
    const index = val.lastIndexOf(".");

    if (index >= 0) {
      return (
        val.substring(0, index) +
        replace +
        val.substring(index + find.length)
      );
    }

    return val.toString();
  };

  if (value === null) {
    return
  }

  if (typeof value === "number" && value.toString().indexOf('.') === -1) {
    value = value + '.00'
  }

  const valor = value.toString().replace(/\D/g, "");

  if (!valor) {
    return '0,00'
  }

  return replaceLast((parseInt(valor) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, "."));
};

const maskCpf = (value: string | number) => {
  value = value.toString().replace(/\D/g, "")
  value = value.replace(/(\d{3})(\d)/, "$1.$2")
  value = value.replace(/(\d{3})(\d)/, "$1.$2")
  value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2")

  return value
}

const maskCnpj = (value: string | number) => {
  value = value.toString().replace(/\D/g, "")
  value = value.replace(/^(\d{2})(\d)/, "$1.$2")
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
  value = value.replace(/\.(\d{3})(\d)/, ".$1/$2")
  value = value.replace(/(\d{4})(\d)/, "$1-$2")

  return value
}

export {
  maskBrFloat,
  maskCpf,
  maskCnpj
}