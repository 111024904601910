import { getData, postData, useGetData } from "lib/request"

export type ContasPagarCriarReq = { centro_custo_id: number, conta_id: number, pessoa_id: number, quantidade_parcelas: number, valor_cobrado: number, data_lancamento: Date, data_vencimento_primeira_parcela: Date }
export type ContasPagarCriarRes = { id: number, centro_custo_id: number, entidade_id: number, conta_id: number, moeda_id: number, pessoa_id: number, usuario_id: number, numero_parcelas: number, numero_parcelas_abertas: number, valor_cobrado: number, valor_pago: number, valor_multa: number, valor_juros: number, valor_desconto: number, valor_aberto: number, status: string, sinal: number, data_lancamento: Date, data_ultimo_pagamento?: Date, criado_as: string }

const contasPagarCriar = async (body: ContasPagarCriarReq) => postData<ContasPagarCriarRes>(`/contas-a-pagar`, body)

export type ContasPagarPegarRes = { id: number, duplicata_id: number, data_vencimento: Date, numero_parcela: number, valor_cobrado: number, valor_pago: number, valor_multa: number, valor_juros: number, valor_desconto: number, valor_aberto: number, status: string, data_ultimo_pagamento?: Date, criado_as: string }

const contasPagarPegar = async (duplicata_parcela_id: number) => getData<ContasPagarPegarRes>(`/contas-a-pagar/${duplicata_parcela_id}`)
const useContasPagarPegar = (duplicata_parcela_id: number) => useGetData<ContasPagarPegarRes>(`/contas-a-pagar/${duplicata_parcela_id}`)

export type ContasPagarListarRes = { id: number, centro_custo: string, pessoa: string, data_lancamento: Date, data_vencimento: Date, valor_cobrado: number, valor_aberto: number, status: string }[]

const contasPagarListar = async () => getData<ContasPagarListarRes>(`/contas-a-pagar`)
const useContasPagarListar = () => useGetData<ContasPagarListarRes>(`/contas-a-pagar`)

export type ContasPagarCriarComMovimentacaoEstoqueReq = { centro_custo_id: number, conta_id: number, cliente_fornecedor_id: number, quantidade_parcelas: number, data_lancamento: Date, data_vencimento_primeira_parcela: Date, produtos_comprados: { produto_id: number, estoque_id: number, quantidade: number, valor_cobrado: number }[] }
export type ContasPagarCriarComMovimentacaoEstoqueRes = { id: number, centro_custo_id: number, entidade_id: number, conta_id: number, moeda_id: number, pessoa_id: number, usuario_id: number, numero_parcelas: number, numero_parcelas_abertas: number, valor_cobrado: number, valor_pago: number, valor_multa: number, valor_juros: number, valor_desconto: number, valor_aberto: number, status: string, sinal: number, data_lancamento: Date, data_ultimo_pagamento?: Date, criado_as: string }[]

const contasPagarCriarComMovimentacaoEstoque = async (body: ContasPagarCriarComMovimentacaoEstoqueReq) => postData<ContasPagarCriarComMovimentacaoEstoqueRes>(`/contas-a-pagar/movimentacao-estoque`, body)

export type ContasPagarListarHistsRes = { id: number, duplicata_parcela_id: number, usuario_id: number, valor: number, valor_multa: number, valor_juros: number, valor_desconto: number, tipo_lancamento: string, detalhes?: string, data_movimento: Date, criado_as: string }[]

const contasPagarListarHists = async (duplicata_parcela_id: number) => getData<ContasPagarListarHistsRes>(`/contas-a-pagar/${duplicata_parcela_id}/hists`)
const useContasPagarListarHists = (duplicata_parcela_id: number) => useGetData<ContasPagarListarHistsRes>(`/contas-a-pagar/${duplicata_parcela_id}/hists`)

export type ContasPagarBaixarReq = { duplicata_parcela_id: number, valor_pago: number, valor_multa: number, valor_juros: number, valor_desconto: number, detalhes?: string, data_movimento: Date }
export type ContasPagarBaixarRes = { id: number, duplicata_parcela_id: number, usuario_id: number, valor: number, valor_multa: number, valor_juros: number, valor_desconto: number, tipo_lancamento: string, detalhes?: string, data_movimento: Date, criado_as: string }[]

const contasPagarBaixar = async (body: ContasPagarBaixarReq) => postData<ContasPagarBaixarRes>(`/contas-a-pagar/baixar`, body)

export type ContasPagarEstornarReq = { duplicata_parcela_hist_id_estornar: number, detalhes?: string, data_movimento: Date }
export type ContasPagarEstornarRes = { id: number, duplicata_parcela_id: number, usuario_id: number, valor: number, valor_multa: number, valor_juros: number, valor_desconto: number, tipo_lancamento: string, detalhes?: string, data_movimento: Date, criado_as: string }[]

const contasPagarEstornar = async (body: ContasPagarEstornarReq) => postData<ContasPagarEstornarRes>(`/contas-a-pagar/estornar`, body)

export { contasPagarCriar, contasPagarPegar, useContasPagarPegar, contasPagarListar, useContasPagarListar, contasPagarCriarComMovimentacaoEstoque, contasPagarListarHists, useContasPagarListarHists, contasPagarBaixar, contasPagarEstornar }