import { PropsWithChildren, forwardRef } from "react";
import { BoxProps } from '@mui/material/Box';

import BoxRoot from "./BoxRoot";

type CustomBoxProps = {
  variant?: string,
  bgColor?: string,
  color?: string,
  opacity?: number,
  borderRadius?: string,
  shadow?: string,
  coloredShadow?: string,

  to?: string   // NavLink
  src?: string  // img
  alt?: string  // img
}

type MergeProps<T, U> = Omit<T, keyof U> & U;

export type ExtendedBoxProps = MergeProps<BoxProps, CustomBoxProps>;

const Box = forwardRef(
  ({
    variant = "contained",
    bgColor = "transparent",
    color = "dark",
    opacity = 1,
    borderRadius = "none",
    shadow = "none",
    coloredShadow = "none",
    children,
    ...rest
  }: PropsWithChildren<ExtendedBoxProps>, ref) => (
    <BoxRoot
      theme={undefined}
      ref={ref}
      ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow, coloredShadow }}
      {...rest}
    >
      {children}
    </BoxRoot>
  )
);

export default Box;
