import { forwardRef, useId } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
} from "@mui/material";

type CustomSelectProps = {
  options: { id: number | string; label: string }[];
};

type MergeProps<T, U> = Omit<T, keyof U> & U;

type ExtendedSelectProps = MergeProps<SelectProps, CustomSelectProps>;

const Select = forwardRef<HTMLDivElement, ExtendedSelectProps>(
  ({ label, options, value, required, ...rest }: ExtendedSelectProps, ref) => {
    const id = useId();

    return (
      <FormControl required={required} sx={{ width: "100%", height: "100%" }}>
        <InputLabel id={id}>{label}</InputLabel>
        <MuiSelect
          labelId={id}
          ref={ref}
          label={label}
          value={value}
          sx={{ height: "inherit" }}
          {...rest}
        >
          {options.map((option) => (
            <MenuItem key={id + "-" + option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </MuiSelect>
      </FormControl>
    );
  }
);

export default Select;
