import { NavigateOptions } from "react-router-dom"

import useFetch from "lib/useFetch"

export type ResponseType<T> = {
    ok: boolean,
    data?: T,
    message?: string,
    notification_type?: string,
    redirect?: { to: string, options: NavigateOptions }
}

async function makeRequest<T>(url: string, params: RequestInit): Promise<ResponseType<T>> {
    const resp = await fetch(process.env.REACT_APP_API_URL + url, params)

    if (resp.status === 401) {
        // TODO: não ter permissão mas está logado
        return { ok: false, notification_type: 'warning', message: "Você não tem permissão para realizar essa ação", redirect: { to: "/entrar", options: { replace: true } } }
    }

    // Bad Gateway
    if (resp.status === 502) {
        return { ok: false, message: "O servidor não está respondendo no momento, aguarde alguns minutos e tente novamente" }
    }

    if (resp.status >= 500) {
        return { ok: false, message: "Erro interno do servidor" }
    }

    const text = await resp.text()

    if (resp.status === 404) {
        return { ok: false, message: "A ação não pode ser localizada no sistema, contate o suporte!" }
    }

    if (resp.status === 400) {
        // Feio que doi na alma, mas ta aqui
        if (text[0] === '"' && text[text.length - 1] === '"') {
            return { ok: false, message: text.slice(1, text.length - 1) }
        }

        return { ok: false, message: text }
    }

    if (text.length) {
        return { ok: true, data: JSON.parse(text) as T }
    }

    return { ok: true }
}

function monta_url(url: string, pagination?: { page: number }, filtros?: { [index in string]: any }) {
    let params = []

    if (pagination?.page) {
        params.push('page=' + pagination?.page)
    }

    for (const key in filtros) {
        if (filtros[key] !== "") {
            params.push(key + '=' + filtros[key])
        }
    }

    if (params.length) {
        url += '?' + params.join('&')
    }

    return url
}

export function useGetData<T>(url: string, pagination?: { page: number }, filtros?: { [index in string]: any }) {
    return useFetch<T>(
        monta_url(url, pagination, filtros),
        { method: 'GET' }
    )
}

export async function getData<T>(url: string, pagination?: { page: number }, filtros?: { [index in string]: any }) {
    return await makeRequest<T>(
        monta_url(url, pagination, filtros),
        { method: 'GET' }
    )
}

export async function postData<T>(url: string, body: any) {
    return await makeRequest<T>(url, {
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })
}

export async function putData<T>(url: string, body: any) {
    return await makeRequest<T>(url, {
        method: "PUT",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    })
}

export async function deleteData<T>(url: string) {
    return await makeRequest<T>(url, { method: "DELETE" })
}
