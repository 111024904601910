import { useState } from "react";

import Grid from "@mui/material/Grid";

import Box from "components/Box";
import Button from "components/Button";
import BrFloatInput from "components/Form/Input/BrFloatInput";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import {
  SafraPegarRes,
  safraRegistrarResultado,
  useSafraPegar,
} from "lib/request/safra";
import { handleResponse } from "lib/response/defaultHandler";
import { useNavigate, useParams } from "react-router-dom";
import { parseBrToFloat } from "lib/parse";
import { maskBrFloat } from "lib/mask";
import DateInput from "components/Form/Input/DateInput";

function SafraDefinirResultadoForm({ data }: { data: SafraPegarRes }) {
  const [dataColheita, setDataColheita] = useState(data.data_colheita);
  const [pesoColhido, setPesoColhido] = useState(data.peso_colhido);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <DateInput
          label="Data da Colheita"
          name="data_colheita"
          value={dataColheita}
          onChange={(e) => setDataColheita(e.target.value as unknown as Date)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BrFloatInput
          label="Peso colhido (KG)"
          name="peso_colhido"
          value={pesoColhido}
          onChange={(e) => setPesoColhido(e.target.value as unknown as number)}
          required
        />
      </Grid>
    </Grid>
  );
}

function SafraDefinirResultadoPage() {
  const { safra_id } = useParams();
  const { data } = useSafraPegar(safra_id as unknown as number);

  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await safraRegistrarResultado({
        safra_id: parseInt(safra_id as unknown as string),
        data_colheita: event.target.data_colheita.value
          ? event.target.data_colheita.value
          : null,
        peso_colhido: parseBrToFloat(event.target.peso_colhido.value),
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Resultado da safra salvo com sucesso");
        navigate("/safras");
      },
    });
  }

  if (data && data.peso_colhido) {
    data.peso_colhido = maskBrFloat(data.peso_colhido) as unknown as number;
  }

  return (
    <ContainerTitleBackLayout title="Resultado da Safra" backTo="/safras">
      <Box component="form" px={3} onSubmit={submitHandler}>
        {data && <SafraDefinirResultadoForm data={data} />}
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default SafraDefinirResultadoPage;
