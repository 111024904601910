import { useEffect, useState } from "react";
import { DrawerProps } from "@mui/material";

import { useLocation, NavLink } from "react-router-dom";

import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import Box from "components/Box";
import Typography from "components/Typography";

import SidenavCollapse from "components/Sidenav/SidenavCollapse";
import SidenavItem from "components/Sidenav/SidenavItem";

import SidenavRoot from "components/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Sidenav/styles/sidenav";

import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

import { MenuItem } from "vars/menu";

type CustomSidenavProps = {
  color?: string;
  brand?: string;
  brandName: string;
  routes: MenuItem[];
};

type MergeProps<T, U> = Omit<T, keyof U> & U;

type ExtendedSidenavProps = MergeProps<DrawerProps, CustomSidenavProps>;

function Sidenav({
  color = "info",
  brand = "",
  brandName,
  routes,
  ...rest
}: ExtendedSidenavProps) {
  const [openCollapse, setOpenCollapse] = useState("");
  const { controller, dispatch } = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemName = items[items.length - 1];

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    const nome_usuario = localStorage.getItem("usuario_nome");
    const $nome_usuario = document.getElementById("MenuHeaderUserName");

    if (nome_usuario && $nome_usuario) {
      $nome_usuario.innerHTML = nome_usuario;
    }
  }, []);

  useEffect(() => {
    setOpenCollapse(collapseName);
  }, [collapseName]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, miniSidenav || window.innerWidth < 1200, true);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, miniSidenav, transparentSidenav, whiteSidenav]);

  const renderMenuIntem = (item: MenuItem) => {
    if ("type" in item) {
      if (item.type === "collapse") {
        return (
          <SidenavCollapse
            key={item.key}
            name={item.name}
            icon={item.icon}
            active={item.key === collapseName}
            open={openCollapse === item.key}
            onClick={() =>
              openCollapse === item.key
                ? setOpenCollapse("")
                : setOpenCollapse(item.key)
            }
          >
            {item.collapse.map((item) => renderMenuIntem(item))}
          </SidenavCollapse>
        );
      }

      if (item.type === "title") {
        return (
          <Typography
            key={item.key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {item.title}
          </Typography>
        );
      }

      if (item.type === "divider") {
        return (
          <Divider
            key={item.key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }
    }

    if ("route" in item && item.route) {
      return (
        <NavLink
          to={item.route}
          key={item.key} /* sx={{ textDecoration: "none" }} */
        >
          <SidenavItem
            color={color}
            name={item.name}
            active={item.key === itemName}
          />
        </NavLink>
      );
    }

    if ("href" in item) {
      return (
        <Link
          href={item.href}
          key={item.key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem
            color={color}
            name={item.name}
            active={item.key === itemName}
          />
        </Link>
      );
    }
  };

  return (
    <SidenavRoot
      theme={undefined}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      {...rest}
    >
      <Box pt={3} pb={1} px={4} textAlign="center">
        <Box
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <Typography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </Typography>
        </Box>
        <Box component={NavLink} to="/" display="flex" alignItems="center">
          {brand && (
            <Box component="img" src={brand} alt="Brand" width="2rem" />
          )}
          <Box
            width={brandName ? "100%" : undefined}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            {/* <Typography component="h6" variant="button" fontWeight="medium" color={textColor}> */}
            <Typography variant="h6" fontWeight="medium" color={textColor}>
              {brandName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{routes.map((item) => renderMenuIntem(item))}</List>
    </SidenavRoot>
  );
}

export default Sidenav;
