import Grid from "@mui/material/Grid";

import Select from "components/Form/Select";
import Input from "components/Form/Input";
import BrFloatInput from "components/Form/Input/BrFloatInput";
import DateInput from "components/Form/Input/DateInput";

import { useCentroCustoListar } from "lib/request/centro_custo";
import { useContaListar } from "lib/request/conta";
import { montaOptionsIdNome } from "lib/montaOptions";
import ApiSearchSelect from "components/Form/Select/ApiSearchSelect";
import { pessoaListar } from "lib/request/pessoa";

function ContasPagarForm() {
  const centro_custos_options = montaOptionsIdNome(useCentroCustoListar());
  const contas_options = montaOptionsIdNome(useContaListar());

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Select
          label="Centro de Custo"
          name="centro_custo_id"
          options={centro_custos_options}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          label="Conta"
          name="conta_id"
          options={contas_options}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ApiSearchSelect
          label="Pessoa"
          name="pessoa_id"
          fnApiGetOptions={pessoaListar}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Quantidade de parcelas"
          name="quantidade_parcelas"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BrFloatInput
          label="Valor cobrado (R$)"
          name="valor_cobrado"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput label="Data de lançamento" name="data_lancamento" required />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          label="Data de vencimento"
          name="data_vencimento_primeira_parcela"
          required
        />
      </Grid>
    </Grid>
  );
}

export default ContasPagarForm;
