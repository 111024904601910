import { forwardRef } from "react";
import { TextFieldProps } from "@mui/material";

import InputRoot from "components/Form/Input/InputRoot";

type CustomTextFieldProps = {
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
};

type MergeProps<T, U> = Omit<T, keyof U> & U;

export type ExtendedTextFieldProps = MergeProps<
  TextFieldProps,
  CustomTextFieldProps
>;

const Input = forwardRef<HTMLDivElement, ExtendedTextFieldProps>(
  (
    {
      error = false,
      success = false,
      disabled = false,
      ...rest
    }: ExtendedTextFieldProps,
    ref
  ) => (
    <InputRoot
      theme={undefined}
      ref={ref}
      sx={{ width: "100%" }}
      ownerState={{ error, success, disabled }}
      {...rest}
    />
  )
);

export default Input;
