import { Link } from "react-router-dom";

import { ColumnDef } from "@tanstack/react-table";

import Button from "components/Button";

import DataTable from "elements/Tables/DataTable";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import ActionButtons from "elements/Tables/ActionButtons";
import {
  ContasPagarListarRes,
  useContasPagarListar,
} from "lib/request/contas_pagar";
import { parseIsoDate } from "lib/parse";
import { maskBrFloat } from "lib/mask";
import SearchIconLink from "components/IconLink/Search";

import { optionsStatus } from "vars/duplicata_parcela";

function ContasPagarListarPage() {
  const { data } = useContasPagarListar();

  return (
    <ContainerTitleBackLayout title="Listagem de Contas a Pagar" mb={0}>
      <Link to="/contas-a-pagar/criar">
        <Button>Novo</Button>
      </Link>
      <Link to="/contas-a-pagar/criar-com-movimentacao">
        <Button>Novo (Com movimentação de estoque)</Button>
      </Link>
      <DataTable<ContasPagarListarRes>
        data={data || []}
        columns={(): ColumnDef<ContasPagarListarRes>[] => [
          {
            accessorKey: "centro_custo",
            cell: (info) => info.getValue(),
            header: "Centro custo",
          },
          {
            accessorKey: "data_lancamento",
            cell: (info) => parseIsoDate(info.getValue() as string),
            header: "Data lançamento",
          },
          {
            accessorKey: "data_vencimento",
            cell: (info) => parseIsoDate(info.getValue() as string),
            header: "Data vencimento",
          },
          {
            accessorKey: "pessoa",
            cell: (info) => info.getValue(),
            header: "Pessoa",
          },
          {
            accessorKey: "valor_cobrado",
            cell: (info) => maskBrFloat(info.getValue() as string),
            header: "Valor Cobrado",
          },
          {
            accessorKey: "valor_aberto",
            cell: (info) => maskBrFloat(info.getValue() as string),
            header: "Valor Aberto",
          },
          {
            accessorKey: "status",
            cell: (info) =>
              optionsStatus.get(info.getValue() as string) || info.getValue(),
            header: "Situação",
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <SearchIconLink to={`detalhes/${info.getValue()}`} />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default ContasPagarListarPage;
