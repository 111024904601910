import { ColumnDef } from "@tanstack/react-table";

import EditIconLink from "components/IconLink/Edit";

import DataTableApiPaginate from "elements/Tables/DataTable/DataTableApiPaginate";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import ActionButtons from "elements/Tables/ActionButtons";
import ButtonLink from "components/ButtonLink";

import useApiTableState from "hooks/useApiTableState";

import {
  MunicipioPaginateFiltros,
  MunicipioPaginateRes,
  useMunicipioPaginate,
} from "lib/request/municipio";

function MunicipioListarPage() {
  const { data, tableState } = useApiTableState<
    MunicipioPaginateRes,
    MunicipioPaginateFiltros
  >(useMunicipioPaginate);

  return (
    <ContainerTitleBackLayout title="Listagem de municipios" mb={0}>
      <ButtonLink to="/municipios/criar">Novo</ButtonLink>
      <DataTableApiPaginate<MunicipioPaginateRes, MunicipioPaginateFiltros>
        data={data}
        tableState={tableState}
        columns={(): ColumnDef<MunicipioPaginateRes>[] => [
          {
            accessorKey: "estado_sigla",
            cell: (info) => info.getValue(),
            header: "Sigla Estado",
            size: 1000,
          },
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 3000,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink to={`/municipios/editar/${info.getValue()}`} />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default MunicipioListarPage;
