import { deleteData, getData, postData, putData, useGetData } from "lib/request"

export type CentroCustoCriarReq = { centro_custo_id?: number, nome: string, descricao?: string }
export type CentroCustoCriarRes = { id: number, entidade_id: number, centro_custo_id?: number, nome: string, descricao?: string }

const centroCustoCriar = async (body: CentroCustoCriarReq) => postData<CentroCustoCriarRes>(`/centro-custo`, body)

export type CentroCustoPegarRes = { id: number, entidade_id: number, centro_custo_id?: number, nome: string, descricao?: string }

const centroCustoPegar = async (id: number) => getData<CentroCustoPegarRes>(`/centro-custo/${id}`)
const useCentroCustoPegar = (id: number) => useGetData<CentroCustoPegarRes>(`/centro-custo/${id}`)

export type CentroCustoAtualizarReq = { id: number, centro_custo_id?: number, nome: string, descricao?: string }
export type CentroCustoAtualizarRes = { id: number, entidade_id: number, centro_custo_id?: number, nome: string, descricao?: string }

const centroCustoAtualizar = async (body: CentroCustoAtualizarReq) => putData<CentroCustoAtualizarRes>(`/centro-custo`, body)

export type CentroCustoDeletarRes = { id: number, entidade_id: number, centro_custo_id?: number, nome: string, descricao?: string }

const centroCustoDeletar = async (id: number) => deleteData<CentroCustoDeletarRes>(`/centro-custo/${id}`)

export type CentroCustoListarRes = { id: number, entidade_id: number, centro_custo_id?: number, nome: string, descricao?: string }[]

const centroCustoListar = async () => getData<CentroCustoListarRes>(`/centro-custos`)
const useCentroCustoListar = () => useGetData<CentroCustoListarRes>(`/centro-custos`)

export { centroCustoCriar, centroCustoPegar, useCentroCustoPegar, centroCustoAtualizar, centroCustoDeletar, centroCustoListar, useCentroCustoListar }