import React, { useEffect, useRef, useState } from "react";

import {
  Icon,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import DataTableBodyCell from "./components/DataTableBodyCell";

import {
  Column,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

import Pagination from "components/Pagination";
import Box from "components/Box";
import Typography from "components/Typography";
import Input from "components/Form/Input";
import Button from "components/Button";
import Select from "components/Form/Select";
import ActiveFilters from "./components/ActiveFilters";
import { useMaterialUIController } from "context";

function DataTable<T>({
  data,
  columns,
}: {
  data: any;
  columns: () => ColumnDef<T>[];
}) {
  const columnsMemo = React.useMemo<ColumnDef<T>[]>(columns, [columns]);

  const [filters, setFilters] = useState({});

  const setFilter = (field: any, value: any) => {
    let newFilter: any = { ...filters };

    if (value === null || value === "") {
      delete newFilter[field];
    } else {
      newFilter[field] = value;
    }

    setFilters(newFilter);
  };

  return (
    <>
      {filters && Object.keys(filters).length ? (
        <ActiveFilters
          columns={columnsMemo}
          filters={filters}
          setFilter={setFilter}
          showRemoveFilter={false}
        />
      ) : null}
      <Table<T> data={data} columns={columnsMemo} setFilter={setFilter} />
    </>
  );
}

function Table<T>({
  data,
  columns,
  setFilter,
}: {
  data: T[];
  columns: ColumnDef<T>[];
  setFilter: (key: string, value: any) => void;
}) {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  const table = useReactTable({
    data,
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // debugTable: true,
    defaultColumn: {
      minSize: 1,
      // size: Number.MAX_SAFE_INTEGER,
      maxSize: 5000,
    },
  });

  const buttonPreviousPage = useRef<HTMLButtonElement>(null);
  const buttonNextPage = useRef<HTMLButtonElement>(null);

  const pageIndex = table.getState().pagination.pageIndex;
  const pageSize = table.getState().pagination.pageSize;
  const lastPageIndex = table.getPageCount() - 1;

  const total = table.getFilteredRowModel().rows.length;
  const showingFrom = total > 0 ? pageIndex * pageSize + 1 : 0;
  let showingTo = (pageIndex + 1) * pageSize;
  if (showingTo > total) {
    showingTo = total;
  }

  let customizedPageOptions: number[] = [
    pageIndex - 2,
    pageIndex - 1,
    pageIndex,
    pageIndex + 1,
    pageIndex + 2,
  ];
  if (lastPageIndex === -1) {
    customizedPageOptions = [];
  } else if ([0, 1, 2].includes(pageIndex)) {
    customizedPageOptions = [0, 1, 2, 3, 4];
  } else if (
    [lastPageIndex, lastPageIndex - 1, lastPageIndex - 2].includes(pageIndex)
  ) {
    customizedPageOptions = [
      lastPageIndex - 4,
      lastPageIndex - 3,
      lastPageIndex - 2,
      lastPageIndex - 1,
      lastPageIndex,
    ];
  }

  customizedPageOptions = customizedPageOptions.filter(
    (e) => e <= lastPageIndex
  );

  useEffect(() => {
    let controlIsPressed = false;

    function handleKeyUp(event: KeyboardEvent) {
      const key = event.key.toUpperCase();

      if (key === "CONTROL") {
        controlIsPressed = false;
      }
    }

    function handleKeyDown(event: KeyboardEvent) {
      const key = event.key.toUpperCase();

      if (key === "CONTROL") {
        controlIsPressed = true;
        return;
      }

      if (!controlIsPressed) {
        return;
      }

      if (key === "ARROWLEFT") {
        buttonPreviousPage.current?.click();
      }
      if (key === "ARROWRIGHT") {
        buttonNextPage.current?.click();
      }
    }

    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);

    return () => (
      document.removeEventListener("keyup", handleKeyUp),
      document.removeEventListener("keydown", handleKeyDown)
    );
  }, []);

  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell
                  key={header.id}
                  colSpan={header.colSpan}
                  width={header.getSize()}
                  style={{ color: darkMode ? "gray" : "initial" }}
                >
                  {header.isPlaceholder ? null : (
                    <>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getCanFilter() ? (
                        <Filter column={header.column} setFilter={setFilter} />
                      ) : null}
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <DataTableBodyCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </DataTableBodyCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>

      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={2}
      >
        <Box mb={{ xs: 3, sm: 0 }}>
          <Typography variant="button" color="secondary" fontWeight="regular">
            Mostrando {showingFrom}/{showingTo} de {total} registros
          </Typography>
        </Box>
        <Pagination>
          <Pagination
            item
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <Icon sx={{ fontWeight: "bold" }}>keyboard_double_arrow_left</Icon>
          </Pagination>
          <Pagination
            item
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            ref={buttonPreviousPage}
          >
            <Icon sx={{ fontWeight: "bold" }}>keyboard_arrow_left</Icon>
          </Pagination>

          {customizedPageOptions.map((option: number) => (
            <Pagination
              item
              key={option}
              onClick={() => table.setPageIndex(option)}
              active={pageIndex === option}
            >
              {option + 1}
            </Pagination>
          ))}

          <Pagination
            item
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            ref={buttonNextPage}
          >
            <Icon sx={{ fontWeight: "bold" }}>keyboard_arrow_right</Icon>
          </Pagination>
          <Pagination
            item
            onClick={() => table.setPageIndex(lastPageIndex)}
            disabled={!table.getCanNextPage()}
          >
            <Icon sx={{ fontWeight: "bold" }}>keyboard_double_arrow_right</Icon>
          </Pagination>
        </Pagination>
      </Box>
    </TableContainer>
  );
}

function Filter({
  column,
  setFilter,
}: {
  column: Column<any, any>;
  setFilter: (key: string, value: any) => void;
}) {
  const [open, setOpen] = useState(false);

  const columnId = column.id;
  const columnFilterValue = column.getFilterValue();

  const options: any = [];
  for (const index in column.columnDef.meta?.filterOptions) {
    options.push({
      id: index,
      label: column.columnDef.meta?.filterOptions[index],
    });
  }

  function submitHandler(event: any) {
    event.preventDefault();
    setOpen(false);
  }

  function filterHandler(valor: any) {
    setFilter(columnId, valor);
    column.setFilterValue(valor);
  }

  const handleToggleOpen = () => setOpen(!open);

  const renderInput = () => {
    if (options.length) {
      return (
        <Select
          name="filtro"
          value={(columnFilterValue ?? "") as string}
          onChange={(e) => filterHandler(e.target.value)}
          placeholder="Selecione..."
          options={options}
        />
      );
    }

    /* typeof firstValue === "number" ? (
          <>
            <Input
              type="number"
              value={(columnFilterValue as [number, number])?.[0] ?? ""}
              onChange={(e) =>
                column.setFilterValue((old: [number, number]) => [
                  e.target.value,
                  old?.[1],
                ])
              }
              placeholder="Min"
            />
            <Input
              type="number"
              value={(columnFilterValue as [number, number])?.[1] ?? ""}
              onChange={(e) =>
                column.setFilterValue((old: [number, number]) => [
                  old?.[0],
                  e.target.value,
                ])
              }
              placeholder="Max"
            />
          </>
        ) */

    return (
      <Input
        name="filtro"
        value={(columnFilterValue ?? "") as string}
        onChange={(e) => filterHandler(e.target.value)}
        placeholder="Pesquisar..."
      />
    );
  };

  return (
    <>
      <Button onClick={handleToggleOpen}>
        <Icon sx={{ fontWeight: "bold", verticalAlign: "sub" }}>
          filter_alt_icon
        </Icon>
      </Button>
      {open ? (
        <Box component="form" px={3} onSubmit={submitHandler}>
          {renderInput()}
        </Box>
      ) : null}
    </>
  );
}

export default DataTable;
