import { Link, useNavigate } from "react-router-dom";

import { ColumnDef } from "@tanstack/react-table";

import Button from "components/Button";
import EditIconLink from "components/IconLink/Edit";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import {
  ProdutoPaginateFiltros,
  ProdutoPaginateRes,
  produtoDeletar,
  useProdutoListarTipos,
  useProdutoPaginate,
} from "lib/request/produto";
import ActionButtons from "elements/Tables/ActionButtons";
import IconLink from "components/IconLink";
import DataTableApiPaginate from "elements/Tables/DataTable/DataTableApiPaginate";
import useApiTableState from "hooks/useApiTableState";
import DeleteIconButton from "components/IconButton/Delete";
import useAlert from "hooks/notification/useAlert";
import { handleResponse } from "lib/response/defaultHandler";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

function ProdutoListarPage() {
  const { data, tableState } = useApiTableState<
    ProdutoPaginateRes,
    ProdutoPaginateFiltros
  >(useProdutoPaginate);

  const { deleteAlert } = useAlert();
  const notification = useNotificationBasic();
  const navigate = useNavigate();

  const tipos = useProdutoListarTipos();
  const tipos_options: any = {};

  if (tipos.data?.length) {
    for (const data of tipos.data) {
      tipos_options[data.id] = data.nome;
    }
  }

  const handlerDelete = (id: number) => {
    deleteAlert({
      cbDelete: async () => {
        handleResponse({
          response: await produtoDeletar(id),
          notification,
          navigate,
          cbSuccess: () => {
            notification.successNotify("Insumo deletado com sucesso");
            navigate("/reload-screen?tela=/produtos");
          },
        });
      },
    });
  };

  return (
    <ContainerTitleBackLayout title="Listagem de insumos" mb={0}>
      <Link to="/produtos/criar">
        <Button>Novo</Button>
      </Link>
      <DataTableApiPaginate<ProdutoPaginateRes, ProdutoPaginateFiltros>
        data={data}
        tableState={tableState}
        columns={(): ColumnDef<ProdutoPaginateRes>[] => [
          {
            accessorKey: "tipo_id",
            cell: (info) =>
              tipos_options
                ? tipos_options[info.getValue() as unknown as number]
                : null,
            header: "Tipo do insumo",
            size: 1000,
            meta: {
              filterOptions: tipos_options,
            },
            enableColumnFilter: false,
          },
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 3000,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink to={`/produtos/editar/${info.getValue()}`} />
                <IconLink to={`/produto/${info.getValue()}/valores`}>
                  <AttachMoneyIcon />
                </IconLink>
                <DeleteIconButton
                  onClick={() => handlerDelete(info.getValue() as number)}
                />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default ProdutoListarPage;
