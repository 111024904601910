import { useNavigate } from "react-router-dom";

import { usuarioCriar } from "lib/request/usuario";

import FormBasicInfo from "pages/usuario/components/FormBasicInfo";
import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import Box from "components/Box";
import Button from "components/Button";
import { handleResponse } from "lib/response/defaultHandler";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

function UsuarioCriarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(e: any) {
    e.preventDefault();

    handleResponse({
      response: await usuarioCriar({
        nome: e.target.nome.value,
        login: e.target.login.value,
        senha: e.target.senha.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Usuário salvo com sucesso");
        navigate("/usuarios");
      },
    });
  }

  return (
    <ContainerTitleBackLayout title="Informações do usuário" backTo="/usuarios">
      <Box component="form" px={3} onSubmit={submitHandler}>
        <FormBasicInfo />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default UsuarioCriarPage;
