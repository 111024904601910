import Grid from "@mui/material/Grid";

import { useState } from "react";
import Select from "components/Form/Select";
import DateInput from "components/Form/Input/DateInput";
import { EstoqueMovimentacaoPegarRes } from "lib/request/estoque_movimentacao";
import { produtoListar, useProdutoListar } from "lib/request/produto";
import BrFloatInput from "components/Form/Input/BrFloatInput";
import ApiSearchSelect from "components/Form/Select/ApiSearchSelect";

function EstoqueMovimentacaoForm({
  data,
}: {
  data?: EstoqueMovimentacaoPegarRes;
}) {
  const [produtoId, setProdutoId] = useState(data?.produto_id);
  const [quantidade, setQuantidade] = useState(data?.quantidade);
  const [date, setDate] = useState(data?.data);
  const [sinal, setSinal] = useState(data?.sinal);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <ApiSearchSelect
          label="Produto"
          name="produto_id"
          fnApiGetOptions={produtoListar}
          value={produtoId}
          onChange={(e) => setProdutoId(e.target.value as unknown as number)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BrFloatInput
          label="Quantidade"
          name="quantidade"
          value={quantidade}
          onChange={(e) => setQuantidade(e.target.value as unknown as number)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          label="Data"
          name="data"
          value={date}
          onChange={(e: any) => setDate(e.target.value as unknown as Date)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          label="Sinal"
          name="sinal"
          options={[
            { id: 1, label: "Entrada" },
            { id: -1, label: "Saída" },
          ]}
          value={sinal}
          onChange={(e) => setSinal(e.target.value as unknown as number)}
          required
        />
      </Grid>
    </Grid>
  );
}

export default EstoqueMovimentacaoForm;
