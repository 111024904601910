import { createTheme } from "@mui/material/styles";
import { Theme, ThemeOptions } from "@mui/material/styles";
// import { Palette, PaletteOptions } from "@mui/material/styles";
// import { PaletteColor, PaletteColorOptions } from "@mui/material/styles";

/* interface MyPaletteOptions extends PaletteOptions {
    gradients: any,
    white: PaletteColorOptions
}

interface MyPalette extends Palette {
    gradients: any,
    white: PaletteColor
}

interface MyThemeOptions extends ThemeOptions {
    boxShadows: any,
    borders: any,
    functions: any,
    palette: MyPaletteOptions
}

export interface MyTheme extends Theme {
    boxShadows: any,
    borders: any,
    functions: any,
    palette: MyPalette
}
*/

const makeMyTheme = (options: ThemeOptions): Theme => createTheme(options)

export default makeMyTheme
