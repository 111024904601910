import Box from "components/Box";
import Typography from "components/Typography";

import { useMaterialUIController } from "context";

function BillingInformation({
  valor = "0,00",
  multa = "0,00",
  juros = "0,00",
  desconto = "0,00",
}) {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <>
      <Box
        component="li"
        display="flex"
        justifyContent="space-between"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={1}
      >
        <Box width="100%" display="flex" flexDirection="column" lineHeight={1}>
          <Box lineHeight={0}>
            <Typography variant="caption" fontWeight="regular" color="text">
              Valor:&nbsp;
              <Typography variant="caption" fontWeight="medium">
                {valor}.&nbsp;&nbsp;
              </Typography>
            </Typography>

            <Typography variant="caption" fontWeight="regular" color="text">
              Multa:&nbsp;
              <Typography variant="caption" fontWeight="medium">
                {multa}.&nbsp;&nbsp;
              </Typography>
            </Typography>

            <Typography variant="caption" fontWeight="regular" color="text">
              Juros:&nbsp;
              <Typography variant="caption" fontWeight="medium">
                {juros}.&nbsp;&nbsp;
              </Typography>
            </Typography>

            <Typography variant="caption" fontWeight="regular" color="text">
              Desconto:&nbsp;
              <Typography variant="caption" fontWeight="medium">
                {desconto}.&nbsp;&nbsp;
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default BillingInformation;
