import Grid from "@mui/material/Grid";

import { UsuarioPegarRes } from "lib/request/usuario";

import FormField from "pages/usuario/components/FormField";
import { useState } from "react";

function FormBasicInfo({ data }: { data?: UsuarioPegarRes }) {
  const [nome, setNome] = useState(data?.nome);
  const [login, setLogin] = useState(data?.login);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <FormField
          label="Nome"
          name="nome"
          placeholder="Nome"
          value={nome}
          onChange={(e: any) => setNome(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          label="Email"
          name="login"
          placeholder="exemplo@email.com"
          inputProps={{ type: "email" }}
          value={login}
          onChange={(e: any) => setLogin(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          label="Senha"
          name="senha"
          placeholder="******"
          inputProps={{ type: "password" }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          label="Confirmar senha"
          placeholder="******"
          inputProps={{ type: "password" }}
        />
      </Grid>
    </Grid>
  );
}

export default FormBasicInfo;
