import { deleteData, getData, postData, putData, useGetData } from "lib/request"

export type ProdutoValorCriarReq = { produto_id: number, cliente_fornecedor_id?: number, valor: number, data: Date }
export type ProdutoValorCriarRes = { id: number, usuario_id: number, produto_id: number, cliente_fornecedor_id?: number, valor: number, data: Date }

const produtoValorCriar = async (body: ProdutoValorCriarReq) => postData<ProdutoValorCriarRes>(`/produto/valor`, body)

export type ProdutoValorPegarRes = { id: number, usuario_id: number, produto_id: number, cliente_fornecedor_id?: number, valor: number, data: Date }

const produtoValorPegar = async (valor_id: number) => getData<ProdutoValorPegarRes>(`/produto/valores/${valor_id}`)
const useProdutoValorPegar = (valor_id: number) => useGetData<ProdutoValorPegarRes>(`/produto/valores/${valor_id}`)

export type ProdutoValorAtualizarReq = { id: number, produto_id: number, cliente_fornecedor_id?: number, valor: number, data: Date }
export type ProdutoValorAtualizarRes = { id: number, usuario_id: number, produto_id: number, cliente_fornecedor_id?: number, valor: number, data: Date }

const produtoValorAtualizar = async (body: ProdutoValorAtualizarReq) => putData<ProdutoValorAtualizarRes>(`/produto/valor`, body)

export type ProdutoValorDeletarRes = { id: number, usuario_id: number, produto_id: number, cliente_fornecedor_id?: number, valor: number, data: Date }

const produtoValorDeletar = async (id: number) => deleteData<ProdutoValorDeletarRes>(`/produto/valor/${id}`)

export type ProdutoValorListarRes = { id: number, usuario_id: number, produto_id: number, cliente_fornecedor_id?: number, valor: number, data: Date }[]

const produtoValorListar = async (id: number) => getData<ProdutoValorListarRes>(`/produto/${id}/valores`)
const useProdutoValorListar = (id: number) => useGetData<ProdutoValorListarRes>(`/produto/${id}/valores`)

export { produtoValorCriar, produtoValorPegar, useProdutoValorPegar, produtoValorAtualizar, produtoValorDeletar, produtoValorListar, useProdutoValorListar }