import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";

import { useState } from "react";
import { FazendaPegarRes } from "lib/request/fazenda";
import Select from "components/Form/Select";
import { useFazendaResponsavelListar } from "lib/request/fazenda_responsavel";

function FazendaForm({ data }: { data?: FazendaPegarRes }) {
  const [responsavelId, setResponsavelId] = useState(data?.responsavel_id);
  const [nome, setNome] = useState(data?.nome);

  const responsaveis = useFazendaResponsavelListar();
  const responsaveis_options: any[] = [];

  if (responsaveis.data?.length) {
    for (const data of responsaveis.data) {
      responsaveis_options.push({ id: data.pessoa_id, label: data.nome });
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Select
          label="Responsável pela fazenda"
          name="responsavel_id"
          options={responsaveis_options}
          value={responsavelId}
          onChange={(e) =>
            setResponsavelId(e.target.value as unknown as number)
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Nome da fazenda"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </Grid>
    </Grid>
  );
}

export default FazendaForm;
