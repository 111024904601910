import { ColumnDef } from "@tanstack/react-table";

import EditIconLink from "components/IconLink/Edit";

import DataTable from "elements/Tables/DataTable";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import ActionButtons from "elements/Tables/ActionButtons";
import ButtonLink from "components/ButtonLink";
import {
  FazendaResponsavelListarRes,
  useFazendaResponsavelListar,
} from "lib/request/fazenda_responsavel";

function FazendaResponsavelListarPage() {
  const { data } = useFazendaResponsavelListar();

  return (
    <ContainerTitleBackLayout title="Responsáveis fazendas" mb={0}>
      <ButtonLink to="/fazendas/responsaveis/criar">Novo</ButtonLink>
      <DataTable<FazendaResponsavelListarRes>
        data={data || []}
        columns={(): ColumnDef<FazendaResponsavelListarRes>[] => [
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 5000,
          },
          {
            accessorKey: "sobrenome",
            cell: (info) => info.getValue(),
            header: "Sobrenome",
            size: 2000,
          },
          {
            accessorKey: "cpf",
            cell: (info) => info.getValue(),
            header: "CPF",
            size: 2000,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink
                  to={`/fazendas/responsaveis/editar/${info.getValue()}`}
                />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default FazendaResponsavelListarPage;
