import { ColumnDef } from "@tanstack/react-table";

import EditIconLink from "components/IconLink/Edit";

import DataTable from "elements/Tables/DataTable";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import ActionButtons from "elements/Tables/ActionButtons";
import ButtonLink from "components/ButtonLink";
import { EstadoListarRes, useEstadoListar } from "lib/request/estado";

function EstadoListarPage() {
  const { data } = useEstadoListar();

  return (
    <ContainerTitleBackLayout title="Listagem de estados" mb={0}>
      <ButtonLink to="/estados/criar">Novo</ButtonLink>
      <DataTable<EstadoListarRes>
        data={data || []}
        columns={(): ColumnDef<EstadoListarRes>[] => [
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 5000,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink to={`/estados/editar/${info.getValue()}`} />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default EstadoListarPage;
