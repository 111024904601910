import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import { useNavigate, useParams } from "react-router-dom";

import useNotificationBasic from "hooks/notification/useNotificationBasic";
import { handleResponse } from "lib/response/defaultHandler";
import FazendaResponsavelForm from "../form";
import {
  fazendaResponsavelAtualizar,
  fazendaResponsavelCriar,
  useFazendaResponsavelPegar,
} from "lib/request/fazenda_responsavel";
import Box from "components/Box";
import Button from "components/Button";

function FazendaResponsavelEditarPage() {
  const { pessoa_fisica_id } = useParams();

  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const { data } = useFazendaResponsavelPegar(
    pessoa_fisica_id as unknown as number
  );

  async function submitHandler(event: any) {
    event.preventDefault();

    if (!data) {
      return;
    }

    handleResponse({
      response: await fazendaResponsavelAtualizar({
        pessoa_id: data.pessoa_id,
        nome: event.target.nome.value,
        sobrenome: event.target.sobrenome.value,
        cpf: event.target.cpf.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Responsável salvo com sucesso");
        navigate(`/fazendas/responsaveis`);
      },
    });
  }

  return (
    <ContainerTitleBackLayout
      title="Editando responsável fazenda"
      backTo="/fazendas/responsaveis"
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        {data ? <FazendaResponsavelForm data={data} /> : null}
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default FazendaResponsavelEditarPage;
