import { deleteData, getData, postData, putData, useGetData } from "lib/request"

export type EstoqueCriarReq = { nome: string }
export type EstoqueCriarRes = { id: number, entidade_id: number, nome: string }

const estoqueCriar = async (body: EstoqueCriarReq) => postData<EstoqueCriarRes>(`/estoque`, body)

export type EstoquePegarRes = { id: number, entidade_id: number, nome: string }

const estoquePegar = async (id: number) => getData<EstoquePegarRes>(`/estoque/${id}`)
const useEstoquePegar = (id: number) => useGetData<EstoquePegarRes>(`/estoque/${id}`)

export type EstoqueAtualizarReq = { id: number, nome: string }
export type EstoqueAtualizarRes = { id: number, entidade_id: number, nome: string }

const estoqueAtualizar = async (body: EstoqueAtualizarReq) => putData<EstoqueAtualizarRes>(`/estoque`, body)

export type EstoqueDeletarRes = { id: number, entidade_id: number, nome: string }

const estoqueDeletar = async (id: number) => deleteData<EstoqueDeletarRes>(`/estoque/${id}`)

export type EstoqueListarRes = { id: number, entidade_id: number, nome: string }[]

const estoqueListar = async () => getData<EstoqueListarRes>(`/estoques`)
const useEstoqueListar = () => useGetData<EstoqueListarRes>(`/estoques`)

export { estoqueCriar, estoquePegar, useEstoquePegar, estoqueAtualizar, estoqueDeletar, estoqueListar, useEstoqueListar }