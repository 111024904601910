import {
  forwardRef,
  createContext,
  useContext,
  useMemo,
  PropsWithChildren,
} from "react";
import { ButtonProps } from "@mui/material";

import Box from "components/Box";

import PaginationItemRoot from "components/Pagination/PaginationItemRoot";

type PaginationContext = {
  variant: "gradient" | "contained" | "text" | "outlined";
  color:
    | "white"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "light"
    | "dark"
    | "inherit";
  size: "small" | "medium" | "large";
};

const Context = createContext<PaginationContext>({
  variant: "gradient",
  color: "info",
  size: "medium",
});

type PaginationProps = {
  item?: boolean;
  active?: boolean;
};

type MergeProps<T, U> = Omit<T, keyof U> & U;

type ExtendedPaginationProps = MergeProps<ButtonProps, PaginationProps>;

const Pagination = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ExtendedPaginationProps>
>(
  (
    {
      item = false,
      variant = "gradient",
      color = "info",
      size = "medium",
      active = false,
      children,
      ...rest
    }: PropsWithChildren<ExtendedPaginationProps>,
    ref
  ) => {
    const context = useContext(Context);
    const paginationSize = context ? context.size : null;

    const value = useMemo(
      () => ({ variant, color, size }),
      [variant, color, size]
    );

    return (
      <Context.Provider value={value}>
        {item ? (
          <PaginationItemRoot
            theme={undefined}
            {...rest}
            ref={ref}
            variant={active ? context.variant : "outlined"}
            color={active ? context.color : "secondary"}
            iconOnly
            circular
            ownerState={{ variant, active, paginationSize }}
          >
            {children}
          </PaginationItemRoot>
        ) : (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ listStyle: "none" }}
          >
            {children}
          </Box>
        )}
      </Context.Provider>
    );
  }
);

export default Pagination;
