import { Link, useNavigate } from "react-router-dom";

import { ColumnDef } from "@tanstack/react-table";

import Button from "components/Button";
import EditIconLink from "components/IconLink/Edit";

import DataTable from "elements/Tables/DataTable";
import ActionButtons from "elements/Tables/ActionButtons";
import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import {
  ContaListarRes,
  contaDeletar,
  useContaListar,
} from "lib/request/conta";
import DeleteIconButton from "components/IconButton/Delete";
import useAlert from "hooks/notification/useAlert";
import useNotificationBasic from "hooks/notification/useNotificationBasic";
import { handleResponse } from "lib/response/defaultHandler";

function ContaListarPage() {
  const { data } = useContaListar();

  const { deleteAlert } = useAlert();
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const handlerDelete = (id: number) => {
    deleteAlert({
      cbDelete: async () => {
        handleResponse({
          response: await contaDeletar(id),
          notification,
          navigate,
          cbSuccess: () => {
            notification.successNotify("Conta deletada com sucesso");
            navigate(`/reload-screen?tela=/contas`);
          },
        });
      },
    });
  };

  return (
    <ContainerTitleBackLayout title="Listagem de Contas" mb={0}>
      <Link to="/contas/criar">
        <Button>Nova</Button>
      </Link>
      <DataTable<ContaListarRes>
        data={data || []}
        columns={(): ColumnDef<ContaListarRes>[] => [
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 5000,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink to={`/contas/editar/${info.getValue()}`} />
                <DeleteIconButton
                  onClick={() => handlerDelete(info.getValue() as number)}
                />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default ContaListarPage;
