import { useNavigate, useParams } from "react-router-dom";

import { ColumnDef } from "@tanstack/react-table";

import EditIconLink from "components/IconLink/Edit";

import DataTable from "elements/Tables/DataTable";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import {
  SafraListarProdutosUsadosAtravezDaPraticaAgricolaRes,
  safraDeletarUsoProduto,
  useSafraListarProdutosUsadosAtravezDaPraticaAgricola,
} from "lib/request/safra";
import ButtonLink from "components/ButtonLink";
import ActionButtons from "elements/Tables/ActionButtons";
import { usePraticaAgricolaPegar } from "lib/request/pratica_agricola";
import { parseIsoDate } from "lib/parse";
import { maskBrFloat } from "lib/mask";
import DeleteIconButton from "components/IconButton/Delete";
import { handleResponse } from "lib/response/defaultHandler";
import useNotificationBasic from "hooks/notification/useNotificationBasic";
import useAlert from "hooks/notification/useAlert";

function SafraPraticaAgricolaUtilizacaoPage() {
  const notification = useNotificationBasic();
  const navigate = useNavigate();

  const { deleteAlert } = useAlert();

  const { safra_id, pratica_agricola_id } = useParams();
  const pratica_agricola = usePraticaAgricolaPegar(
    pratica_agricola_id as unknown as number
  );
  const { data } = useSafraListarProdutosUsadosAtravezDaPraticaAgricola(
    safra_id as unknown as number,
    pratica_agricola_id as unknown as number
  );

  const handlerDelete = async (id: number) => {
    deleteAlert({
      cbDelete: async () => {
        handleResponse({
          response: await safraDeletarUsoProduto(id),
          notification,
          navigate,
          cbSuccess: () => {
            notification.successNotify("Registro deletado com sucesso");
            navigate(
              `/reload-screen?tela=/safras/${safra_id}/praticas-agricolas/${pratica_agricola_id}/utilizacao`
            );
          },
        });
      },
    });
  };

  return (
    <ContainerTitleBackLayout
      title={`Listagem de insumos utilizados - ${
        pratica_agricola.data?.nome || "..."
      }`}
      mb={0}
      backTo={`/safras/${safra_id}/praticas-agricolas`}
    >
      <ButtonLink
        to={`/safras/${safra_id}/praticas-agricolas/${pratica_agricola_id}/utilizacao/criar`}
      >
        Nova
      </ButtonLink>
      <ButtonLink
        to={`/safras/${safra_id}/praticas-agricolas/${pratica_agricola_id}/recomendacao`}
      >
        Recomendação uso
      </ButtonLink>
      <DataTable<SafraListarProdutosUsadosAtravezDaPraticaAgricolaRes>
        data={data || []}
        columns={(): ColumnDef<SafraListarProdutosUsadosAtravezDaPraticaAgricolaRes>[] => [
          {
            accessorKey: "produto",
            cell: (info) => info.getValue(),
            header: "Produto",
          },
          {
            accessorKey: "quantidade",
            cell: (info) => maskBrFloat(info.getValue() as unknown as number),
            header: "Quantidade",
          },
          {
            accessorKey: "data",
            cell: (info) => parseIsoDate(info.getValue() as string),
            header: "Data",
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink
                  to={`/safras/${safra_id}/praticas-agricolas/${pratica_agricola_id}/utilizacao/editar/${info.getValue()}`}
                />
                <DeleteIconButton
                  onClick={() => handlerDelete(info.getValue() as number)}
                />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default SafraPraticaAgricolaUtilizacaoPage;
