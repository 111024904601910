import { PropsWithChildren, forwardRef } from "react";
import { TypographyProps } from "@mui/material";

import TypographyRoot from "./TypographyRoot";

import { useMaterialUIController } from "context";

type CustomTypographyProps = {
  color?: string,
  fontWeight?: string,
  textTransform?: string,
  verticalAlign?: string,
  textGradient?: boolean,
  opacity?: number,
}

type MergeProps<T, U> = Omit<T, keyof U> & U;

type ExtendedTypographyProps = MergeProps<TypographyProps, CustomTypographyProps>;

const Typography = forwardRef<HTMLDivElement, PropsWithChildren<ExtendedTypographyProps>>((
  {
    color = "dark",
    fontWeight = '',
    textTransform = "none",
    verticalAlign = "unset",
    textGradient = false,
    opacity = 1,
    children,
    ...rest
  }: PropsWithChildren<ExtendedTypographyProps>,
  ref
) => {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <TypographyRoot
      theme={undefined}
      ref={ref}
      ownerState={{
        color,
        textTransform,
        verticalAlign,
        fontWeight,
        opacity,
        textGradient,
        darkMode,
      }}
      {...rest}
    >
      {children}
    </TypographyRoot>
  );
});

export default Typography;
