import Icon from "@mui/material/Icon";

import Box from "components/Box";
import Typography from "components/Typography";

// import { useTimeline } from "../context";

import timelineItem from "./styles";
import { ReactNode } from "react";

type TimelineItemProps = {
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "light";
  icon: ReactNode;
  title: ReactNode;
  detalhes?: string | ReactNode;
  dateTime1?: string;
  dateTime2?: string;
  description?: string;
  lastItem?: boolean;
};

function TimelineItem({
  color = "info",
  icon,
  title,
  detalhes = "",
  dateTime1,
  dateTime2,
  description = "",
  lastItem = false,
}: TimelineItemProps) {
  // const isDark = useTimeline();

  return (
    <Box
      position="relative"
      mb={3}
      // sx={(theme) => timelineItem(theme, { lastItem, isDark })}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        // sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </Box>
      <Box
        ml={5.75}
        pt={description ? 0.7 : 0.5}
        lineHeight={0}
        maxWidth="30rem"
      >
        <Typography
          variant="button"
          fontWeight="medium"
          // color={isDark ? "white" : "dark"}
        >
          {title}
        </Typography>
        {detalhes}

        {dateTime1 && dateTime2 ? (
          <Box mt={0.5}>
            <Typography
              variant="caption" /* color={isDark ? "secondary" : "text"} */
            >
              {dateTime1} | {dateTime2}
            </Typography>
          </Box>
        ) : null}

        <Box mt={2} mb={1.5}>
          {description ? (
            <Typography
              variant="button" /* color={isDark ? "white" : "dark"} */
            >
              {description}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}

export default TimelineItem;
