// import { useState } from "react";

import {
  autenticacaoEntrar,
  useAutenticacaoEstouLogado,
} from "lib/request/autenticacao";
import { handleResponse } from "lib/response/defaultHandler";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

import { /* Link, */ useNavigate } from "react-router-dom";

// import Switch from "@mui/material/Switch";

import Box from "components/Box";
import Input from "components/Form/Input";
import Button from "components/Button";
import PasswordInput from "components/Form/Input/PasswordInput";

import IllustrationLayout from "pages/entrar/components/IllustrationLayout";

// import bgImage from "assets/images/illustrations/illustration-reset.jpg";
// import bgImage from "assets/images/illustrations/boi.jpg";
import bgImage from "assets/images/illustrations/unochapeco.svg";

function EntrarPage() {
  // const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const notification = useNotificationBasic();
  const { ok } = useAutenticacaoEstouLogado();

  if (ok) {
    navigate("/");
  }

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (event) => {
    event.preventDefault();

    handleResponse({
      response: await autenticacaoEntrar({
        login: event.target.email.value,
        senha: event.target.password.value,
      }),
      notification,
      cbSuccess(response) {
        localStorage.setItem("usuario_nome", response.data.usuario_nome);
        navigate("/");
      },
    });
  };

  return (
    <IllustrationLayout
      title="Entrar"
      description="Entre com suas credenciais"
      illustration={bgImage}
      includeNavbar={false}
    >
      <Box component="form" role="form" onSubmit={handleSubmit}>
        <Box mb={2}>
          <Input type="email" name="email" label="Email" fullWidth />
        </Box>
        <Box mb={2}>
          <PasswordInput name="password" label="Senha" fullWidth />
        </Box>
        {/* <Box display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <Typography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Manter conectado
          </Typography>
        </Box> */}
        <Box mt={4} mb={1}>
          <Button
            type="submit"
            variant="gradient"
            color="info"
            size="large"
            fullWidth
          >
            entrar
          </Button>
        </Box>
        {/* <Box mt={3} textAlign="center">
          <Typography variant="button" color="text">
            Ainda não tem uma conta?{" "}
            <Typography
              component={Link}
              to="/entrar"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Criar
            </Typography>
          </Typography>
        </Box> */}
      </Box>
    </IllustrationLayout>
  );
}

export default EntrarPage;
