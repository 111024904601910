import { useNavigate, useParams } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { handleResponse } from "lib/response/defaultHandler";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { fazendaAtualizar, useFazendaPegar } from "lib/request/fazenda";
import FazendaForm from "../form";

function FazendaEditarPage() {
  const { fazenda_id } = useParams();
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const { data } = useFazendaPegar(fazenda_id as unknown as number);

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await fazendaAtualizar({
        id: parseInt(fazenda_id as unknown as string),
        responsavel_id: parseInt(event.target.responsavel_id.value),
        nome: event.target.nome.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Fazenda alterada com sucesso");
        navigate("/fazendas");
      },
    });
  }

  return (
    <ContainerTitleBackLayout title="Editando fazenda" backTo="/fazendas">
      <Box component="form" px={3} onSubmit={submitHandler}>
        {data && <FazendaForm data={data} />}
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default FazendaEditarPage;
