import { useNavigate } from "react-router-dom";
import { useAutenticacaoSair } from "lib/request/autenticacao";

function SairPage() {
  const navigate = useNavigate();

  const useSairHandler = async () => {
    localStorage.setItem("usuario_nome", "");
    await useAutenticacaoSair();
    navigate("/entrar");
  };

  useSairHandler();

  return <p>Saindo...</p>;
}

export default SairPage;
