import React, { useEffect, useRef } from "react";

import {
  Icon,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import DataTableBodyCell from "../components/DataTableBodyCell";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

import Pagination from "components/Pagination";
import Box from "components/Box";
import Typography from "components/Typography";

import { TableState } from "hooks/useApiTableState";
import ActiveFilters from "../components/ActiveFilters";
import Filter from "../components/Filter";
import { useMaterialUIController } from "context";

interface TableDataInterface {
  dados: any;
  total_registros: number;
}

function DataTableApiPaginate<T extends TableDataInterface, U>({
  data,
  columns,
  tableState,
}: {
  data?: T;
  columns: () => ColumnDef<T>[];
  tableState: TableState<U>;
}) {
  const columnsMemo = React.useMemo<ColumnDef<T>[]>(columns, [columns]);

  return (
    <>
      {tableState.filters && Object.keys(tableState.filters).length ? (
        <ActiveFilters<T>
          columns={columnsMemo}
          filters={tableState.filters}
          setFilter={tableState.setFilter}
        />
      ) : null}
      <Table<T, U>
        apiData={data}
        columns={columnsMemo}
        tableState={tableState}
      />
    </>
  );
}

function Table<T extends TableDataInterface, U>({
  apiData,
  columns,
  tableState,
}: {
  apiData?: T;
  columns: ColumnDef<T>[];
  tableState: TableState<U>;
}) {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  // Os dados da API, sempre vai ter 'dados' e 'total_registros'

  const table = useReactTable({
    data: apiData ? apiData.dados : [],
    columns,
    // Pipeline
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // debugTable: true,
    defaultColumn: {
      minSize: 1,
      // size: Number.MAX_SAFE_INTEGER,
      maxSize: 5000,
    },
  });

  const total = apiData ? apiData.total_registros : 0;

  const buttonPreviousPage = useRef<HTMLButtonElement>(null);
  const buttonNextPage = useRef<HTMLButtonElement>(null);

  const pageIndex = tableState.pageIndex;
  const pageSize = 10;
  const lastPageIndex = Math.floor(total / pageSize);

  const showingFrom = total > 0 ? pageIndex * pageSize + 1 : 0;
  let showingTo = (pageIndex + 1) * pageSize;
  if (showingTo > total) {
    showingTo = total;
  }

  let customizedPageOptions: number[] = [
    pageIndex - 2,
    pageIndex - 1,
    pageIndex,
    pageIndex + 1,
    pageIndex + 2,
  ];
  if (lastPageIndex === -1) {
    customizedPageOptions = [];
  } else if ([0, 1, 2].includes(pageIndex)) {
    customizedPageOptions = [0, 1, 2, 3, 4];
  } else if (
    [lastPageIndex, lastPageIndex - 1, lastPageIndex - 2].includes(pageIndex)
  ) {
    customizedPageOptions = [
      lastPageIndex - 4,
      lastPageIndex - 3,
      lastPageIndex - 2,
      lastPageIndex - 1,
      lastPageIndex,
    ];
  }

  customizedPageOptions = customizedPageOptions.filter(
    (e) => e <= lastPageIndex
  );

  useEffect(() => {
    let controlIsPressed = false;

    function handleKeyUp(event: KeyboardEvent) {
      const key = event.key.toUpperCase();

      if (key === "CONTROL") {
        controlIsPressed = false;
      }
    }

    function handleKeyDown(event: KeyboardEvent) {
      const key = event.key.toUpperCase();

      if (key === "CONTROL") {
        controlIsPressed = true;
        return;
      }

      if (!controlIsPressed) {
        return;
      }

      if (key === "ARROWLEFT") {
        buttonPreviousPage.current?.click();
      }
      if (key === "ARROWRIGHT") {
        buttonNextPage.current?.click();
      }
    }

    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);

    return () => (
      document.removeEventListener("keyup", handleKeyUp),
      document.removeEventListener("keydown", handleKeyDown)
    );
  }, []);

  return (
    <TableContainer>
      <MuiTable>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell
                  key={header.id}
                  colSpan={header.colSpan}
                  width={header.getSize()}
                  style={{ color: darkMode ? "gray" : "initial" }}
                >
                  {header.isPlaceholder ? null : (
                    <>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getCanFilter() ? (
                        <Filter<T>
                          column={header.column}
                          setFilter={tableState.setFilter}
                        />
                      ) : null}
                    </>
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <DataTableBodyCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </DataTableBodyCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>

      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={2}
      >
        <Box mb={{ xs: 3, sm: 0 }}>
          <Typography variant="button" color="secondary" fontWeight="regular">
            Mostrando {showingFrom}/{showingTo} de {total} registros
          </Typography>
        </Box>
        <Pagination>
          <Pagination
            item
            onClick={tableState.goToFirstPage}
            disabled={!tableState.getCanPreviousPage()}
          >
            <Icon sx={{ fontWeight: "bold" }}>keyboard_double_arrow_left</Icon>
          </Pagination>
          <Pagination
            item
            onClick={tableState.previousPage}
            disabled={!tableState.getCanPreviousPage()}
            ref={buttonPreviousPage}
          >
            <Icon sx={{ fontWeight: "bold" }}>keyboard_arrow_left</Icon>
          </Pagination>

          {customizedPageOptions.map((option: number) => (
            <Pagination
              item
              key={option}
              onClick={() => tableState.setPageIndex(option)}
              active={pageIndex === option}
              sx={{ width: "auto" }}
            >
              {option + 1}
            </Pagination>
          ))}

          <Pagination
            item
            onClick={() => tableState.nextPage(total)}
            disabled={!tableState.getCanNextPage(total)}
            ref={buttonNextPage}
          >
            <Icon sx={{ fontWeight: "bold" }}>keyboard_arrow_right</Icon>
          </Pagination>
          <Pagination
            item
            onClick={() => tableState.setPageIndex(lastPageIndex)}
            disabled={!tableState.getCanNextPage(total)}
          >
            <Icon sx={{ fontWeight: "bold" }}>keyboard_double_arrow_right</Icon>
          </Pagination>
        </Pagination>
      </Box>
    </TableContainer>
  );
}

export default DataTableApiPaginate;
