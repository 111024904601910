import { deleteData, getData, postData, putData, useGetData } from "lib/request"

export type ProdutoCriarReq = { tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }
export type ProdutoCriarRes = { id: number, tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }

const produtoCriar = async (body: ProdutoCriarReq) => postData<ProdutoCriarRes>(`/produto`, body)

export type ProdutoPegarRes = { id: number, tipo_id: number, unidade_medida_id: number, unidade_medida_nome: string, unidade_medida_chave?: string, nome: string, descricao?: string }

const produtoPegar = async (id: number) => getData<ProdutoPegarRes>(`/produto/${id}`)
const useProdutoPegar = (id: number) => useGetData<ProdutoPegarRes>(`/produto/${id}`)

export type ProdutoAtualizarReq = { id: number, tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }
export type ProdutoAtualizarRes = { id: number, tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }

const produtoAtualizar = async (body: ProdutoAtualizarReq) => putData<ProdutoAtualizarRes>(`/produto`, body)

export type ProdutoDeletarRes = { id: number, tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }

const produtoDeletar = async (id: number) => deleteData<ProdutoDeletarRes>(`/produto/${id}`)

export type ProdutoListarFiltros = { id?: number, nome?: string }
export type ProdutoListarRes = { id: number, tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }[]

const produtoListar = async (filtros?: ProdutoListarFiltros) => getData<ProdutoListarRes>(`/produtos`, undefined, filtros)
const useProdutoListar = (filtros?: ProdutoListarFiltros) => useGetData<ProdutoListarRes>(`/produtos`, undefined, filtros)

export type ProdutoPaginatePagination = { page: number }
export type ProdutoPaginateFiltros = { tipo_id?: number, nome?: string }
export type ProdutoPaginateRes = { dados: { id: number, tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }[], total_registros: number }

const produtoPaginate = async (pagination: ProdutoPaginatePagination, filtros: ProdutoPaginateFiltros) => getData<ProdutoPaginateRes>(`/produtos/paginate`, pagination, filtros)
const useProdutoPaginate = (pagination: ProdutoPaginatePagination, filtros: ProdutoPaginateFiltros) => useGetData<ProdutoPaginateRes>(`/produtos/paginate`, pagination, filtros)

export type ProdutoListarTiposRes = { id: number, tipo: string, nome: string, descricao?: string, chave?: string, ordem?: number, deletado_as?: string, caminho_arquivo: string }[]

const produtoListarTipos = async () => getData<ProdutoListarTiposRes>(`/produtos/tipos`)
const useProdutoListarTipos = () => useGetData<ProdutoListarTiposRes>(`/produtos/tipos`)

export type ProdutoListarParaPlantarFiltros = { id?: number, nome?: string }
export type ProdutoListarParaPlantarRes = { id: number, tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }[]

const produtoListarParaPlantar = async (filtros?: ProdutoListarParaPlantarFiltros) => getData<ProdutoListarParaPlantarRes>(`/produtos/para-plantar`, undefined, filtros)
const useProdutoListarParaPlantar = (filtros?: ProdutoListarParaPlantarFiltros) => useGetData<ProdutoListarParaPlantarRes>(`/produtos/para-plantar`, undefined, filtros)

export type ProdutoListarParaUtilizarSafraFiltros = { id?: number, nome?: string }
export type ProdutoListarParaUtilizarSafraRes = { id: number, tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }[]

const produtoListarParaUtilizarSafra = async (filtros?: ProdutoListarParaUtilizarSafraFiltros) => getData<ProdutoListarParaUtilizarSafraRes>(`/produtos/utilizar-safra`, undefined, filtros)
const useProdutoListarParaUtilizarSafra = (filtros?: ProdutoListarParaUtilizarSafraFiltros) => useGetData<ProdutoListarParaUtilizarSafraRes>(`/produtos/utilizar-safra`, undefined, filtros)

export type ProdutoPegarMelhorEficienciaReq = { pratica_agricola_id: number, produto_plantado_id: number, produto_usar_id: number }
export type ProdutoPegarMelhorEficienciaRes = { pratica_agricola_id: number, produto_plantado_id: number, produto_usar_id: number, info_eficiencia: { peso_colhido: number, quantidade_por_ha: number }[], quantidade_rec_financeira: number, quantidade_rec_tecnica: number, custo_rec_financeira: number, custo_rec_tecnica: number, custo_defasadao: boolean }

const produtoPegarMelhorEficiencia = async (body: ProdutoPegarMelhorEficienciaReq) => postData<ProdutoPegarMelhorEficienciaRes>(`/produto/melhor-eficiencia`, body)

export type ProdutoListarUnidadeMedidasRes = { id: number, tipo_id: number, unidade_medida_id: number, nome: string, descricao?: string }[]

const produtoListarUnidadeMedidas = async () => getData<ProdutoListarUnidadeMedidasRes>(`/produto/unidade-medidas`)
const useProdutoListarUnidadeMedidas = () => useGetData<ProdutoListarUnidadeMedidasRes>(`/produto/unidade-medidas`)

export { produtoCriar, produtoPegar, useProdutoPegar, produtoAtualizar, produtoDeletar, produtoListar, useProdutoListar, produtoPaginate, useProdutoPaginate, produtoListarTipos, useProdutoListarTipos, produtoListarParaPlantar, useProdutoListarParaPlantar, produtoListarParaUtilizarSafra, useProdutoListarParaUtilizarSafra, produtoPegarMelhorEficiencia, produtoListarUnidadeMedidas, useProdutoListarUnidadeMedidas }