import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Box from "components/Box";

import Header from "../detalhes/components/Header";
import OrderInfo from "../detalhes/components/OrderInfo";
import TrackOrder from "./components/TrackOrder";
import OrderSummary from "../detalhes/components/OrderSummary";
import { useContasPagarPegar } from "lib/request/contas_pagar";
import { useParams } from "react-router-dom";

function ContasPagarBaixarPage() {
  const { duplicata_parcela_id } = useParams();

  const { data: duplicata_parcela } = useContasPagarPegar(
    duplicata_parcela_id as unknown as number
  );

  return (
    <Box my={6}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Card>
            <Box pt={2} px={2}>
              {duplicata_parcela ? (
                <Header
                  title="Contas a pagar > Baixar"
                  backTo={"/contas-a-pagar/detalhes/" + duplicata_parcela_id}
                  duplicata_parcela={duplicata_parcela}
                  showBaixarLink={false}
                />
              ) : null}
            </Box>
            <Divider />
            <Box pt={1} pb={3} px={2}>
              {/* <Box mb={3}>
                {duplicata_parcela ? (
                  <OrderInfo
                    duplicata_parcela={duplicata_parcela}
                    showBaixarLink={false}
                  />
                ) : null}
              </Box>
              <Divider /> */}
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={7}>
                    {duplicata_parcela ? (
                      <TrackOrder duplicata_parcela={duplicata_parcela} />
                    ) : null}
                  </Grid>
                  <Grid item xs={12} lg={5} sx={{ ml: "auto" }}>
                    {duplicata_parcela ? (
                      <OrderSummary duplicata_parcela={duplicata_parcela} />
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContasPagarBaixarPage;
