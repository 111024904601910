import { useNavigate } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import useNotificationBasic from "hooks/notification/useNotificationBasic";
import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import { handleResponse } from "lib/response/defaultHandler";
import { clienteFornecedorCriar } from "lib/request/cliente_fornecedor";

import ClienteFornecedorForm from "../form";

function ClienteFornecedorCriarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    const info_salvar: any = { nome: event.target.nome.value };

    if (event.target.inscricao_estadual.value) {
      info_salvar.inscricao_estadual = event.target.inscricao_estadual.value;
    }
    if (event.target.inscricao_municipal.value) {
      info_salvar.inscricao_municipal = event.target.inscricao_municipal.value;
    }

    if (event.target.tipo_pessoa.value === "PF") {
      info_salvar.pessoa_fisica = {
        sobrenome: event.target.sobrenome.value,
        cpf: event.target.cpf.value,
      };
    } else {
      info_salvar.pessoa_juridica = {
        razao_social: event.target.razao_social.value,
        cnpj: event.target.cnpj.value,
      };
    }

    handleResponse({
      response: await clienteFornecedorCriar(info_salvar),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Cliente/Fornecedor criado com sucesso");
        navigate("/clientes-fornecedores");
      },
    });
  }

  return (
    <ContainerTitleBackLayout
      title="Salvando novo Cliente / Fornecedor"
      backTo="/clientes-fornecedores"
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        <ClienteFornecedorForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default ClienteFornecedorCriarPage;
