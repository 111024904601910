import { ColumnDef } from "@tanstack/react-table";
import { maskBrFloat } from "lib/mask";
import { parseIsoDate } from "lib/parse";
import FilterCard from "./FilterCard";
import Box from "components/Box";

function ActiveFilters<T>({
  columns,
  filters,
  setFilter,
  showRemoveFilter,
}: {
  columns: ColumnDef<T>[];
  filters?: Object;
  setFilter: any;
  showRemoveFilter?: boolean;
}) {
  const mapColumns: any = {};
  const mapColumnMetaByAccessorKey: any = {};

  for (const column of columns) {
    if ("accessorKey" in column) {
      mapColumns[column.accessorKey] = column.header;
      mapColumnMetaByAccessorKey[column.accessorKey] = column.meta;
    }
  }

  const showActiveFilter = (value: any) => {
    const removeFilter = () => setFilter(value[0], null);

    const valorFiltro = () => {
      if (mapColumnMetaByAccessorKey[value[0]] !== undefined) {
        if (mapColumnMetaByAccessorKey[value[0]].filterInputType === "Date") {
          return parseIsoDate(value[1]);
        }

        if (
          mapColumnMetaByAccessorKey[value[0]].filterInputType === "BrFloat"
        ) {
          return maskBrFloat(value[1]);
        }
      }

      return value[1];
    };

    return (
      <FilterCard
        key={"filter-" + value[0]}
        removeFilter={
          showRemoveFilter === undefined || showRemoveFilter
            ? removeFilter
            : null
        }
      >
        {mapColumns[value[0]]}: {valorFiltro()}
      </FilterCard>
    );
  };

  return (
    <Box pt={2} pl={3}>
      {Object.entries(filters || {}).map((value: any) =>
        showActiveFilter(value)
      )}
    </Box>
  );
}

export default ActiveFilters;
