import { deleteData, getData, postData, putData, useGetData } from "lib/request"

export type EstoqueMovimentacaoCriarReq = { estoque_id: number, produto_id: number, quantidade: number, data: Date, sinal: number }
export type EstoqueMovimentacaoCriarRes = { id: number, duplicata_detalhe_id?: number, estoque_id: number, produto_id: number, quantidade: number, data: Date, sinal: number, lancamento_manual: boolean, criado_as: string }

const estoqueMovimentacaoCriar = async (body: EstoqueMovimentacaoCriarReq) => postData<EstoqueMovimentacaoCriarRes>(`/estoque/movimentacao`, body)

export type EstoqueMovimentacaoPegarRes = { id: number, estoque_id: number, estoque: string, produto_id: number, produto: string, quantidade: number, data: Date, sinal: number, lancamento_manual: boolean }

const estoqueMovimentacaoPegar = async (estoque_produto_id: number) => getData<EstoqueMovimentacaoPegarRes>(`/estoque/movimentacao/${estoque_produto_id}`)
const useEstoqueMovimentacaoPegar = (estoque_produto_id: number) => useGetData<EstoqueMovimentacaoPegarRes>(`/estoque/movimentacao/${estoque_produto_id}`)

export type EstoqueMovimentacaoAtualizarReq = { id: number, estoque_id: number, produto_id: number, quantidade: number, data: Date, sinal: number }
export type EstoqueMovimentacaoAtualizarRes = { id: number, duplicata_detalhe_id?: number, estoque_id: number, produto_id: number, quantidade: number, data: Date, sinal: number, lancamento_manual: boolean, criado_as: string }

const estoqueMovimentacaoAtualizar = async (body: EstoqueMovimentacaoAtualizarReq) => putData<EstoqueMovimentacaoAtualizarRes>(`/estoque/movimentacao`, body)

export type EstoqueMovimentacaoDeletarRes = { id: number, duplicata_detalhe_id?: number, estoque_id: number, produto_id: number, quantidade: number, data: Date, sinal: number, lancamento_manual: boolean, criado_as: string }

const estoqueMovimentacaoDeletar = async (estoque_produto_id: number) => deleteData<EstoqueMovimentacaoDeletarRes>(`/estoque/movimentacao/${estoque_produto_id}`)

export type EstoqueMovimentacaoPaginatePagination = { page: number }
export type EstoqueMovimentacaoPaginateFiltros = { produto?: string, quantidade?: number, data?: Date }
export type EstoqueMovimentacaoPaginateRes = { dados: { id: number, estoque_id: number, estoque: string, produto_id: number, produto: string, quantidade: number, data: Date, sinal: number, lancamento_manual: boolean }[], total_registros: number }

const estoqueMovimentacaoPaginate = async (estoque_id: number, pagination: EstoqueMovimentacaoPaginatePagination, filtros: EstoqueMovimentacaoPaginateFiltros) => getData<EstoqueMovimentacaoPaginateRes>(`/estoque/${estoque_id}/movimentacoes`, pagination, filtros)
const useEstoqueMovimentacaoPaginate = (estoque_id: number, pagination: EstoqueMovimentacaoPaginatePagination, filtros: EstoqueMovimentacaoPaginateFiltros) => useGetData<EstoqueMovimentacaoPaginateRes>(`/estoque/${estoque_id}/movimentacoes`, pagination, filtros)

export { estoqueMovimentacaoCriar, estoqueMovimentacaoPegar, useEstoqueMovimentacaoPegar, estoqueMovimentacaoAtualizar, estoqueMovimentacaoDeletar, estoqueMovimentacaoPaginate, useEstoqueMovimentacaoPaginate }