import { PropsWithChildren } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import Box from "components/Box";
import Typography from "components/Typography";
import BackButtonLink from "components/ButtonLink/Back";

type ContainerTitleBackProps = {
  title: string,
  backTo?: string,
  mb?: number
}

function ContainerTitleBackLayout({ title, backTo, children, mb = 3 }: PropsWithChildren<ContainerTitleBackProps>) {
  return (
    <Grid container spacing={3} >
      <Grid item xs={12} lg={12} >
        <Card>
          <Box pt={3} pl={3} pb={1}>
            <Typography variant="h5">{title}</Typography>
            {backTo && <BackButtonLink to={backTo} />}
          </Box>
          <Box mb={mb}>
            <Grid container spacing={3} >
              <Grid item xs={12} >
                {children}
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ContainerTitleBackLayout;
