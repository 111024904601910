import { forwardRef, useState } from "react";

import InputRoot, { ExtendedTextFieldProps } from "components/Form/Input";
import InputAdornment from "@mui/material/InputAdornment";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "components/Button";

const PasswordInput = forwardRef<HTMLDivElement, ExtendedTextFieldProps>(
  ({ ...rest }: ExtendedTextFieldProps, ref) => {
    const [showingPasswd, setShowingPasswd] = useState(false);

    const togglePasswordHide = () => {
      setShowingPasswd(!showingPasswd);
    };

    return (
      <InputRoot
        type={showingPasswd ? "text" : "password"}
        {...rest}
        ref={ref}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                iconOnly
                onClick={
                  showingPasswd ? togglePasswordHide : togglePasswordHide
                }
              >
                {showingPasswd ? <Visibility /> : <VisibilityOff />}
              </Button>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default PasswordInput;
