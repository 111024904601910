import { useEffect, ReactNode } from "react";

import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import themeLight from "assets/theme-light";
import themeDark from "assets/theme-dark";

import pageRoutes, { PageRoutesType } from "vars/pages.routes";

import { useMaterialUIController, setAllConfigs, UserConfig } from "context";
import { getData } from "lib/request";
import { handleResponse } from "lib/response/defaultHandler";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

function App() {
  const { controller, dispatch } = useMaterialUIController();
  const { pathname } = useLocation();
  const notification = useNotificationBasic();
  const navigate = useNavigate();

  const { darkMode } = controller;

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
  }, [pathname]);

  useEffect(() => {
    async function updateUserConfig() {
      if (!["/entrar", "/sair"].includes(pathname)) {
        handleResponse({
          response: await getData<Partial<UserConfig>>(
            "/usuario/configuracoes"
          ),
          notification,
          navigate,
          cbSuccess(response) {
            if (response?.data) {
              setAllConfigs(dispatch, response.data);
            }
          },
        });
      }
    }

    updateUserConfig();
  }, []);

  const getRoutes = (
    pages: PageRoutesType,
    layout?: (children: ReactNode) => JSX.Element
  ): ReactNode =>
    pages.map((page) => {
      if ("layout" in page) {
        if (layout !== undefined) {
          return getRoutes(page.pages, (children: ReactNode) =>
            layout(page.layout(children))
          );
        }

        return getRoutes(page.pages, page.layout);
      }

      const key = page.route.replace("/", "-");

      if (layout !== undefined) {
        return (
          <Route
            /* exact */ path={page.route}
            element={layout(page.component)}
            key={key}
          />
        );
      }

      return (
        <Route
          /* exact */ path={page.route}
          element={page.component}
          key={key}
        />
      );
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : themeLight}>
      <CssBaseline />
      <Routes>
        {getRoutes(pageRoutes)}
        <Route path="*" element={<Navigate to="/inicio" />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
