import { ColumnDef } from "@tanstack/react-table";

import DisableIcon from "components/Icon/Disable";
import IconButton from "components/IconButton";
import EditIconLink from "components/IconLink/Edit";

import DataTable from "elements/Tables/DataTable";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import {
  FazendaListarRes,
  fazendaDeletar,
  useFazendaListar,
} from "lib/request/fazenda";
import ActionButtons from "elements/Tables/ActionButtons";
import ButtonLink from "components/ButtonLink";
import DeleteIconButton from "components/IconButton/Delete";
import useAlert from "hooks/notification/useAlert";
import { handleResponse } from "lib/response/defaultHandler";
import { useNavigate } from "react-router-dom";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

function FazendaListarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();
  const { deleteAlert } = useAlert();

  const { data } = useFazendaListar();

  const handlerDisable = (id: number) => {
    deleteAlert({
      cbDelete: async () => {
        handleResponse({
          response: await fazendaDeletar(id),
          notification,
          navigate,
          cbSuccess: () => {
            notification.successNotify("Fazenda deletada com sucesso");
            navigate("/reload-screen?tela=/fazendas");
          },
        });
      },
    });
  };

  return (
    <ContainerTitleBackLayout title="Listagem de fazendas" mb={0}>
      <ButtonLink to="/fazendas/criar">Nova</ButtonLink>
      <DataTable<FazendaListarRes>
        data={data || []}
        columns={(): ColumnDef<FazendaListarRes>[] => [
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 5000,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink to={`/fazendas/editar/${info.getValue()}`} />
                <DeleteIconButton
                  onClick={() => handlerDisable(info.getValue() as number)}
                />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default FazendaListarPage;
