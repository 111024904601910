import { Link, useNavigate, useParams } from "react-router-dom";

import { ColumnDef } from "@tanstack/react-table";

import Button from "components/Button";
import EditIconLink from "components/IconLink/Edit";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import {
  EstoqueMovimentacaoPaginateFiltros,
  EstoqueMovimentacaoPaginateRes,
  estoqueMovimentacaoDeletar,
  useEstoqueMovimentacaoPaginate,
} from "lib/request/estoque_movimentacao";
import ActionButtons from "elements/Tables/ActionButtons";
import { parseIsoDate } from "lib/parse";
import DeleteIconButton from "components/IconButton/Delete";
import { handleResponse } from "lib/response/defaultHandler";
import useNotificationBasic from "hooks/notification/useNotificationBasic";
import Typography from "components/Typography";
import DataTableApiPaginate from "elements/Tables/DataTable/DataTableApiPaginate";
import useApiTableState from "hooks/useApiTableState";
import { useEstoquePegar } from "lib/request/estoque";
import { maskBrFloat } from "lib/mask";
import useAlert from "hooks/notification/useAlert";

function EstoqueMovimentacoesPage() {
  const { estoque_id } = useParams();

  const notification = useNotificationBasic();
  const { deleteAlert } = useAlert();
  const navigate = useNavigate();

  const estoque = useEstoquePegar(estoque_id as unknown as number);

  const { data, tableState } = useApiTableState<
    EstoqueMovimentacaoPaginateRes,
    EstoqueMovimentacaoPaginateFiltros
  >(useEstoqueMovimentacaoPaginate, estoque_id);

  const handlerDelete = async (id: number) => {
    deleteAlert({
      cbDelete: async () => {
        handleResponse({
          response: await estoqueMovimentacaoDeletar(id),
          notification,
          navigate,
          cbSuccess: () => {
            notification.successNotify("Movimentação deletada com sucesso");
            navigate(
              `/reload-screen?tela=/estoques/${estoque_id}/movimentacoes`
            );
          },
        });
      },
    });
  };

  return (
    <ContainerTitleBackLayout
      title={`Insumos em estoque - ${estoque.data?.nome || "..."}`}
      backTo="/estoques"
      mb={0}
    >
      <Link to={`/estoques/${estoque_id}/movimentacoes/criar`}>
        <Button>Nova</Button>
      </Link>
      <DataTableApiPaginate<
        EstoqueMovimentacaoPaginateRes,
        EstoqueMovimentacaoPaginateFiltros
      >
        tableState={tableState}
        data={data}
        columns={(): ColumnDef<EstoqueMovimentacaoPaginateRes>[] => [
          {
            accessorKey: "produto",
            cell: (info) => info.getValue(),
            header: "Produto",
            size: 500,
          },
          {
            accessorKey: "quantidade",
            cell: (info) => maskBrFloat(info.getValue() as string),
            header: "Quantidade",
            size: 200,
          },
          {
            accessorKey: "sinal",
            cell: (info) => (info.getValue() === 1 ? "Entrada" : "Saída"),
            header: "Entrada / Saída",
            size: 200,
          },
          {
            accessorKey: "data",
            cell: (info) => parseIsoDate(info.getValue() as string),
            header: "Data",
            size: 200,
          },
          {
            accessorKey: "id",
            cell: (info) => {
              const row: any = info.row.original;

              if (!row.lancamento_manual) {
                return (
                  <Typography fontSize="small">
                    Lançamento
                    <br />
                    automático
                  </Typography>
                );
              }

              return (
                <ActionButtons>
                  <EditIconLink
                    to={`/estoques/${estoque_id}/movimentacoes/editar/${info.getValue()}`}
                  />
                  <DeleteIconButton
                    onClick={() => handlerDelete(info.getValue() as number)}
                  />
                </ActionButtons>
              );
            },
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default EstoqueMovimentacoesPage;
