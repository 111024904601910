import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";
import Input from "components/Form/Input";

function ChangePassword() {
  const passwordRequirements = [
    "Um caracter especial",
    "Seis digitos",
    "Um número",
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <Box
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1}
      >
        <Typography
          variant="button"
          color="text"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </Typography>
      </Box>
    );
  });

  return (
    <Card id="change-password">
      <Box p={3}>
        <Typography variant="h5">Mudar senha</Typography>
      </Box>
      <Box component="form" pb={3} px={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Input
              fullWidth
              label="Senha atual"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              fullWidth
              label="Nova senha"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              fullWidth
              label="Confirmar nova senha"
              inputProps={{ type: "password", autoComplete: "" }}
            />
          </Grid>
        </Grid>
        <Box mt={6} mb={1}>
          <Typography variant="h5">Requisitos da senha</Typography>
        </Box>
        <Box mb={1}>
          <Typography variant="body2" color="text">
            Siga as instruções para ter uma senha forte
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <Box component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderPasswordRequirements}
          </Box>
          <Box ml="auto">
            <Button /* variant="gradient" */ color="dark" size="small">
              atualizar senha
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default ChangePassword;
