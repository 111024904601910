import { ColumnDef } from "@tanstack/react-table";

import EditIconLink from "components/IconLink/Edit";

import DataTable from "elements/Tables/DataTable";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import ActionButtons from "elements/Tables/ActionButtons";
import ButtonLink from "components/ButtonLink";
import {
  EstoqueListarRes,
  estoqueDeletar,
  useEstoqueListar,
} from "lib/request/estoque";
import SearchIconLink from "components/IconLink/Search";
import { useNavigate } from "react-router-dom";
import useNotificationBasic from "hooks/notification/useNotificationBasic";
import useAlert from "hooks/notification/useAlert";
import { handleResponse } from "lib/response/defaultHandler";
import DeleteIconButton from "components/IconButton/Delete";

function EstoqueListarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();
  const { deleteAlert } = useAlert();

  const { data } = useEstoqueListar();

  const handlerDisable = (id: number) => {
    deleteAlert({
      cbDelete: async () => {
        handleResponse({
          response: await estoqueDeletar(id),
          notification,
          navigate,
          cbSuccess: () => {
            notification.successNotify("Estoque deletado com sucesso");
            navigate("/reload-screen?tela=/estoques");
          },
        });
      },
    });
  };

  return (
    <ContainerTitleBackLayout title="Listagem de estoques" mb={0}>
      <ButtonLink to="/estoques/criar">Novo</ButtonLink>
      <DataTable<EstoqueListarRes>
        data={data || []}
        columns={(): ColumnDef<EstoqueListarRes>[] => [
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 5000,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink to={`/estoques/editar/${info.getValue()}`} />
                <SearchIconLink
                  to={`/estoques/${info.getValue()}/movimentacoes`}
                />
                <DeleteIconButton
                  onClick={() => handlerDisable(info.getValue() as number)}
                />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default EstoqueListarPage;
