import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";

import BrFloatInput from "components/Form/Input/BrFloatInput";
import DateInput from "components/Form/Input/DateInput";
import Select from "components/Form/Select";
import SearchSelect from "components/Form/Select/SearchSelect";
import { useEstoqueListar } from "lib/request/estoque";
import { useProdutoListarParaUtilizarSafra } from "lib/request/produto";

import { SafraPegarUsoProdutoRes } from "lib/request/safra";
import { useState } from "react";

function SafraUtilizacaoProdutoForm({
  data,
}: {
  data?: SafraPegarUsoProdutoRes;
}) {
  const [estoqueId, setEstoqueId] = useState(data?.estoque_id);
  const [produtoId, setProdutoId] = useState(data?.produto_id);
  const [quantidade, setQuantidade] = useState(data?.quantidade);
  const [date, setDate] = useState(data?.data);

  const estoques = useEstoqueListar();
  const produtos = useProdutoListarParaUtilizarSafra();

  const estoques_options: any[] = [];
  const produtos_options: any[] = [];

  if (estoques.data) {
    for (let estoque of estoques.data) {
      estoques_options.push({ id: estoque.id, label: estoque.nome });
    }
  }
  if (produtos.data) {
    for (let produto of produtos.data) {
      produtos_options.push({ id: produto.id, label: produto.nome });
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Select
          label="Estoque"
          name="estoque_id"
          options={estoques_options}
          value={estoqueId}
          onChange={(e) => setEstoqueId(e.target.value as unknown as number)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {produtos_options.length ? (
          <SearchSelect
            label="Produto"
            name="produto_id"
            options={produtos_options}
            value={produtoId}
            onChange={(e) => setProdutoId(e.target.value as unknown as number)}
            required
          />
        ) : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <BrFloatInput
          label="Quantidade"
          name="quantidade"
          value={quantidade}
          onChange={(e) => setQuantidade(e.target.value as unknown as number)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          label="Data da utilização"
          name="data"
          value={date}
          onChange={(e: any) => setDate(e.target.value as unknown as Date)}
          required
        />
      </Grid>
    </Grid>
  );
}

export default SafraUtilizacaoProdutoForm;
