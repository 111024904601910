import { useState } from "react";

import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";

import { MunicipioPegarRes } from "lib/request/municipio";
import { useEstadoListar } from "lib/request/estado";
import SearchSelect from "components/Form/Select/SearchSelect";

function MunicipioForm({ data }: { data?: MunicipioPegarRes }) {
  const [estadoId, setEstadoId] = useState(data?.estado_id);
  const [nome, setNome] = useState(data?.nome);

  const estados = useEstadoListar();
  const estados_options: any[] = [];

  if (estados.data?.length) {
    for (const data of estados.data) {
      estados_options.push({ id: data.id, label: data.nome });
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        {estados_options.length ? (
          <SearchSelect
            label="Estado"
            name="estado_id"
            options={estados_options}
            value={estadoId}
            onChange={(e) => setEstadoId(e.target.value as unknown as number)}
            required
          />
        ) : null}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Nome"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </Grid>
    </Grid>
  );
}

export default MunicipioForm;
