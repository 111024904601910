import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";

import { useState } from "react";
import Select from "components/Form/Select";
import { useClienteFornecedorListar } from "lib/request/cliente_fornecedor";
import BrFloatInput from "components/Form/Input/BrFloatInput";
import DateInput from "components/Form/Input/DateInput";
import { ProdutoValorPegarRes } from "lib/request/produto_valor";
import { siglaColetaDados } from "lib/produto_quantidade";

function ProdutoValorForm({
  unidadeMedidaChave,
  data,
}: {
  unidadeMedidaChave?: string;
  data?: ProdutoValorPegarRes;
}) {
  const [clienteFornecedorId, setClienteFornecedorId] = useState(
    data?.cliente_fornecedor_id
  );
  const [valor, setValor] = useState(data?.valor);
  const [date, setDate] = useState(data?.data);

  const fornecedores = useClienteFornecedorListar();
  const fornecedores_options: any[] = [];

  if (fornecedores.data?.length) {
    for (const data of fornecedores.data) {
      fornecedores_options.push({ id: data.id, label: data.nome });
    }
  }

  const unidadeMedida = siglaColetaDados(unidadeMedidaChave);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Select
          label="Fornecedor"
          name="cliente_fornecedor_id"
          options={fornecedores_options}
          value={clienteFornecedorId}
          onChange={(e) =>
            setClienteFornecedorId(e.target.value as unknown as number)
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BrFloatInput
          label={`Valor R$ (${unidadeMedida || "..."})`}
          name="valor"
          value={valor}
          onChange={(e) => setValor(e.target.value as unknown as number)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          label="Data"
          name="data"
          value={date}
          onChange={(e: any) => setDate(e.target.value as unknown as Date)}
          required
        />
      </Grid>
    </Grid>
  );
}

export default ProdutoValorForm;
