import { ReactNode } from "react";

import { Link } from "react-router-dom";

import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";

import Box from "components/Box";
import Typography from "components/Typography";

type DefaultNavbarDropdownProps = {
  name: string,
  icon?: ReactNode,
  children?: ReactNode,
  collapseStatus?: boolean,
  light?: boolean,
  href?: string,
  route?: string,
  collapse: boolean,

  // TODO
  onClick?: () => void,
  onMouseEnter?: (event: any) => void,
  onMouseLeave?: (event: any) => void
}

function DefaultNavbarDropdown({
  name,
  icon = false,
  children,
  collapseStatus = false,
  light = false,
  href = "",
  route = "",
  collapse,
  ...rest
}: DefaultNavbarDropdownProps) {
  const linkComponent = {
    // TODO
    // component: "a",
    href,
    target: "_blank",
    rel: "noreferrer",
  };

  const routeComponent = {
    component: Link,
    to: route,
  };

  return (
    <>
      <Box
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        color={light ? "white" : "dark"}
        opacity={light ? 1 : 0.6}
        sx={{ cursor: "pointer", userSelect: "none" }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
      >
        {icon && (
          <Typography
            variant="body2"
            lineHeight={1}
            color="inherit"
            sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
          >
            {icon}
          </Typography>
        )}
        <Typography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ fontWeight: "100%", ml: 1, mr: 0.25 }}
        >
          {name}
        </Typography>
        <Typography variant="body2" color={light ? "white" : "dark"} ml="auto">
          <Icon sx={{ fontWeight: "normal", verticalAlign: "middle" }}>
            {collapse && "keyboard_arrow_down"}
          </Icon>
        </Typography>
      </Box>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default DefaultNavbarDropdown;
