import { forwardRef } from "react";

import DeleteIcon from "components/Icon/Delete";

import { ExtendedButtonProps } from "components/Button";
import IconButton from "components/IconButton";

const DeleteIconButton = forwardRef<HTMLButtonElement, ExtendedButtonProps>(
  ({ ...rest }: ExtendedButtonProps, ref) => {
    return (
      <IconButton ref={ref} {...rest}>
        <DeleteIcon />
      </IconButton>
    );
  }
);

export default DeleteIconButton;
