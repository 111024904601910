import Box from "components/Box";
import Typography from "components/Typography";
import { maskBrFloat } from "lib/mask";
import { ContasPagarPegarRes } from "lib/request/contas_pagar";

function OrderSummary({
  duplicata_parcela,
}: {
  duplicata_parcela: ContasPagarPegarRes;
}) {
  const valor_total =
    parseFloat(duplicata_parcela.valor_cobrado.toString()) +
    parseFloat(duplicata_parcela.valor_juros.toString()) +
    parseFloat(duplicata_parcela.valor_multa.toString()) -
    parseFloat(duplicata_parcela.valor_desconto.toString());

  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" fontWeight="medium">
          Totalizadores
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <Typography variant="button" fontWeight="regular" color="text">
          Valor do Título:
        </Typography>
        <Box ml={1}>
          <Typography variant="body2" fontWeight="medium">
            R${maskBrFloat(duplicata_parcela.valor_cobrado)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <Typography variant="button" fontWeight="regular" color="text">
          Juros:
        </Typography>
        <Box ml={1}>
          <Typography variant="body2" fontWeight="medium">
            R${maskBrFloat(duplicata_parcela.valor_juros)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <Typography variant="button" fontWeight="regular" color="text">
          Multa:
        </Typography>
        <Box ml={1}>
          <Typography variant="body2" fontWeight="medium">
            R${maskBrFloat(duplicata_parcela.valor_multa)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <Typography variant="button" fontWeight="regular" color="text">
          Desconto:
        </Typography>
        <Box ml={1}>
          <Typography variant="body2" fontWeight="medium">
            R${maskBrFloat(duplicata_parcela.valor_desconto)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={3}>
        <Typography variant="body1" fontWeight="light" color="text">
          Total pago:
        </Typography>
        <Box ml={1}>
          <Typography variant="body1" fontWeight="medium">
            R$
            {maskBrFloat(valor_total)}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default OrderSummary;
