import { useNavigate } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { produtoCriar } from "lib/request/produto";
import { handleResponse } from "lib/response/defaultHandler";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import ProdutoForm from "../form";

function ProdutoCriarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await produtoCriar({
        tipo_id: parseInt(event.target.tipo_id.value),
        unidade_medida_id: parseInt(event.target.unidade_medida_id.value),
        nome: event.target.nome.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Insumo criado com sucesso");
        navigate("/produtos");
      },
    });
  }

  return (
    <ContainerTitleBackLayout title="Salvando novo insumo" backTo="/produtos">
      <Box component="form" px={3} onSubmit={submitHandler}>
        <ProdutoForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default ProdutoCriarPage;
