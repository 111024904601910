import Box from "components/Box";
import Button from "components/Button";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { handleResponse } from "lib/response/defaultHandler";
import MunicipioForm from "../form";
import { useNavigate } from "react-router-dom";
import { municipioCriar } from "lib/request/municipio";

function MunicipioCriarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await municipioCriar({
        estado_id: parseInt(event.target.estado_id.value),
        nome: event.target.nome.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Município criada com sucesso");
        navigate("/municipios");
      },
    });
  }

  return (
    <ContainerTitleBackLayout
      title="Salvando novo município"
      backTo="/municipios"
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        <MunicipioForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default MunicipioCriarPage;
