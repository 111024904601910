import ClearIcon from "@mui/icons-material/Clear";

import Badge from "components/Badge";
import Button from "components/Button";
import { PropsWithChildren } from "react";

function FilterCard({
  removeFilter,
  children,
}: PropsWithChildren<{ removeFilter?: any }>) {
  const iconRemove = (
    <Button
      variant="text"
      circular={true}
      iconOnly={true}
      sx={{
        minWidth: "inherit",
        minHeight: "inherit",
        width: 0,
        height: 0,
        padding: 0,
      }}
      onClick={removeFilter}
    >
      <ClearIcon />
    </Button>
  );

  return (
    <Badge
      size="xs"
      color="error"
      badgeContent={
        <>
          {children}
          {removeFilter ? iconRemove : null}
        </>
      }
      container
    ></Badge>
  );
}

export default FilterCard;
