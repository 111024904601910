import { deleteData, getData, postData, putData, useGetData } from "lib/request"

export type FazendaCriarReq = { responsavel_id: number, nome: string }
export type FazendaCriarRes = { id: number, responsavel_id: number, nome: string }

const fazendaCriar = async (body: FazendaCriarReq) => postData<FazendaCriarRes>(`/fazenda`, body)

export type FazendaAtualizarReq = { id: number, responsavel_id: number, nome: string }
export type FazendaAtualizarRes = { id: number, responsavel_id: number, nome: string }

const fazendaAtualizar = async (body: FazendaAtualizarReq) => putData<FazendaAtualizarRes>(`/fazenda`, body)

export type FazendaPegarRes = { id: number, responsavel_id: number, nome: string }

const fazendaPegar = async (id: number) => getData<FazendaPegarRes>(`/fazenda/${id}`)
const useFazendaPegar = (id: number) => useGetData<FazendaPegarRes>(`/fazenda/${id}`)

export type FazendaDeletarRes = { id: number, responsavel_id: number, nome: string }

const fazendaDeletar = async (id: number) => deleteData<FazendaDeletarRes>(`/fazenda/${id}`)

export type FazendaListarRes = { id: number, responsavel_id: number, nome: string }[]

const fazendaListar = async () => getData<FazendaListarRes>(`/fazendas`)
const useFazendaListar = () => useGetData<FazendaListarRes>(`/fazendas`)

export { fazendaCriar, fazendaAtualizar, fazendaPegar, useFazendaPegar, fazendaDeletar, fazendaListar, useFazendaListar }