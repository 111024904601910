import { PropsWithChildren } from "react";

import Box from "components/Box";

type PageLayoutProps = {
  background?: "white" | "light" | "default"
}

function PageLayout({ background = "default", children }: PropsWithChildren<PageLayoutProps>) {
  return (
    <Box
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      {children}
    </Box>
  );
}

export default PageLayout;
