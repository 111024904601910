import { Link, useNavigate, useParams } from "react-router-dom";

import { ColumnDef } from "@tanstack/react-table";

import Button from "components/Button";
import EditIconLink from "components/IconLink/Edit";

import DataTable from "elements/Tables/DataTable";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import { ProdutoListarRes, useProdutoPegar } from "lib/request/produto";
import ActionButtons from "elements/Tables/ActionButtons";
import { parseIsoDate } from "lib/parse";
import {
  produtoValorDeletar,
  useProdutoValorListar,
} from "lib/request/produto_valor";
import DeleteIconButton from "components/IconButton/Delete";
import useNotificationBasic from "hooks/notification/useNotificationBasic";
import { handleResponse } from "lib/response/defaultHandler";
import { maskBrFloat } from "lib/mask";
import useAlert from "hooks/notification/useAlert";
import { siglaColetaDados } from "lib/produto_quantidade";

function ProdutoValoresPage() {
  const { produto_id } = useParams();
  const { deleteAlert } = useAlert();

  const notification = useNotificationBasic();
  const navigate = useNavigate();

  const produto = useProdutoPegar(produto_id as unknown as number);
  const { data } = useProdutoValorListar(produto_id as unknown as number);

  const unidadeMedida = siglaColetaDados(produto.data?.unidade_medida_chave);

  const handlerDelete = async (id: number) => {
    deleteAlert({
      cbDelete: async () => {
        handleResponse({
          response: await produtoValorDeletar(id),
          notification,
          navigate,
          cbSuccess: () => {
            notification.successNotify("Valor do insumo deletado com sucesso");
            navigate(`/reload-screen?tela=/produto/${produto_id}/valores`);
          },
        });
      },
    });
  };

  return (
    <ContainerTitleBackLayout
      title={`Valores do insumo - ${produto.data?.nome || "..."}`}
      backTo="/produtos"
      mb={0}
    >
      <Link to={`/produto/${produto_id}/valores/criar`}>
        <Button>Novo</Button>
      </Link>
      <DataTable<ProdutoListarRes>
        data={data || []}
        columns={(): ColumnDef<ProdutoListarRes>[] => [
          {
            accessorKey: "valor",
            cell: (info) => "R$ " + maskBrFloat(info.getValue() as string),
            header: `Valor (R$) / ${unidadeMedida || "..."}`,
          },
          {
            accessorKey: "data",
            cell: (info) => parseIsoDate(info.getValue() as string),
            header: "Data",
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink
                  to={`/produto/${produto_id}/valores/editar/${info.getValue()}`}
                />
                <DeleteIconButton
                  onClick={() => handlerDelete(info.getValue() as number)}
                />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default ProdutoValoresPage;
