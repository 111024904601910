import { PropsWithChildren, forwardRef } from "react";
import { AvatarProps } from "@mui/material";

import AvatarRoot from "./AvatarRoot";

type CustomAvatarProps = {
  bgColor?: string,
  size?: string,
  shadow?: string
}

type MergeProps<T, U> = Omit<T, keyof U> & U;

type ExtendedAvatarProps = MergeProps<AvatarProps, CustomAvatarProps>;

const Avatar = forwardRef<HTMLDivElement, PropsWithChildren<ExtendedAvatarProps>>((
  { bgColor = "transparent", size = "md", shadow = "none", ...rest }: PropsWithChildren<ExtendedAvatarProps>, ref
) => (
  <AvatarRoot theme={undefined} ref={ref} ownerState={{ shadow, bgColor, size }} {...rest} />
));

export default Avatar;
