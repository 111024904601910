import { useNavigate, useParams } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { handleResponse } from "lib/response/defaultHandler";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import EstadoForm from "../form";
import { estadoAtualizar, useEstadoPegar } from "lib/request/estado";

function EstadoEditarPage() {
  const { estado_id } = useParams();
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const { data } = useEstadoPegar(estado_id as unknown as number);

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await estadoAtualizar({
        id: parseInt(estado_id as unknown as string),
        nome: event.target.nome.value,
        sigla: event.target.sigla.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Estado alterado com sucesso");
        navigate("/estados");
      },
    });
  }

  return (
    <ContainerTitleBackLayout title="Editando estado" backTo="/estados">
      <Box component="form" px={3} onSubmit={submitHandler}>
        {data && <EstadoForm data={data} />}
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default EstadoEditarPage;
