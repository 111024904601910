import Box from "components/Box";
import Button from "components/Button";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import { safraAtualizar, useSafraPegar } from "lib/request/safra";
import { handleResponse } from "lib/response/defaultHandler";
import { useNavigate, useParams } from "react-router-dom";
import SafraForm from "../form";
import { parseBrToFloat } from "lib/parse";
import { maskBrFloat } from "lib/mask";

function SafraEditarPage() {
  const { safra_id } = useParams();
  const { data } = useSafraPegar(safra_id as unknown as number);

  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await safraAtualizar({
        id: parseInt(safra_id as unknown as string),
        fazenda_id: parseInt(event.target.fazenda_id.value),
        sistema_manejo_id: parseInt(event.target.sistema_manejo_id.value),
        periodo_agricola_id: parseInt(event.target.periodo_agricola_id.value),
        produto_plantado_id: parseInt(event.target.produto_plantado_id.value),
        talhao: event.target.talhao.value,
        area_ha: parseBrToFloat(event.target.area_ha.value),
        data_plantio: event.target.data_plantio.value
          ? event.target.data_plantio.value
          : null,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Safra alterada com sucesso");
        navigate("/safras");
      },
    });
  }

  if (data) {
    data.area_ha = maskBrFloat(data.area_ha) as unknown as number;
  }

  return (
    <ContainerTitleBackLayout title="Editando safra" backTo="/safras">
      <Box component="form" px={3} onSubmit={submitHandler}>
        {data && <SafraForm data={data} />}
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default SafraEditarPage;
