import { getData, postData, putData, useGetData } from "lib/request"

export type EstadoCriarReq = { nome: string, sigla: string }
export type EstadoCriarRes = { id: number, nome: string, sigla: string }

const estadoCriar = async (body: EstadoCriarReq) => postData<EstadoCriarRes>(`/estado`, body)

export type EstadoPegarRes = { id: number, nome: string, sigla: string }

const estadoPegar = async (id: number) => getData<EstadoPegarRes>(`/estado/${id}`)
const useEstadoPegar = (id: number) => useGetData<EstadoPegarRes>(`/estado/${id}`)

export type EstadoAtualizarReq = { id: number, nome: string, sigla: string }
export type EstadoAtualizarRes = { id: number, nome: string, sigla: string }

const estadoAtualizar = async (body: EstadoAtualizarReq) => putData<EstadoAtualizarRes>(`/estado`, body)

export type EstadoListarRes = { id: number, nome: string, sigla: string }[]

const estadoListar = async () => getData<EstadoListarRes>(`/estados`)
const useEstadoListar = () => useGetData<EstadoListarRes>(`/estados`)

export { estadoCriar, estadoPegar, useEstadoPegar, estadoAtualizar, estadoListar, useEstadoListar }