import { PropsWithChildren, ReactNode } from "react";

import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

import Box, { ExtendedBoxProps } from "components/Box";

import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "./styles/sidenavCollapse";

import { useMaterialUIController } from "context";

type SidenavCollapseProps = {
  icon: ReactNode;
  name: string | ReactNode;
  active?: boolean;
  noCollapse?: boolean;
  open?: boolean;
};

type MergeProps<T, U> = Omit<T, keyof U> & U;

export type ExtendedSidenavCollapseProps = MergeProps<
  ExtendedBoxProps,
  SidenavCollapseProps
>;

function SidenavCollapse({
  icon,
  name,
  children,
  active = false,
  noCollapse = false,
  open = false,
  ...rest
}: PropsWithChildren<ExtendedSidenavCollapseProps>) {
  const { controller } = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  return (
    <>
      <ListItem component="li">
        <Box
          sx={(theme) =>
            collapseItem(theme, {
              active,
              transparentSidenav,
              whiteSidenav,
              darkMode,
            })
          }
          {...rest}
        >
          <ListItemIcon
            sx={(theme) =>
              collapseIconBox(theme, {
                transparentSidenav,
                whiteSidenav,
                darkMode,
              })
            }
          >
            {typeof icon === "string" ? (
              <Icon sx={(theme) => collapseIcon(theme, { active })}>
                {icon}
              </Icon>
            ) : (
              icon
            )}
          </ListItemIcon>

          <ListItemText
            primary={name}
            sx={(theme) =>
              collapseText(theme, {
                miniSidenav,
                transparentSidenav,
                active,
              })
            }
          />

          <Icon
            sx={(theme) =>
              collapseArrow(theme, {
                noCollapse,
                transparentSidenav,
                whiteSidenav,
                miniSidenav,
                open,
                active,
                darkMode,
              })
            }
          >
            expand_less
          </Icon>
        </Box>
      </ListItem>

      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default SidenavCollapse;
