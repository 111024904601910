import { usuarioSalvarConfiguracao } from "lib/request/usuario";

import React, { createContext, useContext, useMemo, useReducer } from "react";

const stringConfigs = ["SIDENAV_COLOR"];

export type UserConfig = {
  readonly [index in
    | "MINI_SIDENAV"
    | "TRANSPARENT_SIDENAV"
    | "WHITE_SIDENAV"
    | "SIDENAV_COLOR"
    | "FIXED_NAVBAR"
    | "DARKMODE"]: string;
};

type MaterialState = {
  miniSidenav: boolean;
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  sidenavColor: string;
  transparentNavbar: boolean;
  fixedNavbar: boolean;
  openConfigurator: boolean;
  darkMode: boolean;
};

const initialState: MaterialState = {
  miniSidenav: false,
  transparentSidenav: false,
  whiteSidenav: false,
  sidenavColor: "info",
  transparentNavbar: true,
  fixedNavbar: true,
  openConfigurator: false,
  darkMode: false,
};

type Action =
  | { type: "MINI_SIDENAV"; value: boolean; dontSaveConfig?: boolean }
  | { type: "TRANSPARENT_SIDENAV"; value: boolean; dontSaveConfig?: boolean }
  | { type: "WHITE_SIDENAV"; value: boolean; dontSaveConfig?: boolean }
  | { type: "SIDENAV_COLOR"; value: string; dontSaveConfig?: boolean }
  | { type: "TRANSPARENT_NAVBAR"; value: boolean; dontSaveConfig?: boolean }
  | { type: "FIXED_NAVBAR"; value: boolean; dontSaveConfig?: boolean }
  | { type: "OPEN_CONFIGURATOR"; value: boolean; dontSaveConfig?: boolean }
  | { type: "DARKMODE"; value: boolean; dontSaveConfig?: boolean };

type MaterialUIContext =
  | {
      controller: MaterialState;
      dispatch: React.Dispatch<Action>;
    }
  | undefined;

const MaterialUI = createContext<MaterialUIContext>(undefined);
MaterialUI.displayName = "MaterialUIContext";

function saveConfig(action: Action) {
  if (action.dontSaveConfig) {
    return;
  }

  if (stringConfigs.includes(action.type)) {
    usuarioSalvarConfiguracao({
      chave: action.type,
      valor: action.value.toString(),
    });
  } else {
    usuarioSalvarConfiguracao({
      chave: action.type,
      valor: action.value ? "1" : "0",
    });
  }
}

function reducer(state: MaterialState, action: Action) {
  switch (action.type) {
    case "OPEN_CONFIGURATOR":
      return { ...state, openConfigurator: action.value };
    case "TRANSPARENT_NAVBAR":
      return { ...state, transparentNavbar: action.value };
    case "MINI_SIDENAV":
      if (state.miniSidenav !== action.value) saveConfig(action);
      return { ...state, miniSidenav: action.value };
    case "TRANSPARENT_SIDENAV":
      if (state.transparentSidenav !== action.value) saveConfig(action);
      return { ...state, transparentSidenav: action.value };
    case "WHITE_SIDENAV":
      if (state.whiteSidenav !== action.value) saveConfig(action);
      return { ...state, whiteSidenav: action.value };
    case "SIDENAV_COLOR":
      if (state.sidenavColor !== action.value) saveConfig(action);
      return { ...state, sidenavColor: action.value };
    case "FIXED_NAVBAR":
      if (state.fixedNavbar !== action.value) saveConfig(action);
      return { ...state, fixedNavbar: action.value };
    case "DARKMODE":
      if (state.darkMode !== action.value) saveConfig(action);
      return { ...state, darkMode: action.value };
  }
}

function MaterialUIControllerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [controller, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(
    () => ({ controller, dispatch }),
    [controller, dispatch]
  );

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

const setAllConfigs = (
  dispatch: React.Dispatch<Action>,
  configs: Partial<UserConfig>
) => {
  for (const config of Object.entries(configs)) {
    const type = config[0];
    const value = stringConfigs.includes(type) ? config[1] : config[1] === "1";

    dispatch({ type, value, dontSaveConfig: true } as Action);
  }
};

const setMiniSidenav = (
  dispatch: React.Dispatch<Action>,
  value: boolean,
  dontSaveConfig = false
) => dispatch({ type: "MINI_SIDENAV", value, dontSaveConfig });
const setTransparentSidenav = (
  dispatch: React.Dispatch<Action>,
  value: boolean
) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch: React.Dispatch<Action>, value: boolean) =>
  dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch: React.Dispatch<Action>, value: string) =>
  dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (
  dispatch: React.Dispatch<Action>,
  value: boolean
) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch: React.Dispatch<Action>, value: boolean) =>
  dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (
  dispatch: React.Dispatch<Action>,
  value: boolean
) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDarkMode = (dispatch: React.Dispatch<Action>, value: boolean) =>
  dispatch({ type: "DARKMODE", value });

export {
  MaterialUIControllerProvider,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setFixedNavbar,
  setOpenConfigurator,
  setDarkMode,
  setAllConfigs,
};
