import { useNavigate, useParams } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { handleResponse } from "lib/response/defaultHandler";
import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import CentroCustoForm from "../form";
import {
  centroCustoAtualizar,
  useCentroCustoPegar,
} from "lib/request/centro_custo";

function CentroCustoEditarPage() {
  const { centro_custo_id } = useParams();
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const { data } = useCentroCustoPegar(centro_custo_id as unknown as number);

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await centroCustoAtualizar({
        id: parseInt(centro_custo_id as unknown as string),
        nome: event.target.nome.value,
        descricao: event.target.descricao.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Centro de Custo alterado com sucesso");
        navigate("/centro-de-custo");
      },
    });
  }

  return (
    <ContainerTitleBackLayout
      title="Editando Centro de Custo"
      backTo="/centro-de-custo"
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        {data && <CentroCustoForm data={data} />}
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default CentroCustoEditarPage;
