import { getData, postData, putData, useGetData } from "lib/request"

export type FazendaResponsavelCriarReq = { nome: string, sobrenome: string, cpf: string }
export type FazendaResponsavelCriarRes = { id: number, pessoa_id: number, nome: string, sobrenome: string, cpf: string }

const fazendaResponsavelCriar = async (body: FazendaResponsavelCriarReq) => postData<FazendaResponsavelCriarRes>(`/fazenda/responsavel`, body)

export type FazendaResponsavelAtualizarReq = { pessoa_id: number, nome: string, sobrenome: string, cpf: string }
export type FazendaResponsavelAtualizarRes = { id: number, pessoa_id: number, nome: string, sobrenome: string, cpf: string }

const fazendaResponsavelAtualizar = async (body: FazendaResponsavelAtualizarReq) => putData<FazendaResponsavelAtualizarRes>(`/fazenda/responsavel`, body)

export type FazendaResponsavelPegarRes = { id: number, pessoa_id: number, nome: string, sobrenome: string, cpf: string }

const fazendaResponsavelPegar = async (pessoa_fisica_id: number) => getData<FazendaResponsavelPegarRes>(`/fazenda/responsavel/${pessoa_fisica_id}`)
const useFazendaResponsavelPegar = (pessoa_fisica_id: number) => useGetData<FazendaResponsavelPegarRes>(`/fazenda/responsavel/${pessoa_fisica_id}`)

export type FazendaResponsavelListarRes = { id: number, pessoa_id: number, nome: string, sobrenome: string, cpf: string }[]

const fazendaResponsavelListar = async () => getData<FazendaResponsavelListarRes>(`/fazenda/responsaveis`)
const useFazendaResponsavelListar = () => useGetData<FazendaResponsavelListarRes>(`/fazenda/responsaveis`)

export { fazendaResponsavelCriar, fazendaResponsavelAtualizar, fazendaResponsavelPegar, useFazendaResponsavelPegar, fazendaResponsavelListar, useFazendaResponsavelListar }