import { getData, postData, useGetData } from "lib/request"

export type ContasReceberCriarReq = { centro_custo_id: number, conta_id: number, pessoa_id: number, quantidade_parcelas: number, valor_cobrado: number, data_lancamento: Date, data_vencimento_primeira_parcela: Date }
export type ContasReceberCriarRes = { id: number, centro_custo_id: number, entidade_id: number, conta_id: number, moeda_id: number, pessoa_id: number, usuario_id: number, numero_parcelas: number, numero_parcelas_abertas: number, valor_cobrado: number, valor_pago: number, valor_multa: number, valor_juros: number, valor_desconto: number, valor_aberto: number, status: string, sinal: number, data_lancamento: Date, data_ultimo_pagamento?: Date, criado_as: string }

const contasReceberCriar = async (body: ContasReceberCriarReq) => postData<ContasReceberCriarRes>(`/contas-a-receber`, body)

export type ContasReceberPegarRes = { id: number, duplicata_id: number, data_vencimento: Date, numero_parcela: number, valor_cobrado: number, valor_pago: number, valor_multa: number, valor_juros: number, valor_desconto: number, valor_aberto: number, status: string, data_ultimo_pagamento?: Date, criado_as: string }

const contasReceberPegar = async (duplicata_parcela_id: number) => getData<ContasReceberPegarRes>(`/contas-a-receber/${duplicata_parcela_id}`)
const useContasReceberPegar = (duplicata_parcela_id: number) => useGetData<ContasReceberPegarRes>(`/contas-a-receber/${duplicata_parcela_id}`)

export type ContasReceberListarRes = { id: number, centro_custo: string, pessoa: string, data_lancamento: Date, data_vencimento: Date, valor_cobrado: number, valor_aberto: number, status: string }[]

const contasReceberListar = async () => getData<ContasReceberListarRes>(`/contas-a-receber`)
const useContasReceberListar = () => useGetData<ContasReceberListarRes>(`/contas-a-receber`)

export type ContasReceberCriarComMovimentacaoEstoqueReq = { centro_custo_id: number, conta_id: number, cliente_fornecedor_id: number, quantidade_parcelas: number, data_lancamento: Date, data_vencimento_primeira_parcela: Date, produtos_comprados: { produto_id: number, estoque_id: number, quantidade: number, valor_cobrado: number }[] }
export type ContasReceberCriarComMovimentacaoEstoqueRes = { id: number, centro_custo_id: number, entidade_id: number, conta_id: number, moeda_id: number, pessoa_id: number, usuario_id: number, numero_parcelas: number, numero_parcelas_abertas: number, valor_cobrado: number, valor_pago: number, valor_multa: number, valor_juros: number, valor_desconto: number, valor_aberto: number, status: string, sinal: number, data_lancamento: Date, data_ultimo_pagamento?: Date, criado_as: string }[]

const contasReceberCriarComMovimentacaoEstoque = async (body: ContasReceberCriarComMovimentacaoEstoqueReq) => postData<ContasReceberCriarComMovimentacaoEstoqueRes>(`/contas-a-receber/movimentacao-estoque`, body)

export type ContasReceberListarHistsRes = { id: number, duplicata_parcela_id: number, usuario_id: number, valor: number, valor_multa: number, valor_juros: number, valor_desconto: number, tipo_lancamento: string, detalhes?: string, data_movimento: Date, criado_as: string }[]

const contasReceberListarHists = async (duplicata_parcela_id: number) => getData<ContasReceberListarHistsRes>(`/contas-a-receber/${duplicata_parcela_id}/hists`)
const useContasReceberListarHists = (duplicata_parcela_id: number) => useGetData<ContasReceberListarHistsRes>(`/contas-a-receber/${duplicata_parcela_id}/hists`)

export type ContasReceberBaixarReq = { duplicata_parcela_id: number, valor_pago: number, valor_multa: number, valor_juros: number, valor_desconto: number, detalhes?: string, data_movimento: Date }
export type ContasReceberBaixarRes = { id: number, duplicata_parcela_id: number, usuario_id: number, valor: number, valor_multa: number, valor_juros: number, valor_desconto: number, tipo_lancamento: string, detalhes?: string, data_movimento: Date, criado_as: string }[]

const contasReceberBaixar = async (body: ContasReceberBaixarReq) => postData<ContasReceberBaixarRes>(`/contas-a-receber/baixar`, body)

export type ContasReceberEstornarReq = { duplicata_parcela_hist_id_estornar: number, detalhes?: string, data_movimento: Date }
export type ContasReceberEstornarRes = { id: number, duplicata_parcela_id: number, usuario_id: number, valor: number, valor_multa: number, valor_juros: number, valor_desconto: number, tipo_lancamento: string, detalhes?: string, data_movimento: Date, criado_as: string }[]

const contasReceberEstornar = async (body: ContasReceberEstornarReq) => postData<ContasReceberEstornarRes>(`/contas-a-receber/estornar`, body)

export { contasReceberCriar, contasReceberPegar, useContasReceberPegar, contasReceberListar, useContasReceberListar, contasReceberCriarComMovimentacaoEstoque, contasReceberListarHists, useContasReceberListarHists, contasReceberBaixar, contasReceberEstornar }