import { PropsWithChildren, forwardRef } from "react";
import { ButtonProps } from "@mui/material";

import ButtonRoot from "./ButtonRoot";

import { useMaterialUIController } from "context";

type CustomButtonProps = {
  color?: string;
  circular?: boolean;
  iconOnly?: boolean;
};

type MergeProps<T, U> = Omit<T, keyof U> & U;

export type ExtendedButtonProps = PropsWithChildren<
  MergeProps<ButtonProps, CustomButtonProps>
>;

const Button = forwardRef<HTMLButtonElement, ExtendedButtonProps>(
  (
    {
      color = "white",
      variant = "contained",
      size = "medium",
      circular = false,
      iconOnly = false,
      children,
      ...rest
    }: ExtendedButtonProps,
    ref
  ) => {
    const { controller } = useMaterialUIController();
    const { darkMode } = controller;

    return (
      <ButtonRoot
        theme={undefined}
        ref={ref}
        color="primary"
        variant={variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
        {...rest}
      >
        {children}
      </ButtonRoot>
    );
  }
);

export default Button;
