import Box from "components/Box";
import Button from "components/Button";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { handleResponse } from "lib/response/defaultHandler";
import { fazendaCriar } from "lib/request/fazenda";
import FazendaForm from "../form";
import { useNavigate } from "react-router-dom";

function FazendaCriarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await fazendaCriar({
        responsavel_id: parseInt(event.target.responsavel_id.value),
        nome: event.target.nome.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Fazenda criada com sucesso");
        navigate("/fazendas");
      },
    });
  }

  return (
    <ContainerTitleBackLayout title="Salvando nova fazenda" backTo="/fazendas">
      <Box component="form" px={3} onSubmit={submitHandler}>
        <FazendaForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default FazendaCriarPage;
