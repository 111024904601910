import { useNavigate, useParams } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { handleResponse } from "lib/response/defaultHandler";
import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { estoqueAtualizar, useEstoquePegar } from "lib/request/estoque";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import EstoqueForm from "../form";

function EstoqueEditarPage() {
  const { estoque_id } = useParams();
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const { data } = useEstoquePegar(estoque_id as unknown as number);

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await estoqueAtualizar({
        id: parseInt(estoque_id as unknown as string),
        nome: event.target.nome.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Estoque alterada com sucesso");
        navigate("/estoques");
      },
    });
  }

  return (
    <ContainerTitleBackLayout title="Editando estoque" backTo="/estoques">
      <Box component="form" px={3} onSubmit={submitHandler}>
        {data && <EstoqueForm data={data} />}
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default EstoqueEditarPage;
