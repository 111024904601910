import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Switch from "@mui/material/Switch";

import Box from "components/Box";
import Typography from "components/Typography";

import TableCell from "../TableCell";

function Notifications() {
  return (
    <Card id="notifications">
      <Box p={3} lineHeight={1}>
        <Box mb={1}>
          <Typography variant="h5">Notificações</Typography>
        </Box>
        <Typography variant="button" color="text">
          Escolha como você vai receber as notificações do sistema.
        </Typography>
      </Box>
      <Box pb={3} px={3}>
        <Box minWidth="auto">
          <Table sx={{ minWidth: "36rem" }}>
            <Box component="thead">
              <TableRow>
                <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                  Atividade
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Email
                </TableCell>
                <TableCell align="center" padding={[1.5, 6, 1.5, 6]}>
                  Sistema
                </TableCell>
              </TableRow>
            </Box>
            <TableBody>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <Box lineHeight={1.4}>
                    <Typography display="block" variant="button" fontWeight="regular">
                      Mudança situação financeira
                    </Typography>
                    <Typography variant="caption" color="text" fontWeight="regular">
                      Quando ocorre uma convimentação nos contas a pagar / receber
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell padding={[1, 1, 1, 0.5]}>
                  <Box lineHeight={1.4}>
                    <Typography display="block" variant="button" fontWeight="regular">
                      Conexão em novo dispositivo
                    </Typography>
                    <Typography variant="caption" color="text" fontWeight="regular">
                      Quando um novo aparelho for conectado no sistema
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch defaultChecked />
                </TableCell>
                <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                  <Switch />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Card>
  );
}

export default Notifications;
