import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";

import Button, { ExtendedButtonProps } from "components/Button";

type ButtonLinkProps = {
  to: string;
};

type MergeProps<T, U> = Omit<T, keyof U> & U;

type ExtendedButtonLinkProps = PropsWithChildren<
  MergeProps<ExtendedButtonProps, ButtonLinkProps>
>;

const ButtonLink = ({
  to,
  children,
  ...rest
}: PropsWithChildren<ExtendedButtonLinkProps>) => {
  return (
    <NavLink to={to}>
      <Button {...rest}>{children}</Button>
    </NavLink>
  );
};

export default ButtonLink;
