import { useSnackbar } from "notistack";

import { NotificationInterface } from "hooks/notification/interface";

function useNotificationBasic(): NotificationInterface {
  const { enqueueSnackbar } = useSnackbar();

  function successNotify(message: string | React.ReactNode) {
    enqueueSnackbar(message, { variant: "success" });
  }

  function warningNotify(message: string | React.ReactNode) {
    enqueueSnackbar(message, { variant: "warning" });
  }

  function errorNotify(message: string | React.ReactNode) {
    enqueueSnackbar(message, { variant: "error" });
  }

  return {
    successNotify,
    warningNotify,
    errorNotify,
  };
}

export default useNotificationBasic;
