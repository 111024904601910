import Grid from "@mui/material/Grid";

import Select from "components/Form/Select";
import Input from "components/Form/Input";

import {
  ProdutoPegarRes,
  useProdutoListarTipos,
  useProdutoListarUnidadeMedidas,
} from "lib/request/produto";
import { useState } from "react";
import { montaOptionsIdNome } from "lib/montaOptions";

function ProdutoForm({ data }: { data?: ProdutoPegarRes }) {
  const [tipoId, setTipoId] = useState(data?.tipo_id);
  const [unidadeMedidaId, setUnidadeMedidaId] = useState(
    data?.unidade_medida_id
  );
  const [nome, setNome] = useState(data?.nome);

  const tipos_options = montaOptionsIdNome(useProdutoListarTipos());
  const unidade_medidas_options = montaOptionsIdNome(
    useProdutoListarUnidadeMedidas()
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Select
          label="Tipo do insumo"
          name="tipo_id"
          options={tipos_options}
          value={tipoId}
          onChange={(e) => setTipoId(e.target.value as unknown as number)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Nome"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          label="Forma de compra"
          name="unidade_medida_id"
          options={unidade_medidas_options}
          value={unidadeMedidaId}
          onChange={(e) =>
            setUnidadeMedidaId(e.target.value as unknown as number)
          }
          required
        />
      </Grid>
    </Grid>
  );
}

export default ProdutoForm;
