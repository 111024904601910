import Grid from "@mui/material/Grid";
import Box from "components/Box";
import ButtonLink from "components/ButtonLink";
import BackButtonLink from "components/ButtonLink/Back";
import Typography from "components/Typography";
import { parseIsoDate } from "lib/parse";
import { ContasPagarPegarRes } from "lib/request/contas_pagar";

function Header({
  title,
  backTo,
  duplicata_parcela,
  showBaixarLink,
}: {
  title: string;
  backTo?: string;
  duplicata_parcela: ContasPagarPegarRes;
  showBaixarLink: boolean;
}) {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={6}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Box mb={1}>
              <Typography variant="h6" fontWeight="medium">
                {title}
              </Typography>
              {backTo ? <BackButtonLink to={backTo} /> : null}
            </Box>
            {/* <Typography variant="body2" color="text">
              Número: <b>2023-5-1234</b>
            </Typography> */}
            <Typography variant="body2" color="text">
              Vencimento:{" "}
              <b>
                {parseIsoDate(
                  duplicata_parcela.data_vencimento as unknown as string
                )}
              </b>
            </Typography>
          </Box>
        </Box>
      </Grid>

      {showBaixarLink ? (
        <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
          <ButtonLink
            to={"/contas-a-pagar/baixar/" + duplicata_parcela.id}
            variant="gradient"
            color="dark"
            size="small"
            disabled={duplicata_parcela.status === "F"}
          >
            baixar
          </ButtonLink>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default Header;
