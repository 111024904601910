import Card from "@mui/material/Card";

import Box from "components/Box";
import Typography from "components/Typography";
import Button from "components/Button";

function DeleteAccount() {
  return (
    <Card id="delete-account">
      <Box
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <Box p={3} lineHeight={1}>
          <Box mb={1}>
            <Typography variant="h5">Deletar conta</Typography>
          </Box>
          <Typography variant="button" color="text">
            Uma ver deletada não sejá possível voltar atrás, pensei bem antes de realizar.
          </Typography>
        </Box>
        <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <Button variant="outlined" color="secondary">
            desativar
          </Button>
          <Box ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <Button /* variant="gradient" */ color="error" sx={{ height: "100%" }}>
              deletar
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default DeleteAccount;
