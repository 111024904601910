import { deleteData, getData, postData, putData, useGetData } from "lib/request"

export type ContaCriarReq = { nome: string }
export type ContaCriarRes = { id: number, entidade_id: number, nome: string, deletado_as?: string }

const contaCriar = async (body: ContaCriarReq) => postData<ContaCriarRes>(`/conta`, body)

export type ContaPegarRes = { id: number, entidade_id: number, nome: string, deletado_as?: string }

const contaPegar = async (id: number) => getData<ContaPegarRes>(`/conta/${id}`)
const useContaPegar = (id: number) => useGetData<ContaPegarRes>(`/conta/${id}`)

export type ContaAtualizarReq = { id: number, nome: string }
export type ContaAtualizarRes = { id: number, entidade_id: number, nome: string, deletado_as?: string }

const contaAtualizar = async (body: ContaAtualizarReq) => putData<ContaAtualizarRes>(`/conta`, body)

export type ContaDeletarRes = { id: number, entidade_id: number, nome: string, deletado_as?: string }

const contaDeletar = async (id: number) => deleteData<ContaDeletarRes>(`/conta/${id}`)

export type ContaListarRes = { id: number, entidade_id: number, nome: string, deletado_as?: string }[]

const contaListar = async () => getData<ContaListarRes>(`/contas`)
const useContaListar = () => useGetData<ContaListarRes>(`/contas`)

export { contaCriar, contaPegar, useContaPegar, contaAtualizar, contaDeletar, contaListar, useContaListar }