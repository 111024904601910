/* pub struct SubInfoNovaPessoaFisica {
  pub sobrenome: String,
  pub cpf: String,
}

#[derive(Deserialize, Clone)]
pub struct SubInfoNovaPessoaJuridica {
  pub razao_social: String,
  pub cnpj: String,
}

#[derive(Deserialize)]
struct InfoNovo {
  pessoa_fisica: Option<SubInfoNovaPessoaFisica>,
  pessoa_juridica: Option<SubInfoNovaPessoaJuridica>,
} */
import { useState } from "react";

import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";

import { ClienteFornecedorPegarRes } from "lib/request/cliente_fornecedor";
import Select from "components/Form/Select";
import CpfInput from "components/Form/Input/CpfInput";
import CnpjInput from "components/Form/Input/CnpjInput";

function ClienteFornecedorFormPF({
  data,
}: {
  data?: ClienteFornecedorPegarRes;
}) {
  const [nome, setNome] = useState(data?.nome);
  const [sobrenome, setSobrenome] = useState(""); // useState(data?.sobrenome);
  const [cpf, setCpf] = useState(data?.cpf);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Input
          label="Nome"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Sobrenome"
          name="sobrenome"
          value={sobrenome}
          onChange={(e) => setSobrenome(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CpfInput
          label="CPF"
          name="cpf"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
          required
        />
      </Grid>
    </>
  );
}

function ClienteFornecedorFormPJ({
  data,
}: {
  data?: ClienteFornecedorPegarRes;
}) {
  const [nome, setNome] = useState(data?.nome);
  const [razaoSocial, setRazaoSocial] = useState(""); // useState(data?.razao_social);
  const [cnpj, setCnpj] = useState(data?.cnpj);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Input
          label="Razão Social"
          name="razao_social"
          value={razaoSocial}
          onChange={(e) => setRazaoSocial(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Nome fantasia"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CnpjInput
          label="CNPJ"
          name="cnpj"
          value={cnpj}
          onChange={(e) => setCnpj(e.target.value)}
          required
        />
      </Grid>
    </>
  );
}

function ClienteForm({ data }: { data?: ClienteFornecedorPegarRes }) {
  const [tipoPessoa, setTipoPessoa] = useState("");

  const [inscricaoEstadual, setInscricaoEstadual] = useState(
    data?.inscricao_estadual
  );
  const [inscricaoMunicipal, setInscricaoMunicipal] = useState(
    data?.inscricao_municipal
  );

  const tipos_pessoa = [
    { id: "PF", label: "Pessoa Fisica" },
    { id: "PJ", label: "Pessoa Juridica" },
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <Select
          label="Tipo da pessoa"
          name="tipo_pessoa"
          options={tipos_pessoa}
          value={tipoPessoa}
          onChange={(e) => setTipoPessoa(e.target.value as unknown as string)}
          required
        />
      </Grid>
      {tipoPessoa === "PF" && <ClienteFornecedorFormPF data={data} />}
      {tipoPessoa === "PJ" && <ClienteFornecedorFormPJ data={data} />}
      <Grid item xs={12} sm={6}>
        <Input
          label="Inscrição estadual"
          name="inscricao_estadual"
          value={inscricaoEstadual}
          onChange={(e) => setInscricaoEstadual(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Inscrição municipal"
          name="inscricao_municipal"
          value={inscricaoMunicipal}
          onChange={(e) => setInscricaoMunicipal(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

export default ClienteForm;
