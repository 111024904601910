import { useNavigate, useParams } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { handleResponse } from "lib/response/defaultHandler";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import EstoqueMovimentacaoForm from "../form";
import { estoqueMovimentacaoCriar } from "lib/request/estoque_movimentacao";
import { parseBrToFloat } from "lib/parse";
import { useEstoquePegar } from "lib/request/estoque";

function EstoqueMovimentacaoCriarPage() {
  const { estoque_id } = useParams();

  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const estoque = useEstoquePegar(estoque_id as unknown as number);

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await estoqueMovimentacaoCriar({
        estoque_id: parseInt(estoque_id as unknown as string),
        produto_id: parseInt(event.target.produto_id.value),
        quantidade: parseBrToFloat(event.target.quantidade.value),
        data: event.target.data.value,
        sinal: parseInt(event.target.sinal.value),
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Movimentação de estoque salvo com sucesso");
        navigate(`/estoques/${estoque_id}/movimentacoes`);
      },
    });
  }

  return (
    <ContainerTitleBackLayout
      title={`Salvando movimentação - ${estoque.data?.nome || "..."}`}
      backTo={`/estoques/${estoque_id}/movimentacoes`}
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        <EstoqueMovimentacaoForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default EstoqueMovimentacaoCriarPage;
