import { ReactNode } from "react";

import DefaultLayout from "layouts/Default";

import ReloadScreenPage from "pages/reload-screen";
import DashboardVendaPage from "pages/dashboard/venda";
import EntrarPage from "pages/entrar";
import FazendaListarPage from "pages/fazenda";
import FazendaCriarPage from "pages/fazenda/criar";
import SafraCriarPage from "pages/safra/criar";
import SafraListarPage from "pages/safra";
import SairPage from "pages/sair";
import UsuarioConfiguracoesPage from "pages/usuario/configuracoes";
import UsuarioEditarPage from "pages/usuario/editar";
import UsuariosListarPage from "pages/usuario";
import ProdutoListarPage from "pages/produto";
import ProdutoCriarPage from "pages/produto/criar";
import SafraPraticaAgricolaPage from "pages/safra/pratica-agricola";
import SafraPraticaAgricolaUtilizacaoPage from "pages/safra/pratica-agricola/utilizacao";
import ProdutoEditarPage from "pages/produto/editar";
import FazendaEditarPage from "pages/fazenda/editar";
import SafraEditarPage from "pages/safra/editar";
import UsuarioCriarPage from "pages/usuario/criar";
import ProdutoValoresPage from "pages/produto/valores";
import ProdutoValorCriarPage from "pages/produto/valores/criar";
import ProdutoValorEditarPage from "pages/produto/valores/editar";
import ContasPagarListarPage from "pages/financeiro/contas-a-pagar";
import ContasPagarCriarPage from "pages/financeiro/contas-a-pagar/criar";
import EstoqueListarPage from "pages/estoque";
import EstoqueCriarPage from "pages/estoque/criar";
import EstoqueEditarPage from "pages/estoque/editar";
import CentroCustoCriarPage from "pages/financeiro/centro-custo/criar";
import CentroCustoListarPage from "pages/financeiro/centro-custo";
import CentroCustoEditarPage from "pages/financeiro/centro-custo/editar";
import ContaListarPage from "pages/financeiro/conta";
import ContaCriarPage from "pages/financeiro/conta/criar";
import ContaEditarPage from "pages/financeiro/conta/editar";
import ClienteFornecedorListarPage from "pages/cliente-fornecedor";
import ClienteFornecedorCriarPage from "pages/cliente-fornecedor/criar";
import EstoqueMovimentacoesPage from "pages/estoque/movimentacao";
import EstoqueMovimentacaoCriarPage from "pages/estoque/movimentacao/criar";
import EstoqueMovimentacaoEditarPage from "pages/estoque/movimentacao/editar";
import ContasPagarCriarComMovimentacaoPage from "pages/financeiro/contas-a-pagar/criar-com-movimentacao";
import SafraPraticaAgricolaUtilizacaoCriarPage from "pages/safra/pratica-agricola/utilizacao/criar";
import SafraPraticaAgricolaUtilizacaoEditarPage from "pages/safra/pratica-agricola/utilizacao/editar";
import SafraDefinirResultadoPage from "pages/safra/definir-resultado";
import SafraPraticaAgricolaRecomendacaoUsoPage from "pages/safra/pratica-agricola/recomendacao-uso";
import MunicipioListarPage from "pages/municipio";
import MunicipioCriarPage from "pages/municipio/criar";
import MunicipioEditarPage from "pages/municipio/editar";
import EstadoListarPage from "pages/estado";
import EstadoCriarPage from "pages/estado/criar";
import EstadoEditarPage from "pages/estado/editar";
import FazendaResponsavelListarPage from "pages/fazenda/responsavel";
import FazendaResponsavelCriarPage from "pages/fazenda/responsavel/criar";
import FazendaResponsavelEditarPage from "pages/fazenda/responsavel/editar";
import ContasPagarDetalhesPage from "pages/financeiro/contas-a-pagar/detalhes";
import ContasPagarBaixarPage from "pages/financeiro/contas-a-pagar/baixar";

import ContasReceberListarPage from "pages/financeiro/contas-a-receber";
import ContasReceberCriarPage from "pages/financeiro/contas-a-receber/criar";
import ContasReceberCriarComMovimentacaoPage from "pages/financeiro/contas-a-receber/criar-com-movimentacao";
import ContasReceberDetalhesPage from "pages/financeiro/contas-a-receber/detalhes";
import ContasReceberBaixarPage from "pages/financeiro/contas-a-receber/baixar";
import InicioPage from "pages/inicio";

type PageLayoutType = {
  layout: (children: ReactNode) => JSX.Element;
  pages: PageRoutesType;
};

type PageType = {
  route: string;
  component: ReactNode;
};

export type PageRoutesType = (PageLayoutType | PageType)[];

const pageRoutes: PageRoutesType = [
  {
    layout: DefaultLayout,
    pages: [
      { route: "/reload-screen", component: <ReloadScreenPage /> },
      // { route: "/dashboard/vendas", component: <DashboardVendaPage /> },
      { route: "/inicio", component: <InicioPage /> },
      { route: "/fazendas", component: <FazendaListarPage /> },
      { route: "/fazendas/criar", component: <FazendaCriarPage /> },
      {
        route: "/fazendas/editar/:fazenda_id",
        component: <FazendaEditarPage />,
      },
      {
        route: "/fazendas/responsaveis",
        component: <FazendaResponsavelListarPage />,
      },
      {
        route: "/fazendas/responsaveis/criar",
        component: <FazendaResponsavelCriarPage />,
      },
      {
        route: "/fazendas/responsaveis/editar/:pessoa_fisica_id",
        component: <FazendaResponsavelEditarPage />,
      },
      { route: "/safras", component: <SafraListarPage /> },
      { route: "/safras/criar", component: <SafraCriarPage /> },
      { route: "/safras/editar/:safra_id", component: <SafraEditarPage /> },
      {
        route: "/safras/definir-resultado/:safra_id",
        component: <SafraDefinirResultadoPage />,
      },
      {
        route: "/safras/:safra_id/praticas-agricolas",
        component: <SafraPraticaAgricolaPage />,
      },
      {
        route:
          "/safras/:safra_id/praticas-agricolas/:pratica_agricola_id/utilizacao",
        component: <SafraPraticaAgricolaUtilizacaoPage />,
      },
      {
        route:
          "/safras/:safra_id/praticas-agricolas/:pratica_agricola_id/recomendacao",
        component: <SafraPraticaAgricolaRecomendacaoUsoPage />,
      },
      {
        route:
          "/safras/:safra_id/praticas-agricolas/:pratica_agricola_id/utilizacao/criar",
        component: <SafraPraticaAgricolaUtilizacaoCriarPage />,
      },
      {
        route:
          "/safras/:safra_id/praticas-agricolas/:pratica_agricola_id/utilizacao/editar/:uso_produto_id",
        component: <SafraPraticaAgricolaUtilizacaoEditarPage />,
      },
      {
        route: "/usuario/configuracoes",
        component: <UsuarioConfiguracoesPage />,
      },
      {
        route: "/usuarios/criar",
        component: <UsuarioCriarPage />,
      },
      {
        route: "/usuarios/editar/:usuario_id",
        component: <UsuarioEditarPage />,
      },
      { route: "/usuarios", component: <UsuariosListarPage /> },
      { route: "/municipios", component: <MunicipioListarPage /> },
      { route: "/municipios/criar", component: <MunicipioCriarPage /> },
      {
        route: "/municipios/editar/:municipio_id",
        component: <MunicipioEditarPage />,
      },
      { route: "/estados", component: <EstadoListarPage /> },
      { route: "/estados/criar", component: <EstadoCriarPage /> },
      {
        route: "/estados/editar/:municipio_id",
        component: <EstadoEditarPage />,
      },
      { route: "/produtos", component: <ProdutoListarPage /> },
      { route: "/produtos/criar", component: <ProdutoCriarPage /> },
      {
        route: "/produtos/editar/:produto_id",
        component: <ProdutoEditarPage />,
      },
      {
        route: "/produto/:produto_id/valores",
        component: <ProdutoValoresPage />,
      },
      {
        route: "/produto/:produto_id/valores/criar",
        component: <ProdutoValorCriarPage />,
      },
      {
        route: "/produto/:produto_id/valores/editar/:valor_id",
        component: <ProdutoValorEditarPage />,
      },
      { route: "/estoques", component: <EstoqueListarPage /> },
      { route: "/estoques/criar", component: <EstoqueCriarPage /> },
      {
        route: "/estoques/editar/:estoque_id",
        component: <EstoqueEditarPage />,
      },
      {
        route: "/estoques/:estoque_id/movimentacoes",
        component: <EstoqueMovimentacoesPage />,
      },
      {
        route: "/estoques/:estoque_id/movimentacoes/criar",
        component: <EstoqueMovimentacaoCriarPage />,
      },
      {
        route: "/estoques/:estoque_id/movimentacoes/editar/:estoque_produto_id",
        component: <EstoqueMovimentacaoEditarPage />,
      },
      {
        route: "/clientes-fornecedores",
        component: <ClienteFornecedorListarPage />,
      },
      {
        route: "/clientes-fornecedores/criar",
        component: <ClienteFornecedorCriarPage />,
      },
      { route: "/contas", component: <ContaListarPage /> },
      { route: "/contas/criar", component: <ContaCriarPage /> },
      { route: "/contas/editar/:conta_id", component: <ContaEditarPage /> },
      { route: "/centro-de-custo", component: <CentroCustoListarPage /> },
      { route: "/centro-de-custo/criar", component: <CentroCustoCriarPage /> },
      {
        route: "/centro-de-custo/editar/:centro_custo_id",
        component: <CentroCustoEditarPage />,
      },
      { route: "/contas-a-pagar", component: <ContasPagarListarPage /> },
      { route: "/contas-a-pagar/criar", component: <ContasPagarCriarPage /> },
      {
        route: "/contas-a-pagar/detalhes/:duplicata_parcela_id",
        component: <ContasPagarDetalhesPage />,
      },
      {
        route: "/contas-a-pagar/baixar/:duplicata_parcela_id",
        component: <ContasPagarBaixarPage />,
      },
      {
        route: "/contas-a-pagar/criar-com-movimentacao",
        component: <ContasPagarCriarComMovimentacaoPage />,
      },

      { route: "/contas-a-receber", component: <ContasReceberListarPage /> },
      {
        route: "/contas-a-receber/criar",
        component: <ContasReceberCriarPage />,
      },
      {
        route: "/contas-a-receber/detalhes/:duplicata_parcela_id",
        component: <ContasReceberDetalhesPage />,
      },
      {
        route: "/contas-a-receber/baixar/:duplicata_parcela_id",
        component: <ContasReceberBaixarPage />,
      },
      {
        route: "/contas-a-receber/criar-com-movimentacao",
        component: <ContasReceberCriarComMovimentacaoPage />,
      },
    ],
  },
  { route: "/entrar", component: <EntrarPage /> },
  { route: "/sair", component: <SairPage /> },
];

export default pageRoutes;
