import { ReactNode, useState } from "react";

import { Box } from "@mui/material";

import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";

import brandWhite from "assets/images/agro-leaf-logo.png";
import brandDark from "assets/images/agro-leaf-logo-dark.png";

import DashboardLayout from "elements/LayoutContainers/DashboardLayout";
import DashboardNavbar from "elements/Navbars/DashboardNavbar";
import Footer from "elements/Footer";

import { setMiniSidenav, useMaterialUIController } from "context";

import menu from "vars/menu";

function DefaultLayout(children: ReactNode) {
  const { controller, dispatch } = useMaterialUIController();
  const {
    miniSidenav,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false, true);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={
          (transparentSidenav && !darkMode) || whiteSidenav
            ? brandDark
            : brandWhite
        }
        brandName="Agro Financeiro"
        routes={menu}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <Configurator />
      <DashboardLayout>
        <DashboardNavbar />
        <Box pt={2} pb={3} sx={{ flexGrow: 1 }}>
          {children}
        </Box>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default DefaultLayout;
