import { useNavigate } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { handleResponse } from "lib/response/defaultHandler";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { parseBrToFloat } from "lib/parse";
import { contasPagarCriarComMovimentacaoEstoque } from "lib/request/contas_pagar";
import ContasPagarComMovimentacaoForm from "./form";

function ContasPagarCriarComMovimentacaoPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    let index = 0;
    let produtos_comprados: any[] = [];

    while (true) {
      if (event.target[`produto_id[${index}]`] === undefined) {
        break;
      }

      produtos_comprados.push({
        produto_id: parseInt(event.target[`produto_id[${index}]`].value),
        estoque_id: parseInt(event.target[`estoque_id[${index}]`].value),
        quantidade: parseBrToFloat(event.target[`quantidade[${index}]`].value),
        valor_cobrado: parseBrToFloat(
          event.target[`valor_cobrado[${index}]`].value
        ),
      });

      index++;
    }

    handleResponse({
      response: await contasPagarCriarComMovimentacaoEstoque({
        centro_custo_id: parseInt(event.target.centro_custo_id.value),
        conta_id: parseInt(event.target.conta_id.value),
        cliente_fornecedor_id: parseInt(
          event.target.cliente_fornecedor_id.value
        ),
        quantidade_parcelas: parseInt(event.target.quantidade_parcelas.value),
        data_lancamento: event.target.data_lancamento.value,
        data_vencimento_primeira_parcela:
          event.target.data_vencimento_primeira_parcela.value,
        produtos_comprados: produtos_comprados,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Contas a pagar salva com sucesso");
        navigate(`/contas-a-pagar`);
      },
    });
  }

  return (
    <ContainerTitleBackLayout
      title={`Salvando Contas a pagar`}
      backTo={`/contas-a-pagar`}
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        <ContasPagarComMovimentacaoForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default ContasPagarCriarComMovimentacaoPage;
