import EditIconLink from "components/IconLink/Edit";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import ActionButtons from "elements/Tables/ActionButtons";
import ButtonLink from "components/ButtonLink";
import {
  ClienteFornecedorPaginateFiltros,
  ClienteFornecedorPaginateRes,
  useClienteFornecedorPaginate,
} from "lib/request/cliente_fornecedor";
import useApiTableState from "hooks/useApiTableState";
import DataTableApiPaginate from "elements/Tables/DataTable/DataTableApiPaginate";
import { ColumnDef } from "@tanstack/react-table";

function ClienteFornecedorListarPage() {
  const { data, tableState } = useApiTableState<
    ClienteFornecedorPaginateRes,
    ClienteFornecedorPaginateFiltros
  >(useClienteFornecedorPaginate);

  // TODO: Editar / Deletar

  return (
    <ContainerTitleBackLayout
      title="Listagem de Clientes / Fornecedores"
      mb={0}
    >
      <ButtonLink to="/clientes-fornecedores/criar">Novo</ButtonLink>
      <DataTableApiPaginate<
        ClienteFornecedorPaginateRes,
        ClienteFornecedorPaginateFiltros
      >
        data={data}
        tableState={tableState}
        columns={(): ColumnDef<ClienteFornecedorPaginateRes>[] => [
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 5000,
          },
          {
            accessorKey: "inscricao_estadual",
            cell: (info) => info.getValue(),
            header: "Inscrição estadual",
          },
          {
            accessorKey: "inscricao_municipal",
            cell: (info) => info.getValue(),
            header: "Inscrição municipal",
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink
                  to={`/clientes-fornecedores/editar/${info.getValue()}`}
                />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default ClienteFornecedorListarPage;
