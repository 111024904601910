import { getData, postData, useGetData } from "lib/request"

export type AutenticacaoEntrarReq = { login: string, senha: string }
export type AutenticacaoEntrarRes = { usuario_id: number, usuario_nome: string, entidade_sessao: { id: number, nome: string } }

const autenticacaoEntrar = async (body: AutenticacaoEntrarReq) => postData<AutenticacaoEntrarRes>(`/entrar`, body)

export type AutenticacaoSairRes = null

const autenticacaoSair = async () => getData<AutenticacaoSairRes>(`/sair`)
const useAutenticacaoSair = () => useGetData<AutenticacaoSairRes>(`/sair`)

export type AutenticacaoEstouLogadoRes = null

const autenticacaoEstouLogado = async () => getData<AutenticacaoEstouLogadoRes>(`/estou_logado`)
const useAutenticacaoEstouLogado = () => useGetData<AutenticacaoEstouLogadoRes>(`/estou_logado`)

export { autenticacaoEntrar, autenticacaoSair, useAutenticacaoSair, autenticacaoEstouLogado, useAutenticacaoEstouLogado }