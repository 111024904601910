import Swal from "sweetalert2";

function useAlert() {
  function confirmAlert({
    title,
    message,
    cbConfirm,
  }: {
    title: string;
    message: string;
    cbConfirm: Function;
  }) {
    Swal.fire({
      title,
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Voltar",
    }).then((result) => {
      if (result.isConfirmed) {
        cbConfirm();
      }
    });
  }

  function deleteAlert({
    title,
    message,
    cbDelete,
  }: {
    title?: string;
    message?: string;
    cbDelete: Function;
  }) {
    Swal.fire({
      title: title || "Tem certeza?",
      text: message || "Tem certeza que desejá deletar esse registro",
      icon: "warning",
      showConfirmButton: false,
      showDenyButton: true,
      showCancelButton: true,
      denyButtonText: "Deletar",
    }).then((result) => {
      if (result.isDenied) {
        cbDelete();
      }
    });
  }

  function disableAlert({
    title,
    message,
    cbDisable,
  }: {
    title?: string;
    message?: string;
    cbDisable: Function;
  }) {
    Swal.fire({
      title: title || "Tem certeza?",
      text: message || "Tem certeza que desejá desativar esse registro",
      icon: "warning",
      showConfirmButton: false,
      showDenyButton: true,
      showCancelButton: true,
      denyButtonText: "Desativar",
    }).then((result) => {
      if (result.isDenied) {
        cbDisable();
      }
    });
  }

  return { confirmAlert, deleteAlert, disableAlert };
}

export default useAlert;
