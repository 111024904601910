import Grid from "@mui/material/Grid";

import Box from "components/Box";

import Sidenav from "pages/usuario/configuracoes/components/Sidenav";
import Header from "pages/usuario/configuracoes/components/Header";
import BasicInfo from "pages/usuario/configuracoes/components/BasicInfo";
import ChangePassword from "pages/usuario/configuracoes/components/ChangePassword";
import Notifications from "pages/usuario/configuracoes/components/Notifications";
import Sessions from "pages/usuario/configuracoes/components/Sessions";
import DeleteAccount from "pages/usuario/configuracoes/components/DeleteAccount";

function UsuarioConfiguracoesPage() {
  return (
    <Grid container spacing={3} >
      <Grid item xs={12} lg={3} >
        <Sidenav />
      </Grid>
      <Grid item xs={12} lg={9} >
        <Box mb={3}>
          <Grid container spacing={3} >
            <Grid item xs={12} >
              <Header />
            </Grid>
            < Grid item xs={12} >
              <BasicInfo />
            </Grid>
            < Grid item xs={12} >
              <ChangePassword />
            </Grid>
            < Grid item xs={12} >
              <Notifications />
            </Grid>
            < Grid item xs={12} >
              <Sessions />
            </Grid>
            < Grid item xs={12} >
              <DeleteAccount />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default UsuarioConfiguracoesPage;
