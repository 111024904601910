import { useState } from "react";

import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";

import { CentroCustoPegarRes } from "lib/request/centro_custo";

function CentroCustoForm({ data }: { data?: CentroCustoPegarRes }) {
  const [nome, setNome] = useState(data?.nome);
  const [descricao, setDescricao] = useState(data?.descricao);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Input
          label="Nome do Centro de custo"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Descrição"
          name="descricao"
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
        />
      </Grid>
    </Grid>
  );
}

export default CentroCustoForm;
