import Avatar from "../components/Avatar";

import Icon from "@mui/material/Icon";

import profilePicture from "assets/images/team-3.jpg";
import { ReactNode } from "react";

type CollapseMenu = {
  type: "collapse";
  name: string | ReactNode;
  key: string;
  icon: ReactNode;
  collapse: MenuItem[];
};

type InternalLinkMenu = {
  name: string;
  key: string;
  route: string;
};

type ExternalLinkMenu = {
  name: string;
  key: string;
  href: string;
};

type TitleMenu = {
  type: "title";
  key: string;
  title: string;
};

type DivisorMenu = {
  key: string;
  type: "divider";
};

export type MenuItem =
  | CollapseMenu
  | InternalLinkMenu
  | ExternalLinkMenu
  | TitleMenu
  | DivisorMenu;

const menu: MenuItem[] = [
  {
    type: "collapse",
    name: <span id="MenuHeaderUserName">Nome usuário</span>,
    key: "usuario",
    icon: <Avatar /* src={profilePicture} */ alt="Nome usuário" size="sm" />,
    collapse: [
      /* {
        name: "Configurações",
        key: "configuracoes",
        route: "/usuario/configuracoes",
      }, */
      {
        name: "Sair",
        key: "sair",
        route: "/sair",
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  /* {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Vendas",
        key: "vendas",
        route: "/dashboard/vendas",
      },
    ],
  }, */
  {
    type: "collapse",
    name: "Produção",
    key: "producao",
    icon: <Icon fontSize="medium">agriculture</Icon>,
    collapse: [
      {
        name: "Fazendas",
        key: "fazendas",
        route: "/fazendas",
      },
      {
        name: "Responsáveis fazendas",
        key: "responsaveis",
        route: "/fazendas/responsaveis",
      },
      {
        name: "Insumos",
        key: "produtos",
        route: "/produtos",
      },
      {
        name: "Safras",
        key: "safras",
        route: "/safras",
      },
      { name: "Estoques", key: "estoques", route: "/estoques" },
    ],
  },
  {
    type: "collapse",
    name: "Financeiro",
    key: "financeiro",
    icon: <Icon fontSize="medium">attach_money</Icon>,
    collapse: [
      {
        name: "Centro de custo",
        key: "centro-de-custo",
        route: "/centro-de-custo",
      },
      {
        name: "Contas",
        key: "contas",
        route: "/contas",
      },
      {
        name: "Contas a pagar",
        key: "contas-a-pagar",
        route: "/contas-a-pagar",
      },
      {
        name: "Contas a receber",
        key: "contas-a-receber",
        route: "/contas-a-receber",
      },
    ],
  },
  {
    name: "Cliente / Fornecedor",
    key: "clientes-fornecedores",
    route: "/clientes-fornecedores",
  },
  /* {
    type: "collapse",
    name: "Localizações",
    key: "localizacao",
    icon: <Icon fontSize="medium">location_on_icon</Icon>,
    collapse: [
      {
        name: "Estados",
        key: "estados",
        route: "/estados",
      },
      {
        name: "Municípios",
        key: "municipios",
        route: "/municipios",
      },
    ],
  }, */
  {
    type: "collapse",
    name: "Controle Acesso",
    key: "controle-acesso",
    icon: <Icon fontSize="medium">lock</Icon>,
    collapse: [
      {
        name: "Usuários",
        key: "usuarios",
        route: "/usuarios",
      },
    ],
  },
  /* {
    type: "collapse",
    name: "Pagamentos",
    key: "pagamentos",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Listagem",
        key: "listagem",
        route: "/pagamentos/listagem",
        component: <PagamentosListagem />,
      },
      {
        name: "Detalhes",
        key: "detalhes",
        route: "/pagamentos/detalhes",
        component: <PagamentosDetalhes />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Safras",
    key: "safras",
    icon: <Icon fontSize="medium">agriculture</Icon>,
    collapse: [
      {
        name: "Listagem",
        key: "",
        route: "/safras",
        component: <SafrasListagem />,
      },
      {
        name: "Nova",
        key: "nova",
        route: "/safras/nova",
        component: <SafrasNova />,
      },
      {
        type: "collapse",
        name: "Praticas Agricolas",
        key: "praticas-agricolas",
        icon: <Icon fontSize="medium">agriculture</Icon>,
        collapse: [
          {
            name: "Listagem",
            key: "",
            route: "/safras/praticas-agricolas",
            component: <SafrasPraticasAgricolas />,
          },
          {
            name: "Utilização",
            key: "utilizacao",
            route: "/safras/praticas-agricolas/utilizacao",
            component: <SafrasPraticasAgricolasUtilizacao />,
          },
          {
            name: "Nova Utilização",
            key: "utilizacao-criar",
            route: "/safras/praticas-agricolas/utilizacao/criar",
            component: <SafrasPraticasAgricolasUtilizacaoNova />,
          },
        ],
      },
    ],
  }, */
];

export default menu;
