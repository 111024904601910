import { ColumnDef } from "@tanstack/react-table";

import { useUsuarioListar } from "lib/request/usuario";

import EditIconLink from "components/IconLink/Edit";

import DataTable from "elements/Tables/DataTable";
import { Person } from "elements/Tables/DataTable/makeData";
import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import ActionButtons from "elements/Tables/ActionButtons";
import ButtonLink from "components/ButtonLink";

function UsuariosListarPage() {
  const { data } = useUsuarioListar();

  return (
    <ContainerTitleBackLayout title="Lista de usuários" mb={0}>
      <ButtonLink to="/usuarios/criar">Novo</ButtonLink>
      <DataTable<Person>
        data={data || []}
        columns={(): ColumnDef<Person>[] => [
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 2500,
          },
          {
            accessorKey: "login",
            cell: (info) => info.getValue(),
            header: "Login",
            size: 2500,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink to={`/usuarios/editar/${info.getValue()}`} />
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default UsuariosListarPage;
