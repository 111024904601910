import Box from "components/Box";
import { PropsWithChildren } from "react";

type DataTableBodyCellProps = {
  noBorder?: boolean,
  align?: "left" | "right" | "center",
}

// TODO

function DataTableBodyCell({ noBorder = false, align = "left", children }: PropsWithChildren<DataTableBodyCellProps>) {
  return (
    <Box
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { light }, /* typography: { size }, */ borders: { borderWidth } }) => ({
        // fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <Box
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default DataTableBodyCell;
