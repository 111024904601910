import { forwardRef } from "react";

import EditIcon from "components/Icon/Edit";

import IconLink from "components/IconLink";
import { ExtendedIconLinkProps } from "components/IconLink"

const EditIconLink = forwardRef<HTMLAnchorElement, ExtendedIconLinkProps>((
  { to }: ExtendedIconLinkProps, ref
) => {
  return (
    <IconLink to={to} ref={ref}>
      <EditIcon />
    </IconLink>
  );
});

export default EditIconLink;
