import { forwardRef } from "react";

import SearchIcon from "components/Icon/Search";

import IconLink from "components/IconLink";
import { ExtendedIconLinkProps } from "components/IconLink"

const SearchIconLink = forwardRef<HTMLAnchorElement, ExtendedIconLinkProps>((
  { to }: ExtendedIconLinkProps, ref
) => {
  return (
    <IconLink to={to} ref={ref}>
      <SearchIcon />
    </IconLink>
  );
});

export default SearchIconLink;
