import { useNavigate } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { handleResponse } from "lib/response/defaultHandler";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { parseBrToFloat } from "lib/parse";
import { contasReceberCriar } from "lib/request/contas_receber";
import ContasReceberForm from "./form";

function ContasReceberCriarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await contasReceberCriar({
        centro_custo_id: parseInt(event.target.centro_custo_id.value),
        conta_id: parseInt(event.target.conta_id.value),
        pessoa_id: parseInt(event.target.pessoa_id.value),
        quantidade_parcelas: parseInt(event.target.quantidade_parcelas.value),
        valor_cobrado: parseBrToFloat(event.target.valor_cobrado.value),
        data_lancamento: event.target.data_lancamento.value,
        data_vencimento_primeira_parcela:
          event.target.data_vencimento_primeira_parcela.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Contas a receber salva com sucesso");
        navigate(`/contas-a-receber`);
      },
    });
  }

  return (
    <ContainerTitleBackLayout
      title={`Salvando Contas a receber`}
      backTo={`/contas-a-receber`}
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        <ContasReceberForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default ContasReceberCriarPage;
