import Box from "components/Box";
import Button from "components/Button";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import { safraCriar } from "lib/request/safra";
import { handleResponse } from "lib/response/defaultHandler";
import { useNavigate } from "react-router-dom";
import SafraForm from "../form";
import { parseBrToFloat } from "lib/parse";

function SafraCriarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await safraCriar({
        fazenda_id: parseInt(event.target.fazenda_id.value),
        sistema_manejo_id: parseInt(event.target.sistema_manejo_id.value),
        periodo_agricola_id: parseInt(event.target.periodo_agricola_id.value),
        produto_plantado_id: parseInt(event.target.produto_plantado_id.value),
        talhao: event.target.talhao.value,
        area_ha: parseBrToFloat(event.target.area_ha.value),
        data_plantio: event.target.data_plantio.value
          ? event.target.data_plantio.value
          : null,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Safra criada com sucesso");
        navigate("/safras");
      },
    });
  }

  return (
    <ContainerTitleBackLayout title="Salvando nova safra" backTo="/safras">
      <Box component="form" px={3} onSubmit={submitHandler}>
        <SafraForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default SafraCriarPage;
