import { PropsWithChildren, forwardRef } from "react";

import BadgeRoot from "./BadgeRoot";
import { BadgeProps } from "@mui/material";

type CustomBadgeProps = {
  size?: "xs" | "sm" | "md" | "lg",
  circular?: boolean,
  indicator?: boolean,
  border?: boolean,
  container?: boolean,
}

type MergeProps<T, U> = Omit<T, keyof U> & U;

export type ExtendedBadgeProps = MergeProps<BadgeProps, CustomBadgeProps>;

const Badge = forwardRef<HTMLSpanElement, PropsWithChildren<ExtendedBadgeProps>>(
  ({
    color = "info",
    variant = "gradient",
    size = "sm",
    circular = false,
    indicator = false,
    border = false,
    container = false,
    children,
    ...rest
  }: PropsWithChildren<ExtendedBadgeProps>, ref) => (
    <BadgeRoot
      theme={undefined}
      {...rest}
      ownerState={{ color, variant, size, circular, indicator, border, container, children }}
      ref={ref}
      color="default"
    >
      {children}
    </BadgeRoot>
  )
);

export default Badge;
