import { ReactNode, forwardRef } from "react";

import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

import Box from "components/Box";
import Typography from "components/Typography";

import menuItem from "./styles";

type NotificationItemProps = { icon: ReactNode, title: string }

// TODO: href

const NotificationItem = forwardRef<HTMLAnchorElement, NotificationItemProps>(({ icon, title, ...rest }: NotificationItemProps, ref) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)} href="">
    <Box component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1}>
      <Typography variant="body1" color="secondary" lineHeight={0.75}>
        {icon}
      </Typography>
      <Typography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
        {title}
      </Typography>
    </Box>
  </MenuItem>
));

export default NotificationItem;
