import Input from "components/Form/Input";

function FormField({ label = " ", ...rest }) {
  return (
    <Input
      variant="standard"
      label={label}
      fullWidth
      InputLabelProps={{ shrink: true }}
      {...rest}
    />
  );
}

export default FormField;
