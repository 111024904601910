import { useNavigate, useParams } from "react-router-dom";

import Box from "components/Box";
import Button from "components/Button";

import { handleResponse } from "lib/response/defaultHandler";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { parseBrToFloat } from "lib/parse";
import ContasPagarForm from "../form";
import { safraRegistrarUsoProduto, useSafraPegar } from "lib/request/safra";

function SafraPraticaAgricolaUtilizacaoCriarPage() {
  const { safra_id, pratica_agricola_id } = useParams();

  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const safra = useSafraPegar(safra_id as unknown as number);

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await safraRegistrarUsoProduto({
        safra_id: parseInt(safra_id as string),
        pratica_agricola_id: parseInt(pratica_agricola_id as string),
        estoque_id: parseInt(event.target.estoque_id.value),
        produto_id: parseInt(event.target.produto_id.value),
        quantidade: parseBrToFloat(event.target.quantidade.value),
        data: event.target.data.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Utilização de produto salva com sucesso");
        navigate(
          `/safras/${safra_id}/praticas-agricolas/${pratica_agricola_id}/utilizacao`
        );
      },
    });
  }

  return (
    <ContainerTitleBackLayout
      title={`Salvando Utilização de produto na safra (${
        safra.data?.talhao || "..."
      })`}
      backTo={`/safras/${safra_id}/praticas-agricolas/${pratica_agricola_id}/utilizacao`}
    >
      <Box component="form" px={3} onSubmit={submitHandler}>
        <ContasPagarForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default SafraPraticaAgricolaUtilizacaoCriarPage;
