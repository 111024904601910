import Grid from "@mui/material/Grid";
import Box from "components/Box";
import Button from "components/Button";
import BrFloatInput from "components/Form/Input/BrFloatInput";
import DateInput from "components/Form/Input/DateInput";
import Typography from "components/Typography";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

import {
  ContasPagarPegarRes,
  contasPagarBaixar,
} from "lib/request/contas_pagar";
import { handleResponse } from "lib/response/defaultHandler";
import { useNavigate } from "react-router-dom";

function OrdersOverview({
  duplicata_parcela,
}: {
  duplicata_parcela: ContasPagarPegarRes;
}) {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const submitHandler = async (event: any) => {
    event.preventDefault();

    let valor_pago = parseFloat(event.target.valor_pago.value);

    let valor_multa = parseFloat(event.target.valor_multa.value);
    if (!valor_multa) valor_multa = 0;
    let valor_juros = parseFloat(event.target.valor_juros.value);
    if (!valor_juros) valor_juros = 0;
    let valor_desconto = parseFloat(event.target.valor_desconto.value);
    if (!valor_desconto) valor_desconto = 0;

    handleResponse({
      response: await contasPagarBaixar({
        duplicata_parcela_id: duplicata_parcela.id,
        valor_pago,
        valor_multa,
        valor_juros,
        valor_desconto,
        data_movimento: event.target.data_movimento.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Contas a receber baixada com sucesso");
        navigate("/contas-a-receber/detalhes/" + duplicata_parcela.id);
      },
    });
  };

  return (
    <>
      <Typography variant="h6" fontWeight="medium">
        Informações Baixa
      </Typography>
      <Box mt={2}>
        <Box component="form" px={1} onSubmit={submitHandler}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <DateInput label="Data baixa" name="data_movimento" required />
            </Grid>
            <Grid item xs={12} sm={9}>
              <BrFloatInput label="Valor Pago" name="valor_pago" required />
            </Grid>
            <Grid item xs={12} sm={9}>
              <BrFloatInput label="Valor Multa" name="valor_multa" />
            </Grid>
            <Grid item xs={12} sm={9}>
              <BrFloatInput label="Valor Juros" name="valor_juros" />
            </Grid>
            <Grid item xs={12} sm={9}>
              <BrFloatInput label="Valor Desconto" name="valor_desconto" />
            </Grid>
          </Grid>

          <Box mt={3}>
            <Button type="submit" variant="gradient" color="info">
              Baixar
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default OrdersOverview;
