import Box from "components/Box";
import Button from "components/Button";

import useNotificationBasic from "hooks/notification/useNotificationBasic";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";
import { handleResponse } from "lib/response/defaultHandler";
import EstadoForm from "../form";
import { useNavigate } from "react-router-dom";
import { estadoCriar } from "lib/request/estado";

function EstadoCriarPage() {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  async function submitHandler(event: any) {
    event.preventDefault();

    handleResponse({
      response: await estadoCriar({
        nome: event.target.nome.value,
        sigla: event.target.sigla.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Estado criada com sucesso");
        navigate("/estados");
      },
    });
  }

  return (
    <ContainerTitleBackLayout title="Salvando novo estado" backTo="/estados">
      <Box component="form" px={3} onSubmit={submitHandler}>
        <EstadoForm />
        <Box mt={3}>
          <Button type="submit" variant="gradient" color="info">
            Salvar
          </Button>
        </Box>
      </Box>
    </ContainerTitleBackLayout>
  );
}

export default EstadoCriarPage;
