import { getData, postData, useGetData } from "lib/request"

export type ClienteFornecedorCriarReq = { nome: string, pessoa_fisica?: {'sobrenome': 'string', 'cpf': 'string'}, pessoa_juridica?: {'razao_social': 'string', 'cnpj': 'string'}, inscricao_estadual?: string, inscricao_municipal?: string }
export type ClienteFornecedorCriarRes = { id: number, nome: string, cpf?: string, cnpj?: string, inscricao_estadual?: string, inscricao_municipal?: string }

const clienteFornecedorCriar = async (body: ClienteFornecedorCriarReq) => postData<ClienteFornecedorCriarRes>(`/clientes`, body)

export type ClienteFornecedorPegarRes = { id: number, nome: string, cpf?: string, cnpj?: string, inscricao_estadual?: string, inscricao_municipal?: string }

const clienteFornecedorPegar = async (id: number) => getData<ClienteFornecedorPegarRes>(`/cliente/${id}`)
const useClienteFornecedorPegar = (id: number) => useGetData<ClienteFornecedorPegarRes>(`/cliente/${id}`)

export type ClienteFornecedorListarFiltros = { id?: number, nome?: string }
export type ClienteFornecedorListarRes = { id: number, nome: string, cpf?: string, cnpj?: string, inscricao_estadual?: string, inscricao_municipal?: string }[]

const clienteFornecedorListar = async (filtros?: ClienteFornecedorListarFiltros) => getData<ClienteFornecedorListarRes>(`/clientes`, undefined, filtros)
const useClienteFornecedorListar = (filtros?: ClienteFornecedorListarFiltros) => useGetData<ClienteFornecedorListarRes>(`/clientes`, undefined, filtros)

export type ClienteFornecedorPaginatePagination = { page: number }
export type ClienteFornecedorPaginateFiltros = { nome?: string, inscricao_municipal?: string, inscricao_estadual?: string }
export type ClienteFornecedorPaginateRes = { dados: { id: number, nome: string, cpf?: string, cnpj?: string, inscricao_estadual?: string, inscricao_municipal?: string }[], total_registros: number }

const clienteFornecedorPaginate = async (pagination: ClienteFornecedorPaginatePagination, filtros: ClienteFornecedorPaginateFiltros) => getData<ClienteFornecedorPaginateRes>(`/clientes/paginate`, pagination, filtros)
const useClienteFornecedorPaginate = (pagination: ClienteFornecedorPaginatePagination, filtros: ClienteFornecedorPaginateFiltros) => useGetData<ClienteFornecedorPaginateRes>(`/clientes/paginate`, pagination, filtros)

export { clienteFornecedorCriar, clienteFornecedorPegar, useClienteFornecedorPegar, clienteFornecedorListar, useClienteFornecedorListar, clienteFornecedorPaginate, useClienteFornecedorPaginate }