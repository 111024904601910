import { Link, useNavigate } from "react-router-dom";

import { ColumnDef } from "@tanstack/react-table";

import Button from "components/Button";
import EditIconLink from "components/IconLink/Edit";

import DataTable from "elements/Tables/DataTable";

import ContainerTitleBackLayout from "layouts/ContainerTitleBack";

import ActionButtons from "elements/Tables/ActionButtons";
import {
  CentroCustoListarRes,
  centroCustoDeletar,
  useCentroCustoListar,
} from "lib/request/centro_custo";
import IconButton from "components/IconButton";
import useAlert from "hooks/notification/useAlert";
import DeleteIcon from "components/Icon/Delete";
import { handleResponse } from "lib/response/defaultHandler";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

function CentroCustoListarPage() {
  const { data } = useCentroCustoListar();

  const navigate = useNavigate();
  const notification = useNotificationBasic();
  const { deleteAlert } = useAlert();

  const handlerDelete = (id: number) => {
    deleteAlert({
      cbDelete: async () => {
        handleResponse({
          response: await centroCustoDeletar(id),
          notification,
          navigate,
          cbSuccess: () => {
            notification.successNotify("Centro de custo deletado com sucesso");
            navigate("/reload-screen?tela=/centro-de-custo");
          },
        });
      },
    });
  };

  return (
    <ContainerTitleBackLayout title="Listagem de Centro de Custo" mb={0}>
      <Link to="/centro-de-custo/criar">
        <Button>Novo</Button>
      </Link>
      <DataTable<CentroCustoListarRes>
        data={data || []}
        columns={(): ColumnDef<CentroCustoListarRes>[] => [
          {
            accessorKey: "nome",
            cell: (info) => info.getValue(),
            header: "Nome",
            size: 5000,
          },
          {
            accessorKey: "id",
            cell: (info) => (
              <ActionButtons>
                <EditIconLink
                  to={`/centro-de-custo/editar/${info.getValue()}`}
                />
                <IconButton
                  onClick={() => handlerDelete(info.getValue() as number)}
                >
                  <DeleteIcon />
                </IconButton>
              </ActionButtons>
            ),
            enableColumnFilter: false,
            header: "Opções",
          },
        ]}
      />
    </ContainerTitleBackLayout>
  );
}

export default CentroCustoListarPage;
