import { useEffect, useState } from 'react'

type ApiTableState<T, U> = {
  data?: T,
  tableState: TableState<U>
}

export type TableState<T> = {
  pageIndex: number
  filters: T
  setFilter: (field: any, value: any) => void
  setPageIndex: (newIndex: number) => void
  goToFirstPage: () => void
  previousPage: () => void
  getCanPreviousPage: () => boolean
  nextPage: (totalData: number) => void
  getCanNextPage: (totalData: number) => boolean
  goToLastPage: () => void
}

function useTableState<T>() {
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [filters, setFilters] = useState<T>({} as T)

  const totalDataPerPage = 10

  const setFilter = (field: any, value: any) => {
    let newFilter: any = { ...filters }

    if (value === null || value === "") {
      delete newFilter[field]
    } else {
      newFilter[field] = value
    }

    goToFirstPage()
    setFilters(newFilter)
  }

  const lastIndex = (totalData: number) => parseInt((Math.ceil((totalData || 0) / totalDataPerPage).toString()))
  const goToFirstPage = () => setPageIndex(0)
  const getCanPreviousPage = () => pageIndex != 0
  const getCanNextPage = (totalData: number) => pageIndex + 1 < lastIndex(totalData)

  return {
    pageIndex,
    setPageIndex: (newIndex: number) => setPageIndex(newIndex),
    goToFirstPage,
    previousPage: () => getCanPreviousPage() ? setPageIndex(pageIndex - 1) : null,
    getCanPreviousPage,
    nextPage: (totalData: number) => getCanNextPage(totalData) ? setPageIndex(pageIndex + 1) : null,
    getCanNextPage,
    goToLastPage: () => setPageIndex(0),
    filters,
    setFilter
  }
}

export default function useApiTableState<T, U>(fnApiCall: any, fnApiCallParams?: any): ApiTableState<T, U> {
  if (fnApiCallParams === undefined) {
    fnApiCallParams = []
  }

  const tableState = useTableState<U>()
  const { data } = fnApiCall(
    ...fnApiCallParams,
    { page: tableState.pageIndex + 1 },
    tableState.filters
  );

  return { data, tableState }
}
