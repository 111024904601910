import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import Box from "components/Box";
import Typography from "components/Typography";
import pxToRem from "assets/theme/functions/pxToRem";

import { useMaterialUIController } from "context";

function Sidenav() {
  const { controller } = useMaterialUIController();
  const { darkMode } = controller;

  const sidenavItems = [
    { icon: "person", label: "Perfil", href: "profile" },
    { icon: "receipt_long", label: "Informações básicas", href: "basic-info" },
    { icon: "lock", label: "Mudar senha", href: "change-password" },
    // { icon: "security", label: "2FA", href: "2fa" },
    { icon: "campaign", label: "Notificações", href: "notifications" },
    { icon: "settings_applications", label: "Sessões", href: "sessions" },
    { icon: "delete", label: "Deletar conta", href: "delete-account" },
  ];

  const renderSidenavItems = sidenavItems.map(({ icon, label, href }, key) => {
    const itemKey = `item-${key}`;

    // TODO css

    return (
      <Box key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <a href={`#${href}`}>
          <Typography
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            sx={({
              // borders: { borderRadius },
              // functions: { pxToRem },
              // palette: { light },
              transitions,
            }) => ({
              display: "flex",
              alignItems: "center",
              // borderRadius: borderRadius.md,
              padding: `${pxToRem(10)} ${pxToRem(16)}`,
              transition: transitions.create("background-color", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
              }),

              "&:hover": {
                // backgroundColor: light.main,
              },
            })}
          >
            <Box mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
              <Icon fontSize="small">{icon}</Icon>
            </Box>
            {label}
          </Typography>
        </a>
      </Box>
    );
  });

  return (
    <Card
      sx={{
        // borderRadius: ({ borders: { borderRadius } }) => borderRadius.lg,
        position: "sticky",
        top: "1%",
      }}
    >
      <Box
        component="ul"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        sx={{ listStyle: "none" }}
      >
        {renderSidenavItems}
      </Box>
    </Card>
  );
}

export default Sidenav;
