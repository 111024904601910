import { PropsWithChildren, forwardRef } from "react";

import Button from "components/Button";
import { ExtendedButtonProps } from "components/Button";

const IconButton = forwardRef<HTMLButtonElement, PropsWithChildren<ExtendedButtonProps>>((
  { variant = "outlined", color = "info", iconOnly = true, circular = true, children, ...rest }: PropsWithChildren<ExtendedButtonProps>, ref
) => {
  return (
    <Button
      ref={ref}
      variant={variant}
      color={color}
      iconOnly={iconOnly}
      circular={circular}
      {...rest}
    >
      {children}
    </Button>
  );
});

export default IconButton;
