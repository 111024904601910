import { ReactNode } from "react";
import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import Box from "components/Box";
import Typography from "components/Typography";
import Avatar from "components/Avatar";

import Button from "components/Button";

type ComplexProjectCardProps = {
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "light";
  image: string;
  title: string;
  description: ReactNode;
  useCount?: number;
  safra_id: number;
  pratica_agricola_id: number;
};

function ComplexProjectCard({
  color = "dark",
  image,
  title,
  description,
  useCount = 0,
  safra_id,
  pratica_agricola_id,
}: ComplexProjectCardProps) {
  return (
    <Card>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <Avatar
            src={image}
            alt={title}
            size="xl"
            variant="rounded"
            /* bgColor={color}
            sx={{
              p: 1,
              mt: -6,
              borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
            }} */
          />
          <Box ml={2} mt={-1} lineHeight={0}>
            <Typography
              variant="h6"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {title}
            </Typography>
          </Box>
        </Box>
        <Box my={2} lineHeight={1}>
          <Typography variant="button" fontWeight="light" color="text">
            {description}
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="column" lineHeight={0}>
            <Typography variant="button" fontWeight="medium">
              <Link
                to={`/safras/${safra_id}/praticas-agricolas/${pratica_agricola_id}/utilizacao`}
              >
                <Button variant="gradient" size="small" color="info">
                  exibir
                </Button>
              </Link>
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" lineHeight={0}>
            <Typography variant="button" fontWeight="medium">
              {useCount}
            </Typography>
            <Typography variant="button" fontWeight="regular" color="secondary">
              Movs.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default ComplexProjectCard;
