import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";
import BrFloatInput from "components/Form/Input/BrFloatInput";
import DateInput from "components/Form/Input/DateInput";
import Select from "components/Form/Select";
import ApiSearchSelect from "components/Form/Select/ApiSearchSelect";
import SearchSelect from "components/Form/Select/SearchSelect";
import { montaOptionsIdNome } from "lib/montaOptions";

import { useFazendaListar } from "lib/request/fazenda";
import {
  produtoListarParaPlantar,
  useProdutoListarParaPlantar,
} from "lib/request/produto";
import {
  SafraPegarRes,
  useSafraListarPeriodoAgricolas,
  useSafraListarSistemasManejo,
} from "lib/request/safra";
import { useState } from "react";

function SafraForm({ data }: { data?: SafraPegarRes }) {
  const [periodoAgricolaId, setPeriodoAgricolaId] = useState(
    data?.periodo_agricola_id
  );
  const [sistemaManejoId, setSistemaManejoId] = useState(
    data?.sistema_manejo_id
  );
  const [fazendaId, setFazendaId] = useState(data?.fazenda_id);
  const [talhao, setTalhao] = useState(data?.talhao);
  const [produtoPlantadoId, setProdutoPlantadoId] = useState(
    data?.produto_plantado_id
  );
  const [areaHa, setAreaHa] = useState(data?.area_ha);
  const [dataPlantio, setDataPlantio] = useState(data?.data_plantio);

  const fazendas_options = montaOptionsIdNome(useFazendaListar());
  const periodo_agricolas_options = montaOptionsIdNome(
    useSafraListarPeriodoAgricolas()
  );
  const sistemas_manejo_options = montaOptionsIdNome(
    useSafraListarSistemasManejo()
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Select
          label="Fazenda"
          name="fazenda_id"
          options={fazendas_options}
          value={fazendaId}
          onChange={(e) => setFazendaId(e.target.value as unknown as number)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Talhão"
          name="talhao"
          value={talhao}
          onChange={(e) => setTalhao(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ApiSearchSelect
          label="Cultura"
          name="produto_plantado_id"
          value={produtoPlantadoId}
          fnApiGetOptions={produtoListarParaPlantar}
          onChange={(e) =>
            setProdutoPlantadoId(e.target.value as unknown as number)
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BrFloatInput
          label="Área de plantio (HA)"
          name="area_ha"
          value={areaHa}
          onChange={(e) => setAreaHa(e.target.value as unknown as number)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          label="Periodo Agricola"
          name="periodo_agricola_id"
          options={periodo_agricolas_options}
          value={periodoAgricolaId}
          onChange={(e) =>
            setPeriodoAgricolaId(e.target.value as unknown as number)
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Select
          label="Sistema de Manejo"
          name="sistema_manejo_id"
          options={sistemas_manejo_options}
          value={sistemaManejoId}
          onChange={(e) =>
            setSistemaManejoId(e.target.value as unknown as number)
          }
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateInput
          label="Data do Plantio"
          name="data_plantio"
          value={dataPlantio}
          onChange={(e) => setDataPlantio(e.target.value as unknown as Date)}
        />
      </Grid>
    </Grid>
  );
}

export default SafraForm;
