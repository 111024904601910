import { forwardRef, useEffect, useId, useState } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
} from "@mui/material";
import Input from "components/Form/Input";
import SearchIcon from "components/Icon/Search";

type CustomSelectProps = {
  idField?: string;
  labelField?: string;
  fnApiGetOptions: (filtros: any) => Promise<any>;
};

type MergeProps<T, U> = Omit<T, keyof U> & U;

type ExtendedSelectProps = MergeProps<SelectProps, CustomSelectProps>;

const ApiSearchSelect = forwardRef<HTMLDivElement, ExtendedSelectProps>(
  (
    {
      label,
      value,
      idField = "id",
      labelField = "nome",
      fnApiGetOptions,
      required,
      ...rest
    }: ExtendedSelectProps,
    ref
  ) => {
    const id = useId();

    const [selectValue, setSelectValue] = useState(value);
    const [searchText, setSearchText] = useState("");

    const [displayedOptions, setDisplayedOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleChangeSelectedOptions = async (value: any) => {
      const options = await fetchOptions(idField, value);
      setSelectedOptions(options);
    };

    const handleChangeSelectValue = async (value: any) => {
      await handleChangeSelectedOptions(value);
      setSelectValue(value);
    };

    const fetchOptions = async (key: any, value: any) => {
      let options: any = [];

      let filter: any = {};
      filter[key] = value;

      let resp = await fnApiGetOptions(filter);

      if (resp.ok) {
        for (const data of resp.data) {
          options.push({ id: data[idField], label: data[labelField] });
        }
      }

      return options;
    };

    useEffect(() => {
      if (selectValue) {
        handleChangeSelectedOptions(selectValue);
      }
    }, []);

    useEffect(() => {
      fetchOptions(labelField, searchText).then((options) =>
        setDisplayedOptions(options)
      );
    }, [searchText]);

    const MakeMenuItem = (option: any) => {
      return (
        <MenuItem
          key={id + "-" + option.id}
          value={option.id}
          autoFocus={false}
          onClick={() => handleChangeSelectValue(option.id)}
        >
          {option.label}
        </MenuItem>
      );
    };

    const makeOptions = (a: any, b: any) => {
      const predicate = (a: any, b: any) => a.id === b.id;
      const c = [...a];

      b.forEach((bItem: any) =>
        c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)
      );

      return c;
    };

    const options = makeOptions(displayedOptions, selectedOptions);

    return (
      <FormControl required={required} sx={{ width: "100%", height: "100%" }}>
        <InputLabel id={id}>{label}</InputLabel>
        <MuiSelect
          labelId={id}
          ref={ref}
          label={label}
          sx={{ height: "inherit" }}
          value={selectValue}
          MenuProps={{ autoFocus: false }}
          // onClose={() => setSearchText("")}
          {...rest}
        >
          <ListSubheader>
            <Input
              size="small"
              autoFocus
              placeholder="Digite para pesquisar..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>

          {options.map(MakeMenuItem)}
        </MuiSelect>
      </FormControl>
    );
  }
);

export default ApiSearchSelect;
