import { forwardRef } from "react";
import { NavLink } from "react-router-dom";

import { ExtendedButtonProps } from "components/Button";
import IconButton from "components/IconButton";

type CustomIconLinkProps = {
  to: string,
}

type MergeProps<T, U> = Omit<T, keyof U> & U;

export type ExtendedIconLinkProps = MergeProps<ExtendedButtonProps, CustomIconLinkProps>;

const IconLink = forwardRef<HTMLAnchorElement, ExtendedIconLinkProps>((
  { to, children, ...rest }: ExtendedIconLinkProps, ref
) => {
  return (
    <NavLink to={to} ref={ref}>
      <IconButton {...rest}>
        {children}
      </IconButton>
    </NavLink>
  );
});

export default IconLink;
