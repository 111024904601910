import { NavLink } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";

import Button from "components/Button";
import Typography from "components/Typography";

const BackButtonLink = ({ to }: { to: string }) => {
  return (
    <Typography variant="h6" fontWeight="medium">
      <NavLink to={to}>
        <Button startIcon={<ArrowBack />} size="small" sx={{ p: 0 }}>
          Voltar
        </Button>
      </NavLink>
    </Typography>
  );
};

export default BackButtonLink;
