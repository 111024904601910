import { getData, postData, putData, useGetData } from "lib/request"

export type MunicipioCriarReq = { estado_id: number, nome: string }
export type MunicipioCriarRes = { id: number, estado_id: number, nome: string }

const municipioCriar = async (body: MunicipioCriarReq) => postData<MunicipioCriarRes>(`/municipio`, body)

export type MunicipioPegarRes = { id: number, estado_id: number, nome: string }

const municipioPegar = async (id: number) => getData<MunicipioPegarRes>(`/municipio/${id}`)
const useMunicipioPegar = (id: number) => useGetData<MunicipioPegarRes>(`/municipio/${id}`)

export type MunicipioAtualizarReq = { id: number, estado_id: number, nome: string }
export type MunicipioAtualizarRes = { id: number, estado_id: number, nome: string }

const municipioAtualizar = async (body: MunicipioAtualizarReq) => putData<MunicipioAtualizarRes>(`/municipio`, body)

export type MunicipioPaginatePagination = { page: number }
export type MunicipioPaginateFiltros = { estado_sigla?: string, nome?: string }
export type MunicipioPaginateRes = { dados: { id: number, estado_id: number, estado_sigla: string, nome: string }[], total_registros: number }

const municipioPaginate = async (pagination: MunicipioPaginatePagination, filtros: MunicipioPaginateFiltros) => getData<MunicipioPaginateRes>(`/municipios/paginate`, pagination, filtros)
const useMunicipioPaginate = (pagination: MunicipioPaginatePagination, filtros: MunicipioPaginateFiltros) => useGetData<MunicipioPaginateRes>(`/municipios/paginate`, pagination, filtros)

export { municipioCriar, municipioPegar, useMunicipioPegar, municipioAtualizar, municipioPaginate, useMunicipioPaginate }