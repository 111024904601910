import { useState, useEffect, ReactNode } from "react";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Box from "components/Box";
import Typography from "components/Typography";

import breakpoints from "assets/theme-light/base/breakpoints";

import backgroundImage from "assets/images/bg-profile.jpeg";
import BackButtonLink from "components/ButtonLink/Back";
import { useSafraPegar } from "lib/request/safra";
import { parseIsoDate } from "lib/parse";

function Header({
  safra_id,
  children,
}: {
  safra_id: number;
  children?: ReactNode;
}) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const { data } = useSafraPegar(safra_id);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const montaInfoSafra = () => {
    if (!data) {
      return;
    }

    return (
      <>
        <Typography variant="h5" fontWeight="medium">
          {data.periodo_agricola} - {data.produto_plantado} - Talhão#
          {data.talhao}
        </Typography>
        <Typography variant="button" color="text" fontWeight="regular">
          {data.sistema_manejo}{" "}
          {data.data_plantio
            ? `- Plantio: ${parseIsoDate(
                data.data_plantio as unknown as string
              )}`
            : null}
        </Typography>
      </>
    );
  };

  return (
    <Box position="relative" mb={5}>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Box height="100%" mt={0.5} lineHeight={1}>
              {montaInfoSafra()}
              <BackButtonLink to="/safras" />
            </Box>
          </Grid>
        </Grid>
        {children}
      </Card>
    </Box>
  );
}

export default Header;
