import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

import { ListItemProps } from "@mui/material/ListItem";

import Box from "../Box";

import { item, itemContent, itemArrow } from "components/Sidenav/styles/sidenavItem";

import { useMaterialUIController } from "context";
import { PropsWithChildren } from "react";

type SidenavItemProps = {
  color?: string,
  name: string,
  active?: string | boolean,
  nested?: boolean,
  open?: boolean,
}

type MergeProps<T, U> = Omit<T, keyof U> & U;

type ExtendedSidenavProps = MergeProps<ListItemProps, SidenavItemProps>;

function SidenavItem({
  color = "info",
  name,
  active = false,
  nested = false,
  children,
  open = false,
  ...rest
}: PropsWithChildren<ExtendedSidenavProps>) {
  const { controller } = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

  return (
    <>
      <ListItem
        component="li"
        sx={(theme) => item(theme, { active, color, transparentSidenav, whiteSidenav, darkMode })}
        {...rest}
      >
        <Box
          sx={(theme) =>
            itemContent(theme, {
              active,
              miniSidenav,
              name,
              transparentSidenav,
              whiteSidenav,
              darkMode,
            })
          }
        >
          <ListItemText primary={name} />

          {children && (
            <Icon
              component="i"
              sx={(theme) =>
                itemArrow(theme, {
                  open,
                  miniSidenav,
                  transparentSidenav,
                  whiteSidenav,
                  darkMode,
                  noCollapse: false,
                  active: false
                })
              }
            >
              expand_less
            </Icon>
          )}
        </Box>
      </ListItem>

      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit {...rest}>
          {children}
        </Collapse>
      )}
    </>
  );
}

export default SidenavItem;
