const parseBrToFloat = (number: string | number): number => number.toString().replaceAll('.', '').replace(',', '.') as unknown as number

const parseIsoDate = (date: string): string => {
  if (!date) return ''
  const [year, month, day] = date.split('-')
  return day + '/' + month + '/' + year
}

export {
  parseBrToFloat,
  parseIsoDate
}