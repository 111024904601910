import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import Box from "components/Box";
import Typography from "components/Typography";
import Avatar from "components/Avatar";

import burceMars from "assets/images/team-3.jpg";

function Header() {
  return (
    <Card id="profile">
      <Box p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Avatar src={burceMars} alt="profile-image" size="xl" shadow="sm" />
          </Grid>
          <Grid item>
            <Box height="100%" mt={0.5} lineHeight={1}>
              <Typography variant="h5" fontWeight="medium">
                Nome do usuário
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default Header;
