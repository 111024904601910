import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

function ReloadScreenPage() {
  const [searchParams] = useSearchParams();
  const natigate = useNavigate();

  const tela = searchParams.get("tela");
  useEffect(() => {
    if (tela !== null && tela.length > 0) {
      natigate(tela);
      return;
    }

    natigate("/");
  }, [tela]);

  return <p>Carregando...</p>;
}

export default ReloadScreenPage;
