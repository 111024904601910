const siglaColetaDados = (unidade_medida?: string) => {
  if (unidade_medida == "COMPRIMENTO") {
    return 'M';
  }
  if (unidade_medida == "CAPACIDADE") {
    return 'L';
  }
  if (unidade_medida == "MASSA") {
    return 'KG';
  }
  return null
}

export {
  siglaColetaDados
}