const montaOtions = (data: any, id: string, label: string) => {
  const options: any[] = [];

  if (data.data?.length) {
    for (const d of data.data) {
      options.push({ id: d[id], label: d[label] });
    }
  }

  return options
}

const montaOptionsIdNome = (data: any) => montaOtions(data, 'id', 'nome')

export {
  montaOptionsIdNome
}