import { useState } from "react";

import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";

import { ContaPegarRes } from "lib/request/conta";

function ContaForm({ data }: { data?: ContaPegarRes }) {
  const [nome, setNome] = useState(data?.nome);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Input
          label="Nome da Conta"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </Grid>
    </Grid>
  );
}

export default ContaForm;
