import { getData, useGetData } from "lib/request"

export type PraticaAgricolaListarRes = { id: number, tipo: string, nome: string, descricao?: string, chave?: string, ordem?: number, deletado_as?: string, caminho_arquivo: string }[]

const praticaAgricolaListar = async () => getData<PraticaAgricolaListarRes>(`/praticas-agricolas`)
const usePraticaAgricolaListar = () => useGetData<PraticaAgricolaListarRes>(`/praticas-agricolas`)

export type PraticaAgricolaPegarRes = { id: number, tipo: string, nome: string, descricao?: string, chave?: string, ordem?: number, deletado_as?: string, caminho_arquivo: string }

const praticaAgricolaPegar = async (id: number) => getData<PraticaAgricolaPegarRes>(`/pratica-agricola/${id}`)
const usePraticaAgricolaPegar = (id: number) => useGetData<PraticaAgricolaPegarRes>(`/pratica-agricola/${id}`)

export { praticaAgricolaListar, usePraticaAgricolaListar, praticaAgricolaPegar, usePraticaAgricolaPegar }