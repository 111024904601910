import { NotificationInterface } from "hooks/notification/interface"
import { ResponseType } from "lib/request"
import { NavigateFunction } from "react-router-dom"

type handleResponseProps<T> = {
  response: ResponseType<T>,
  notification: NotificationInterface,
  navigate: NavigateFunction,
  cbSuccess?: (response: ResponseType<T>) => void
}

export function handleResponse<T>({ response, notification, navigate, cbSuccess }: handleResponseProps<T>) {
  if (!response.ok) {
    let notification_fn = notification.errorNotify;

    if (response.notification_type) {
      if (response.notification_type === 'warning') {
        notification_fn = notification.warningNotify
      }
    }

    notification_fn(response.message)

    if (response.redirect) {
      navigate(response.redirect.to, response.redirect.options)
    }
    return
  }

  if (cbSuccess) {
    cbSuccess(response)
  }
}
