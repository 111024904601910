import { forwardRef } from "react";

import InputRoot, { ExtendedTextFieldProps } from "components/Form/Input";
import { maskBrFloat } from "lib/mask";

const BrFloatInput = forwardRef<HTMLDivElement, ExtendedTextFieldProps>(
  ({ ...rest }: ExtendedTextFieldProps, ref) => {
    const handleMask = (event: any) => {
      if (event.target.value) {
        event.target.value = maskBrFloat(event.target.value);
      }
    };

    return <InputRoot {...rest} ref={ref} onInput={handleMask} />;
  }
);

export default BrFloatInput;
