import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Box from "components/Box";

import Header from "./components/Header";
import OrderInfo from "./components/OrderInfo";
import TrackOrder from "./components/TrackOrder";
import OrderSummary from "./components/OrderSummary";
import {
  useContasReceberListarHists,
  useContasReceberPegar,
} from "lib/request/contas_receber";
import { useParams } from "react-router-dom";

function ContasReceberDetalhesPage() {
  const { duplicata_parcela_id } = useParams();

  const { data: duplicata_parcela } = useContasReceberPegar(
    duplicata_parcela_id as unknown as number
  );
  const { data: movs } = useContasReceberListarHists(
    duplicata_parcela_id as unknown as number
  );

  return (
    <Box my={6}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Card>
            <Box pt={2} px={2}>
              {duplicata_parcela ? (
                <Header
                  title="Contas a receber > Detalhes"
                  backTo="/contas-a-receber"
                  duplicata_parcela={duplicata_parcela}
                  showBaixarLink={duplicata_parcela.status != "F"}
                />
              ) : null}
            </Box>
            <Divider />
            <Box pt={1} pb={3} px={2}>
              {/* <Box mb={3}>
                {duplicata_parcela ? (
                  <OrderInfo
                    duplicata_parcela={duplicata_parcela}
                    showBaixarLink={duplicata_parcela.status != "F"}
                  />
                ) : null}
              </Box>
              <Divider /> */}
              <Box mt={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={7}>
                    {duplicata_parcela && movs?.length ? (
                      <TrackOrder
                        duplicata_parcela={duplicata_parcela}
                        movs={movs}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12} lg={5} sx={{ ml: "auto" }}>
                    {duplicata_parcela ? (
                      <OrderSummary duplicata_parcela={duplicata_parcela} />
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContasReceberDetalhesPage;
