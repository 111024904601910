import { forwardRef } from "react";

import Input, { ExtendedTextFieldProps } from "components/Form/Input";

const DateInput = forwardRef<HTMLDivElement, ExtendedTextFieldProps>(
  ({ ...rest }: ExtendedTextFieldProps, ref) => {
    return (
      <Input
        InputLabelProps={{ shrink: true }}
        type="date"
        ref={ref}
        {...rest}
      />
    );
  }
);

export default DateInput;
