import { deleteData, getData, postData, putData, useGetData } from "lib/request"

export type SafraCriarReq = { fazenda_id: number, periodo_agricola_id: number, produto_plantado_id: number, sistema_manejo_id: number, talhao: string, area_ha: number, data_plantio?: Date, data_colheita?: Date }
export type SafraCriarRes = { id: number, entidade_id: number, periodo_agricola_id: number, sistema_manejo_id: number, fazenda_id: number, centro_custo_id: number, produto_plantado_id: number, talhao: string, area_ha: number, data_plantio?: Date, data_colheita?: Date, peso_colhido?: number }

const safraCriar = async (body: SafraCriarReq) => postData<SafraCriarRes>(`/safra`, body)

export type SafraAtualizarReq = { id: number, fazenda_id: number, periodo_agricola_id: number, produto_plantado_id: number, sistema_manejo_id: number, talhao: string, area_ha: number, data_plantio?: Date, data_colheita?: Date }
export type SafraAtualizarRes = { id: number, entidade_id: number, periodo_agricola_id: number, sistema_manejo_id: number, fazenda_id: number, centro_custo_id: number, produto_plantado_id: number, talhao: string, area_ha: number, data_plantio?: Date, data_colheita?: Date, peso_colhido?: number }

const safraAtualizar = async (body: SafraAtualizarReq) => putData<SafraAtualizarRes>(`/safra`, body)

export type SafraPegarRes = { id: number, periodo_agricola_id: number, periodo_agricola: string, sistema_manejo_id: number, sistema_manejo: string, fazenda_id: number, fazenda: string, produto_plantado_id: number, produto_plantado: string, talhao: string, area_ha: number, data_plantio?: Date, data_colheita?: Date, peso_colhido?: number }

const safraPegar = async (id: number) => getData<SafraPegarRes>(`/safra/${id}`)
const useSafraPegar = (id: number) => useGetData<SafraPegarRes>(`/safra/${id}`)

export type SafraPaginatePagination = { page: number }
export type SafraPaginateFiltros = { talhao?: string, data_plantio?: Date, data_colheita?: Date, peso_colhido?: number }
export type SafraPaginateRes = { dados: { id: number, entidade_id: number, periodo_agricola_id: number, sistema_manejo_id: number, fazenda_id: number, centro_custo_id: number, produto_plantado_id: number, talhao: string, area_ha: number, data_plantio?: Date, data_colheita?: Date, peso_colhido?: number }[], total_registros: number }

const safraPaginate = async (pagination: SafraPaginatePagination, filtros: SafraPaginateFiltros) => getData<SafraPaginateRes>(`/safras`, pagination, filtros)
const useSafraPaginate = (pagination: SafraPaginatePagination, filtros: SafraPaginateFiltros) => useGetData<SafraPaginateRes>(`/safras`, pagination, filtros)

export type SafraRegistrarUsoProdutoReq = { safra_id: number, pratica_agricola_id: number, estoque_id: number, produto_id: number, descricao?: string, quantidade: number, data: Date }
export type SafraRegistrarUsoProdutoRes = { id: number, safra_id: number, pratica_agricola_id: number, lancamento_baixa_estoque_id: number, descricao?: string }

const safraRegistrarUsoProduto = async (body: SafraRegistrarUsoProdutoReq) => postData<SafraRegistrarUsoProdutoRes>(`/safra/uso-produto`, body)

export type SafraPegarUsoProdutoRes = { id: number, produto_id: number, produto: string, estoque_id: number, estoque: string, quantidade: number, data: Date }

const safraPegarUsoProduto = async (uso_produto_id: number) => getData<SafraPegarUsoProdutoRes>(`/safra/uso-produto/${uso_produto_id}`)
const useSafraPegarUsoProduto = (uso_produto_id: number) => useGetData<SafraPegarUsoProdutoRes>(`/safra/uso-produto/${uso_produto_id}`)

export type SafraAtualizarUsoProdutoReq = { id: number, safra_id: number, pratica_agricola_id: number, estoque_id: number, produto_id: number, descricao?: string, quantidade: number, data: Date }
export type SafraAtualizarUsoProdutoRes = { id: number, safra_id: number, pratica_agricola_id: number, lancamento_baixa_estoque_id: number, descricao?: string }

const safraAtualizarUsoProduto = async (body: SafraAtualizarUsoProdutoReq) => putData<SafraAtualizarUsoProdutoRes>(`/safra/uso-produto`, body)

export type SafraDeletarUsoProdutoRes = { id: number, safra_id: number, pratica_agricola_id: number, lancamento_baixa_estoque_id: number, descricao?: string }

const safraDeletarUsoProduto = async (uso_produto_id: number) => deleteData<SafraDeletarUsoProdutoRes>(`/safra/uso-produto/${uso_produto_id}`)

export type SafraListarContagemUsoProdutoPorPeriodoAgricolaRes = { pratica_agricola_id: number, contagem: number }[]

const safraListarContagemUsoProdutoPorPeriodoAgricola = async (safra_id: number) => getData<SafraListarContagemUsoProdutoPorPeriodoAgricolaRes>(`/safra/${safra_id}/periodo-agricolas/contagem-usos-produtos`)
const useSafraListarContagemUsoProdutoPorPeriodoAgricola = (safra_id: number) => useGetData<SafraListarContagemUsoProdutoPorPeriodoAgricolaRes>(`/safra/${safra_id}/periodo-agricolas/contagem-usos-produtos`)

export type SafraListarPeriodoAgricolasRes = { id: number, tipo: string, nome: string, descricao?: string, chave?: string, ordem?: number, deletado_as?: string, caminho_arquivo: string }[]

const safraListarPeriodoAgricolas = async () => getData<SafraListarPeriodoAgricolasRes>(`/safra/periodo-agricolas`)
const useSafraListarPeriodoAgricolas = () => useGetData<SafraListarPeriodoAgricolasRes>(`/safra/periodo-agricolas`)

export type SafraListarSistemasManejoRes = { id: number, tipo: string, nome: string, descricao?: string, chave?: string, ordem?: number, deletado_as?: string, caminho_arquivo: string }[]

const safraListarSistemasManejo = async () => getData<SafraListarSistemasManejoRes>(`/safra/sistemas-manejo`)
const useSafraListarSistemasManejo = () => useGetData<SafraListarSistemasManejoRes>(`/safra/sistemas-manejo`)

export type SafraListarProdutosUsadosAtravezDaPraticaAgricolaRes = { id: number, safra_id: number, pratica_agricola_id: number, lancamento_baixa_estoque_id: number, descricao?: string }[]

const safraListarProdutosUsadosAtravezDaPraticaAgricola = async (safra_id: number, pratica_agricola_id: number) => getData<SafraListarProdutosUsadosAtravezDaPraticaAgricolaRes>(`/safra/${safra_id}/uso-produto/${pratica_agricola_id}`)
const useSafraListarProdutosUsadosAtravezDaPraticaAgricola = (safra_id: number, pratica_agricola_id: number) => useGetData<SafraListarProdutosUsadosAtravezDaPraticaAgricolaRes>(`/safra/${safra_id}/uso-produto/${pratica_agricola_id}`)

export type SafraRegistrarResultadoReq = { safra_id: number, data_colheita: Date, peso_colhido: number }
export type SafraRegistrarResultadoRes = { id: number, safra_id: number, pratica_agricola_id: number, lancamento_baixa_estoque_id: number, descricao?: string }

const safraRegistrarResultado = async (body: SafraRegistrarResultadoReq) => postData<SafraRegistrarResultadoRes>(`/safra/resultado`, body)

export { safraCriar, safraAtualizar, safraPegar, useSafraPegar, safraPaginate, useSafraPaginate, safraRegistrarUsoProduto, safraPegarUsoProduto, useSafraPegarUsoProduto, safraAtualizarUsoProduto, safraDeletarUsoProduto, safraListarContagemUsoProdutoPorPeriodoAgricola, useSafraListarContagemUsoProdutoPorPeriodoAgricola, safraListarPeriodoAgricolas, useSafraListarPeriodoAgricolas, safraListarSistemasManejo, useSafraListarSistemasManejo, safraListarProdutosUsadosAtravezDaPraticaAgricola, useSafraListarProdutosUsadosAtravezDaPraticaAgricola, safraRegistrarResultado }