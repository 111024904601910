import { ReactNode } from "react";

import Box from "components/Box";
import pxToRem from "assets/theme/functions/pxToRem";

import { useMaterialUIController } from "context";

function DashboardLayout({ children }: { children: ReactNode }) {
  const { controller } = useMaterialUIController();
  const { miniSidenav } = controller;

  return (
    <Box
      sx={({ breakpoints, transitions }) => ({
        p: 3,
        position: "relative",

        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </Box>
  );
}

export default DashboardLayout;
