import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

import Box from "components/Box";
import Typography from "components/Typography";
import Badge from "components/Badge";

function Sessions() {
  const actionButtonStyles = {
    "& .material-icons-round": {
      transform: `translateX(0)`,
      transition: "all 200ms cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(4px)`,
    },
  };

  return (
    <Card id="sessions">
      <Box p={3} lineHeight={1}>
        <Box mb={1}>
          <Typography variant="h5">Conexões</Typography>
        </Box>
        <Typography variant="button" color="text" fontWeight="regular">
          Está é a lista de dispositivos que o sistema reconhece.
        </Typography>
      </Box>
      <Box pb={3} px={3} sx={{ overflow: "auto" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={{ xs: "max-content", sm: "100%" }}
        >
          <Box display="flex" alignItems="center">
            <Box textAlign="center" color="text" px={{ xs: 0, md: 1.5 }} opacity={0.6}>
              <Icon /* fontSize="default" */>desktop_windows</Icon>
            </Box>
            <Box height="100%" ml={2} lineHeight={1} mr={2}>
              <Typography display="block" variant="button" fontWeight="regular" color="text">
                Chapecó 68.133.163.201
              </Typography>
              <Typography variant="caption" color="text">
                Conexão atual
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box mx={2} lineHeight={1}>
              <Badge
                variant="contained"
                size="xs"
                badgeContent="agora"
                color="success"
                container
              />
            </Box>
            <a href="#">
              <Typography
                variant="button"
                color="info"
                fontWeight="regular"
                sx={actionButtonStyles}
              >
                Ver mais&nbsp;
                <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>arrow_forward</Icon>
              </Typography>
            </a>
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={{ xs: "max-content", sm: "100%" }}
        >
          <Box display="flex" alignItems="center" mr={2}>
            <Box textAlign="center" color="text" px={{ xs: 0, md: 1.5 }} opacity={0.6}>
              <Icon /* fontSize="default" */>desktop_windows</Icon>
            </Box>
            <Box ml={2}>
              <Typography display="block" variant="body2" fontWeight="regular" color="text">
                Chrome windows 11
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box mx={2} lineHeight={1}>
              <Badge
                variant="contained"
                size="xs"
                badgeContent="01/05/2023"
                color="warning"
                container
              />
            </Box>
            <a href="#">
              <Typography
                variant="button"
                color="info"
                fontWeight="regular"
                sx={actionButtonStyles}
              >
                Ver mais&nbsp;
                <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>arrow_forward</Icon>
              </Typography>
            </a>
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={{ xs: "max-content", sm: "100%" }}
        >
          <Box display="flex" alignItems="center" mr={2}>
            <Box textAlign="center" color="text" px={{ xs: 0, md: 1.5 }} opacity={0.6}>
              <Icon /* fontSize="default" */>phone_iphone</Icon>
            </Box>
            <Box ml={2}>
              <Typography display="block" variant="body2" fontWeight="regular" color="text">
                Chrome Android
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box mx={2} lineHeight={1}>
              <Badge
                variant="contained"
                size="xs"
                badgeContent="01/05/2023"
                color="warning"
                container
              />
            </Box>
            <a href="#">
              <Typography
                variant="button"
                color="info"
                fontWeight="regular"
                sx={actionButtonStyles}
              >
                Ver mais&nbsp;
                <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>arrow_forward</Icon>
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default Sessions;
