import Grid from "@mui/material/Grid";

import Input from "components/Form/Input";

import { useState } from "react";
import { FazendaResponsavelPegarRes } from "lib/request/fazenda_responsavel";
import CpfInput from "components/Form/Input/CpfInput";

function FazendaResponsavelForm({
  data,
}: {
  data?: FazendaResponsavelPegarRes;
}) {
  const [nome, setNome] = useState(data?.nome);
  const [sobrenome, setSobrenome] = useState(data?.sobrenome);
  const [cpf, setCpf] = useState(data?.cpf);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Input
          label="Nome"
          name="nome"
          value={nome}
          onChange={(e) => setNome(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          label="Sobrenome"
          name="sobrenome"
          value={sobrenome}
          onChange={(e) => setSobrenome(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CpfInput
          label="CPF"
          name="cpf"
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
          required
        />
      </Grid>
    </Grid>
  );
}

export default FazendaResponsavelForm;
