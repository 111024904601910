import Box from "components/Box";
import Typography from "components/Typography";

import TimelineItem from "elements/Timeline/TimelineItem";

import BillingInformation from "../BillingInformation";
import {
  ContasPagarListarHistsRes,
  ContasPagarPegarRes,
  contasPagarEstornar,
} from "lib/request/contas_pagar";
import { maskBrFloat } from "lib/mask";
import IconButton from "components/IconButton";
import RestoreIcon from "components/Icon/Restore";
import useAlert from "hooks/notification/useAlert";
import { handleResponse } from "lib/response/defaultHandler";
import Input from "components/Form/Input";
import DateInput from "components/Form/Input/DateInput";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import useNotificationBasic from "hooks/notification/useNotificationBasic";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type Mov = {
  id: number;
  tipo_lancamento: "L" | "B" | "E";
};

function OrdersOverview({
  duplicata_parcela,
  movs,
}: {
  duplicata_parcela: ContasPagarPegarRes;
  movs: ContasPagarListarHistsRes;
}) {
  const navigate = useNavigate();
  const notification = useNotificationBasic();

  const [histIdEstornando, setHistIdEstornando] = useState(0);

  let ultima_mov: any = movs[movs.length - 1];
  let ultimas_mov_context: any[] = [];

  let ultima_mov_nao_estorno: any = null;
  let contagem_estorno = 0;

  const mov_reverse = [...movs].reverse();

  mov_reverse.map((mov) => {
    if (ultima_mov_nao_estorno !== null) {
      return;
    }

    if (mov.tipo_lancamento === "E") {
      contagem_estorno++;
      return;
    }

    if (contagem_estorno > 0) {
      contagem_estorno--;
      return;
    }

    ultima_mov_nao_estorno = mov;
  });

  const submitEstornoHandler = async (event: any) => {
    event.preventDefault();

    handleResponse({
      response: await contasPagarEstornar({
        duplicata_parcela_hist_id_estornar: histIdEstornando,
        data_movimento: event.target.data_movimento.value,
        detalhes: event.target.detalhes.value,
      }),
      notification,
      navigate,
      cbSuccess() {
        notification.successNotify("Estorno realizado com sucesso");
        navigate(
          "/reload-screen?tela=/contas-a-pagar/detalhes/" + duplicata_parcela.id
        );
      },
    });
  };

  const Color = (mov: Mov): "secondary" | "warning" => {
    if (mov.tipo_lancamento === "E") {
      return "warning";
    }

    return "secondary";
  };

  const Title = (mov: Mov) => {
    const TitleHapper = (mov: Mov) => {
      if (mov.tipo_lancamento === "B") {
        if (duplicata_parcela.status === "F" && mov.id === ultima_mov.id) {
          return "Baixa";
        }

        return "Baixa parcial";
      }

      if (mov.tipo_lancamento === "E") {
        const ultima_mov_context =
          ultimas_mov_context[ultimas_mov_context.length - 1];

        if (ultima_mov_context?.tipo_lancamento === "B") {
          return "Estorno de Baixar";
        }

        if (ultima_mov_context?.tipo_lancamento === "L") {
          return "Estorno de Lançamento";
        }

        return "Estorno";
      }

      return "Lançamento";
    };

    return (
      <>
        {TitleHapper(mov)}{" "}
        {mov.id === ultima_mov_nao_estorno?.id ? (
          <IconButton
            variant="text"
            title="Estornar"
            onClick={() => setHistIdEstornando(mov.id)}
          >
            <RestoreIcon />
          </IconButton>
        ) : null}
      </>
    );
  };

  const Icon = (mov: Mov) => {
    if (mov.tipo_lancamento === "B") {
      return "arrow_downward";
    }

    if (mov.tipo_lancamento === "E") {
      return "replay";
    }

    return "play_arrow";
  };

  return (
    <>
      <Modal
        open={histIdEstornando !== 0}
        onClose={() => setHistIdEstornando(0)}
      >
        <Box sx={style}>
          <Typography variant="h3">
            Para realizar o estorno é preciso informar uma data
          </Typography>

          <Box component="form" mt={5} px={1} onSubmit={submitEstornoHandler}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <DateInput
                  label="Data do estorno"
                  name="data_movimento"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input label="Motivo" name="detalhes" />
              </Grid>
            </Grid>

            <Box mt={3}>
              <Button type="submit" variant="gradient" color="info">
                Gerar estorno
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Typography variant="h6" fontWeight="medium">
        Movimentos
      </Typography>
      <Box mt={2}>
        {movs.map((mov) => {
          const item = (
            <TimelineItem
              key={mov.id}
              color={Color(mov as Mov)}
              icon={Icon(mov as Mov)}
              title={Title(mov as Mov)}
              detalhes={
                <BillingInformation
                  valor={maskBrFloat(mov.valor)}
                  multa={maskBrFloat(mov.valor_multa)}
                  juros={maskBrFloat(mov.valor_juros)}
                  desconto={maskBrFloat(mov.valor_desconto)}
                />
              }
              dateTime1={new Date(
                mov.data_movimento.toString()
              ).toLocaleDateString()}
              dateTime2={new Date(mov.criado_as.toString()).toLocaleString()}
            />
          );

          if (mov.tipo_lancamento === "E") {
            ultimas_mov_context.pop();
          } else {
            ultimas_mov_context.push(mov);
          }

          return item;
        })}

        {duplicata_parcela.status === "F" ? (
          <TimelineItem
            color="success"
            icon="done"
            title="Finalizado"
            lastItem
          />
        ) : null}
      </Box>
    </>
  );
}

export default OrdersOverview;
